<app-page-cardhead [PageName]="'Area Details'" [PageHeadWidth]="500"></app-page-cardhead>

<div class="container-md center-screen">
  <form [formGroup]="areaForm">
    <div class="card me-auto ms-auto" [style.width.px]="500">
      <div class="card-body pt-1 py-2">

      <table class="table table-striped table-hover table-sm caption-top">
        <caption>Update area name</caption>
        <tbody class="field-4column-label">
          <tr>
            <td>Area</td>
            <td>
              <input formControlName="area" [(ngModel)]="areaModel.name" type="text" required>
              <div *ngIf="areaForm.controls.area.errors?.required && submitted" class="error-label">
                Area is required
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="card-footer center-screen">
        <button mat-raised-button class="btn btn-primary btn-rounded me-2" mdbRipple color="primary" (click)="close()"> Close
        </button>
        <button mat-raised-button class="btn btn-primary btn-rounded" mdbRipple color="primary"
          (click)="saveArea()">Save</button>
      </div>
    </div>
    </div>
  </form>
</div>
