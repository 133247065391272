<!-- <div class="center-screen">
<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
      (change)="todoLeafItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <mat-form-field appearance="fill">
      <mat-label>New item...</mat-label>
      <input matInput #itemValue placeholder="Ex. Lettuce">
    </mat-form-field>
    <button mat-button (click)="saveNode(node, itemValue.value)">Save</button>
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.item">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <mat-checkbox [checked]="descendantsAllSelected(node)" [indeterminate]="descendantsPartiallySelected(node)"
      (change)="todoItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
    <button mat-icon-button (click)="addNewItem(node)"><mat-icon>add</mat-icon></button>
  </mat-tree-node>
</mat-tree>
<div> -->

<form class="form-container center-screen" [formGroup]="menusAdminForm">
      <table class="table table-striped table-hover table-sm" [style.width.px]="750">
        <thead class="table-center-header" >
          <tr>
            <td> Menu </td>
            <td> Component </td>
            <td style="width: 30px;"> Submenus </td>
            <td> Trigger </td>
            <td> Role </td>
            <td style="width: 30px;">
              Edit </td>
            <td style="width: 30px;">
              Delete </td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let menu of menus | paginate: { itemsPerPage: pageSize, currentPage: pager.currentPage }">
            <td style="width:40vw"> {{menu.menu}} </td>
            <td style="width:40vw"> {{menu.component}} </td>
            <td>
              <button *ngIf="menu.hasSubmenu == true" mat-icon-button class="btn btn-primary btn-rounded" mdbRipple color="primary" (click)="editMenu(menu)">
                <i class="fas fa-xs fa-square-check"></i>
              </button>
            </td>
            <td style="width:10vw"> {{menu.trigger}} </td>
            <td style="line-break: normal;"> {{menu.jobRoles}} </td>
            <td>
              <button mat-icon-button class="btn btn-primary btn-rounded" mdbRipple color="primary" (click)="editMenu(menu)">
                <i class="fas fa-xs fa-pen"></i>
              </button>
            </td>
            <td>
              <button mat-icon-button class="btn btn-primary btn-rounded" mdbRipple color="primary" (click)="deleteMenu(menu)">
                <i class="fas fa-xs fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="lms-pager mt-2">
        <div *ngIf="pager.pages && pager.pages?.length && pager.pages?.length > 1" class="btn-toolbar" role="toolbar" aria-label="LMS Pager group">
          <div class="btn-group btn-group-sm" role="group" aria-label="Move Previous group">

            <button type="button" class="btn btn-primary rounded-start" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5; margin: 0;" (click)="setPage(1)" [disabled]="pager.currentPage === 1">First</button>
            <button type="button" class="btn btn-primary rounded-0" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5; margin: 0;"(click)="setPage(pager.currentPage - 1)" [disabled]="pager.currentPage === 1">Previous</button>
          </div>
          <div class="btn-group btn-group-sm" role="group" aria-label="PageNumbers">
            <button *ngFor="let page of pager.pages" type="button" data-mdb-ripple-color="#7a7676" style="background-color:#f5f9ff; margin: 0; color: #7a7676;" [ngClass]="{active:pager.currentPage === page}" (click)="setPage(page)" class="btn btn-outline-secondary page-item number-item  rounded-0">{{page}}</button>
          </div>
          <div class="btn-group btn-group-sm" role="group" aria-label="Move Next group">
            <button type="button" class="btn btn-primary rounded-0" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5; margin: 0;" (click)="setPage(pager.currentPage + 1)" [disabled]="pager.currentPage === pager.totalPages">Next</button>
            <button type="button" class="btn btn-primary rounded-end" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5;margin: 0;" (click)="setPage(pager.totalPages)" [disabled]="pager.currentPage === pager.totalPages">Last</button>
          </div>
        </div>
      </div>

        <button class="btn btn-primary my-3" mdbRipple
          (click)="addMenu()">Add Menu</button>
  </form>
