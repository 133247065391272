import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from "@angular/router";
import {  type KeycloakEvent, KeycloakEventType } from 'keycloak-angular';
import { Observable, Subscription, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LMSMenuItem } from 'src/app/core/models/menuitem.model';
//import { SwitchModeService } from 'src/app/core/services/switch-mode.service';
import { UserService } from 'src/app/core/services/lmsuser.service';
import { PostcodesService } from 'src/app/core/services/postcodes.service';
import packageJson from 'package.json';
import { LMSPageTitleService } from 'src/app/core/services/lmspage-headertitle.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css'],
    // standalone: true,
    // imports: [MdbCollapseModule, NgIf, NgFor, RouterLinkActive, RouterLink, MdbDropdownModule, MdbPopoverModule, NgClass, ApiloaderComponent, AsyncPipe]
})
export class NavbarComponent implements OnInit,OnDestroy {
  //private userService = inject(UserService);
  // private authService: AuthenticationService = inject(AuthenticationService);
  isDark = false;
  public version: string = packageJson.version;
  environmentName: string;
  options: FormGroup;

  // private OnUserEvent = this.userService.UserhasEvent$;
  public isLoggedIn$ : Observable<boolean>;
  public MenuList$ = this.userService.MenusControl$;

  menus: LMSMenuItem[] = [];

  useractioncolor = 3;
  usertoken: any;
  DisplayedUsername = '';
  userBranchName = '';

  constructor(private router: Router,
    fb: FormBuilder,
    private userService: UserService,
    //private storageService: StorageService,
    private postcodeService: PostcodesService,
    private lmsPageTitleService: LMSPageTitleService) {
      this.isLoggedIn$ = this.userService.ValidUserLoaded$;

      this.userService.LMSUserEvent$.subscribe({
        next:(kevent: KeycloakEvent) => {
          if(kevent.type == KeycloakEventType.OnAuthSuccess) {
            this.useractioncolor = 4;
          }
          if(kevent.type == KeycloakEventType.OnReady) {
            this.isLoggedIn$.pipe(tap((valid) => {
              if(valid){
              this.DisplayedUsername = this.userService.userDisplayName || '';
              this.userBranchName = this.userService.userBranchName || '';
              }
            })
            );

            this.useractioncolor = 5;
            this.usertoken = `Token exp(5): ${localStorage.getItem('expires_at')||''}`;
            this.usertoken += ` JWT exp(5): ${localStorage.getItem('jwtTkn')}`;
          }
          if(kevent.type == KeycloakEventType.OnAuthRefreshSuccess) {
            this.useractioncolor = 3;
            this.usertoken = `Token exp(3): ${localStorage.getItem('expires_at')||''}`;
            this.usertoken += ` JWT exp(3): ${localStorage.getItem('jwtTkn')}`;
          }
        }
      });

      this.userService.onLogginChanged.subscribe((loginChanged) => {
        // eslint-disable-next-line no-empty
        if(loginChanged) {
          console.log("Nav(User Event): ", loginChanged);
          setTimeout(() => {
          this.DisplayedUsername = this.userService.userDisplayName || '';
          this.userBranchName = this.userService.userBranchName || '';
          },1000);
        }
      });

      this.options = fb.group({
        'fixed': true,
        'top': 0,
        'bottom': 0,
      });
  }

  subscriptions: Subscription[] = [];
  ngOnInit(): void {
    // const subs = this.lmsPageTitleService.PageTitleText.subscribe(
    //   pagename => this.PageName = pagename);

    // this.subscriptions.push(subs);
    this.environmentName = environment.name;
    //If User is not logged in then do Login
    //********  OR
    //Current User and user in Session is different - UnAUth
    // this.userService.ValidUserLoaded$.pipe(tap((val)=> {
    //   console.log('NavBar VAlidUser >>', val);
    // }));
      //this.userService.getLMSUserProfile();
      //this.userService.onLMSUserProfile();
  }

  navigate(route: string) {
    console.log(route);
    this.router.navigate([route]);
  }


  changeMode() {
    this.isDark = !this.isDark;

    //this.switchMode.handleSwitchMode(this.isDark);
  }

  logout() {
    this.router.navigate(['/']).then(() => {
      this.userService.logOutUser();
    });
  }

  get userDisplay() {
    this.DisplayedUsername = this.userService.userDisplayName;
    //this.userService.LMSProfile$.pipe
    return this.DisplayedUsername;
    //return `${this.userService.userDisplayName || this.DisplayedUsername}`;
  }

  hasSubmenu(item: any) {
    return item.submenus && item.submenus.length > 0;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => {
      x.unsubscribe();
    });
  }
}
