<div class="lms-dialog">
  <app-page-cardhead [PageName]="'{{dlgData.title}}'" [PageHeadWidth]=900></app-page-cardhead>
  <mat-dialog-content>
    {{dlgData.message}}
    </mat-dialog-content>
  <div class="mt-1 center-screen">
    <button mat-raised-button class="btn btn-primary " mdbRipple color="primary" type="button" (click)="close()">Close</button>
    <button mat-raised-button class="btn btn-primary " mdbRipple color="primary" type="submit" (click)="saveLmsDialog()">Save</button>
    <!-- Compulsory Dismiss Button -->
    <!-- <button matRipple [mat-dialog-close]>{{data.dismiss_text || 'OK'}}</button> -->
    <!-- Optional Action -->
    <!-- <button *ngIf="!!data.action" matRipple [mat-dialog-close] (click)="data.action()">{{data.action_text}}</button> -->
  </div>


</div>
