import { Injectable } from '@angular/core';
import { MdbModalRef, MdbModalService } from "mdb-angular-ui-kit/modal";
import { BehaviorSubject } from 'rxjs';
import { SpinnerComponent } from './spinner.component';


@Injectable({
  providedIn: 'root'
})
export class LoadingSpinService {
  private spinDialogRef: MdbModalRef<SpinnerComponent> | null = null;

  private _loadingrx = new BehaviorSubject<boolean>(false);
  public readonly loadingrx$ = this._loadingrx.asObservable();
  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading.asObservable();

  constructor(public modalService: MdbModalService) { }

  startLoading(message?: string, withTimer = false): MdbModalRef<SpinnerComponent> | null {
    if (this._loading.getValue()) {
      //this.dialog.openDialogs.setNewDataMessage(message);
    }

    if (!this._loading.value) {
      this._loading.next(true);
      this.spinDialogRef = this.modalService.open(SpinnerComponent, {
        backdrop: true, ignoreBackdropClick: true, keyboard: true,
        modalClass: 'modal-sm modal-fluid modal-dialog-centered modal-center modal-notify modal-info',
        data: {
          message: (!message || message == '') ? "Please wait!" : message,
          showTimer: withTimer
        }
      });
    }

    return this.spinDialogRef;
  }

  stopLoading(ref?: MdbModalRef<SpinnerComponent> | null) {
    this._loading.next(false);
    if (ref || this.spinDialogRef) {
      if (ref) {
        ref.close();
      }

      if (this.spinDialogRef) {
        this.spinDialogRef.close();
      }
    }
  }

  showrx() {
    this._loadingrx.next(true);
  }

  hiderx() {
    this._loadingrx.next(false);
  }

  public show() {
    this.startLoading();
  }

  public hide() {
    this.stopLoading();
  }
}
