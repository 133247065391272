import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatDialog, MatDialogRef, MAT_DIALOG_DATA
} from '@angular/material/dialog';

import { SubmenuModel } from 'src/app/core/models/submenu.model';
import { ClientDetailsService } from 'src/app/core/rest-services/client-details.service';
import { LoadingSpinService } from 'src/app/shared/loaders/spinner/loadspinner.service';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor } from '@angular/common';
import { PageCardheadComponent } from '../../../shared/ui/pageheader-card/pageheader-card.component';

@Component({
    selector: 'app-sub-menu-dialog',
    templateUrl: './sub-menu-dialog.component.html',
    styleUrls: ['./sub-menu-dialog.component.css'],
    standalone: true,
    imports: [PageCardheadComponent, FormsModule, ReactiveFormsModule, NgIf, MatSelectModule, NgFor, MatOptionModule, MatButtonModule]
})
export class SubMenuDialogComponent {
  submenuForm: FormGroup;
  submenuModel: SubmenuModel = new SubmenuModel();
  submitted = false;
  jobTitles: any;

  constructor(
    private dialogRef: MatDialogRef<SubMenuDialogComponent>,
    private formBuilder: FormBuilder,
    private cs: ClientDetailsService,
    public dialog: MatDialog,
    private loadingService: LoadingSpinService,
    @Inject(MAT_DIALOG_DATA) data: any) {

    this.cs.getAllJobTitles().subscribe((res) => {
      this.jobTitles = res;
      if (data) {
        this.submenuModel = data;
        let activeRoles: any[] = [];

        for (let b of data.submenusRoles) {
          let jobTitleId = b.jobTitle.id;
          let index = this.jobTitles.findIndex(
            (x: { id: any }) => x.id === jobTitleId
          );
          activeRoles.push(this.jobTitles[index]);
        }

        this.submenuModel.userRoles = activeRoles;
      }
    });
  }

  ngOnInit(): void {
    this.submitted = true;

    this.submenuForm = this.formBuilder.group({
      submenu: [this.submenuModel.submenu],
      component: [this.submenuModel.component],
      userRoles: [this.submenuModel.userRoles],
    });
  }

  async saveChanges(submenu: any) {
    this.dialogRef.close(submenu);
  }

  close() {
    this.dialogRef.close();
  }
}
