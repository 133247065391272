/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-page-cardhead',
  templateUrl: './pageheader-card.component.html',
  styleUrls: ['./pageheader-card.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true
})
export class PageCardheadComponent implements OnInit {
  @Input() PageName!: string;

  @Input() PageHeadWidth = 650;
  @Input() ribbonIdx = true;

  PageHeadLess: number;

  constructor(){

  }

  ngOnInit(): void {
    this.PageHeadLess = this.PageHeadWidth - 35;
  }

}
