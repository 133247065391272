import { Directive, Input } from '@angular/core';
import { AbstractControl, FormGroup, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { PaySlipModel } from 'src/app/core/models/pay-slip-model.model';
import { flatMap } from 'lodash-es';


export function UniquePayslipValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    if (control && control.value) {
      const paysliparray = control.value;

      const hasDuplicateMonth = (...payslips: PaySlipModel[] | any[]): boolean => {
        const hasDuplicate =
        payslips.map((ps) => {
          const date1 = new Date(ps.payslip1!.date).getMonth();
          const date2 = new Date(ps.payslip2!.date).getMonth();
          const date3 = new Date(ps.payslip3!.date).getMonth();
          return (date1 !== date2 && date2 !== date3 && date3 !== date1);
        });

        return !hasDuplicate.every(c => c == true);
      };

      return hasDuplicateMonth(control.parent?.value) ? {duplicatePayslip: true}:null;
    }

    return null;
  }
}

export const UniquePayslip: ValidatorFn =
  (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
    const paysliparray = control.value as PaySlipModel[];
      // if(paysliparray.every(v => v!)) {
      //   return null;// {duplicatePayslip: true};
      // }

      const hasDuplicateMonth = (...payslips: PaySlipModel[] | any[]): boolean => {
        const hasDuplicate =
        payslips.map((ps) => {
          const date1 = new Date(ps.payslip1!.date).getMonth();
          const date2 = new Date(ps.payslip2!.date).getMonth();
          const date3 = new Date(ps.payslip3!.date).getMonth();
          return (date1 !== date2 && date2 !== date3 && date3 !== date1);
        });

        return !hasDuplicate.every(c => c == true);
      };

      return hasDuplicateMonth(paysliparray) ? {duplicatePayslip: true}:null;
    }

    return null;
  };

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'lmsUniquePayslip',
  providers: [{ provide: NG_VALIDATORS, useExisting: UniquePayslipDirective, multi: true }],
  standalone: true
})
export class UniquePayslipDirective implements Validator {
  @Input('lmsUniquePayslip') UniquePayslip = 0;
  validate(control: AbstractControl): ValidationErrors | null {
    return this.UniquePayslip ? UniquePayslip(control): null;
  }
  registerOnValidatorChange?(): void {

    throw new Error('Method not implemented.');
  }

}


