import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { RatesAndFeesModel } from '../models/rates-and-fees-model.model';

@Injectable({
    providedIn: 'root'
})

export class RatesAdnFeesService {
    http: HttpClient;
    ratesAndFessModel: RatesAndFeesModel;

    productData: any;
    data: any;

    HttpParamsOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(http: HttpClient) {
        this.http = http;
    }

    getProductData() {
        return this.productData;
    }

    setProductData(data: any) {
        this.productData = data;
    }

    findAllProducts() {
        return this.http.
        get<any>(environment.apiUrl + '/loan/api/admin/findAllProducts');
      }

    findLoanTypeData(id: any) {
        return this.http
            .post<any>(environment.apiUrl + '/loan/api/admin/findLoanTypeData', id)
            .pipe(
                map((responseData) => {
                    this.productData = responseData;
                    return responseData;
                })
            );
    }

    findProductByDescription(data: any) {
        return this.http
            .post<any>(environment.apiUrl + '/loan/api/admin/findProductByDescription', data)
            .pipe(
                map((responseData) => {
                    this.productData = responseData;
                    return responseData;
                })
            );
    }

    saveProduct(data: any) {
        return this.http
            .post<any>(environment.apiUrl + '/loan/api/admin/saveLoanType', data)
            .pipe(
                map((responseData) => {
                    return responseData;
                })
            );
    }

    updateProduct(data: any) {
        return this.http
            .post<any>(environment.apiUrl + '/loan/api/admin/updateProduct', data)
            .pipe(
                map((responseData) => {
                    return responseData;
                })
            );
    }

    removeLoanType(id: any): Observable<any> {
        return this.http
            .delete(environment.apiUrl + '/loan/api/admin/removeLoanType/' + id)
            .pipe(
                map((responseData) => {
                    return responseData;
                })
            );
    }

}
