// material.module.ts
import { A11yModule } from '@angular/cdk/a11y';

import { ModuleWithProviders, NgModule } from '@angular/core';
import { DateAdapter, MatCommonModule, MatNativeDateModule, MatRippleModule, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerIntl, MatDatepickerModule } from '@angular/material/datepicker';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatGridListModule} from '@angular/material/grid-list';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { LMSDateAdapter, LMS_DATE_FORMATS } from './core/constants/datepicker-adapter';


@NgModule({
  exports: [
    A11yModule,
    MatCommonModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatCardModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatRadioModule,
    MatMenuModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatGridListModule,
    MatAutocompleteModule,
    ScrollingModule
  ],
})

export class MaterialModule {
  constructor() {
    //matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
  }

  static forRoot(): ModuleWithProviders<MaterialModule> {
    return {
      ngModule: MaterialModule,
      providers: [
        { provide: DateAdapter, useClass: LMSDateAdapter },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true, disableClose: true } },
        { provide: MAT_DATE_FORMATS, useValue: LMS_DATE_FORMATS },
        { provide: MAT_NATIVE_DATE_FORMATS, useValue: LMS_DATE_FORMATS },
        { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: 'yyyy-MM-dd' } },
      ]
    };
  }
}

