import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { NgModule, LOCALE_ID, ModuleWithProviders } from '@angular/core';
import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';
import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbFormsModule, MdbInputDirective } from 'mdb-angular-ui-kit/forms';
import { MdbModalModule, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbPopoverModule } from 'mdb-angular-ui-kit/popover';
import { MdbRadioModule } from 'mdb-angular-ui-kit/radio';
import { MdbRangeModule } from 'mdb-angular-ui-kit/range';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MdbScrollspyModule } from 'mdb-angular-ui-kit/scrollspy';
import { MdbTabContentDirective, MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';
import { MdbErrorDirective, MdbValidationModule } from 'mdb-angular-ui-kit/validation';
import { A11yModule } from "@angular/cdk/a11y";
import { MdbDropdownDirective } from 'mdb-angular-ui-kit/dropdown';
import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';


@NgModule({
  imports:[
    A11yModule,
    MdbAccordionModule,
    MdbCarouselModule,
    MdbCheckboxModule,
    MdbCollapseModule,
    MdbDropdownModule,
    MdbFormsModule,
    MdbModalModule,
    MdbPopoverModule,
    MdbRadioModule,
    MdbRangeModule,
    MdbRippleModule,
    MdbScrollspyModule,
    MdbTabsModule,
    MdbTooltipModule,
    MdbValidationModule,
  ]
})
export class MdbUiKitModule {
  static forRoot(): ModuleWithProviders<MdbUiKitModule> {
    return {
      ngModule: MdbUiKitModule,
      providers: [
        { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: 'yyyy-MM-dd' } },

        MdbModalService,
      ]
    };
  }
}
