/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppDictionaryService } from './app-dictionary.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { type NavigationExtras, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Overlay } from '@angular/cdk/overlay';
import { LMSDialogComponent } from './shared/dialogs/lms-dialog/lms-dialog.component';
import { ToastrService } from "ngx-toastr";
import { MdbModalRef, MdbModalService } from "mdb-angular-ui-kit/modal";
import { DatePipe } from "@angular/common";
import { ErrorhanderService } from "./core/services/error-handler.service";
import { LoadingSpinService } from './shared/loaders/spinner/loadspinner.service';

@Injectable({
  providedIn: 'root'
})
export class AppUtilityService extends AppDictionaryService {

  private globals: { [key: string]: any; } = {
    ongoing_request_count: 0,
    loading_animation_control: new Subject<any>(),
    modalDialog_control: new Subject<any>(),
    banner_control: new Subject<any>(),
    page_title: ''
  };

  private lmsDialogRef: MdbModalRef<LMSDialogComponent> | null = null;

  httpOptions = {
    headers: new HttpHeaders({ 'content-type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private modalService: MdbModalService,
    private toastrService: ToastrService,
    private loaderService: LoadingSpinService,
    // private lmsPageTitleService: LMSPageTitleService,
    private lmsErrorHandlerService: ErrorhanderService,
    private datePipe: DatePipe,
    private overlay: Overlay
  ) { super(); }

  serviceWrapper(
    HTTP_method: string|'POST',
    API_URL: string,
    responseProcessing: any,
    request_data?: any,
    skip_loading_animation?: string,
  ): Subject<any> {

    const response_subject = new Subject<any>();

    // If it has not been explicitly mentioned to not show the loader, please show.
    if (!skip_loading_animation) {
      this.globals.ongoing_request_count++;
      this.globals.loading_animation_control.next(true);
    }

    // Hide snackbars and banners if any
    this.hideSnackbar();
    this.hideBanner();

    // For local API requests, fetch the JSON file instead
    if (environment.dummy_JSONs) {
      API_URL += '.json';
    }

    this.http.request(
      HTTP_method,
      API_URL,
      request_data
    ).pipe(
      finalize(
        () => {
          if (!skip_loading_animation) {
            if (this.globals.ongoing_request_count > 0) {
              this.globals.ongoing_request_count--;
            }
            // Hiding the loading animation
            this.globals.loading_animation_control.next(false);
          }
        }
      )
    ).subscribe({
      next:(response: any) => {
        // If this is an error object directly send it across
        if (response['errorCode']) {
          response_subject.error(response);
        } else {
          const processed_response = responseProcessing(response);
          if (processed_response.error) {
            response_subject.error(processed_response.error);
          } else {
            response_subject.next(processed_response.data);
          }
        }
      },
      error: (error) => {
        const error_object = {
          'Error message': error || this.error_messages.service_failure
        };
        this.stopLoading();
        response_subject.error(error_object);
      }
    });

    return response_subject;
  }

  login(user_credentials: any) {
    const credentials = { ...user_credentials };
    return this.serviceWrapper(
      'POST',
      this.getAPI('login'),
      (response: any) => {
        if (response.responseCode == 200) {
          return (user_credentials.username == "error") ?
            { 'error': response } : { 'data': response };
        } else {
          return { 'error': response };
        }
      },
      {
        body: credentials
      }
    );
  }

  generateId() {
    return Number.parseInt((Math.random() + 1).toString(36).substring(4) + (Math.random() + 1).toString(36).substring(4));
  }

  downloadFile() {
    return this.serviceWrapper(
      'POST',
      this.getAPI('file_download'),
      () => {
        //const file_name = "dummy_file.pdf";
        //saveAs(response, file_name);
        return { 'data': { 'message': 'download success' } };
      },
      {
        body: {
          'dummy': 'data'
        },
        responseType: "blob"
      }
    );
  }

  uploadFile(form_data: any, API: string) {
    return this.serviceWrapper(
      'POST',
      API,
      (response: any) => {
        return { 'data': { 'message': response.uploadStatus } };
      },
      {
        body: form_data
      },
      'skip_loader_animation'
    );
  }

  updatePageTitle(pageTitle: string) {
    this.globals.page_title = pageTitle;
  }

  showBanner(text?: string) {
    const options = {
      to_show: true,
      text: text || this.error_messages.service_failure
    };
    this.globals.banner_control.next(options);
  }

  hideBanner() {
    const options = {
      to_show: false
    };
    this.globals.banner_control.next(options);
  }

  warning(message?: string, toastTitle?: string, enableHtml?: boolean) {
    setTimeout(() => {
      const toastr_ref = this.toastrService.warning(
        message || this.error_messages.service_failure,
        toastTitle || '',
        {
          newestOnTop: true, timeOut: 7000,
          positionClass: 'toast-top-center',
          enableHtml: enableHtml,
          titleClass: 'h5'
        });
      this.setGlobalData('global_toastr', toastr_ref);
    }, 200);
  }

  success(message?: string, toastTitle?: string) {
    setTimeout(() => {
      const toastr_ref = this.toastrService.success(
        message || this.error_messages.service_failure,
        toastTitle || '',
        {
          newestOnTop: true, timeOut: 6000,
          positionClass: 'toast-top-center'
        });
      this.setGlobalData('global_toastr', toastr_ref);
    }, 300);
  }

  info(message?: string, toastTitle?: string) {
    setTimeout(() => {
      const toastr_ref = this.toastrService.info(
        message || this.error_messages.service_failure,
        toastTitle || '',
        {
          newestOnTop: true, timeOut: 6000,
          positionClass: 'toast-top-center'
        });
      this.setGlobalData('global_toastr', toastr_ref);
    }, 300);
  }

  error(message?: string, toastTitle?: string) {
    setTimeout(() => {
      const toastr_ref = this.toastrService.error(
        message || this.error_messages.service_failure,
        toastTitle || '',
        {
          newestOnTop: true, timeOut: 5000,
          positionClass: 'toast-top-center'
        });
      this.setGlobalData('global_toastr', toastr_ref);
    }, 300);
  }

  handle_returnMessage(errMessage: string) {
    this.stopLoading();
    this.lmsErrorHandlerService.handleString(errMessage, 'Server result error', true);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleError(e: unknown, hdrString?: string, showdate = true) {
    this.stopLoading();
    this.lmsErrorHandlerService.handle(e, 'Server result error', true);
  }


  stopLoading() {
    this.loaderService.stopLoading();
  }

  startLoading(message?: string, showTimer?: boolean | false) {
    this.loaderService.startLoading(message, showTimer);
  }

  hideSnackbar() {
    this.getGlobalData('global_toastr')?.dismiss();
  }

  displayLMSDialog(message?: string) {
    const options = {
      to_show: true,
      message: (!message || message == '') ? "Please wait!" : message,
      text: message || this.error_messages.service_failure,
    };

    this.openModalDialog(options);
  }

  openModalDialog(data?: any) {
    const dialog_ref = this.displayDialogData(
      {
        backdrop: true, ignoreBackdropClick: true, keyboard: true,
        modalClass: 'modal-md modal-fluid modal-dialog-centered modal-center modal-notify modal-info',
        data: data
      });
    return dialog_ref;

    setTimeout(() => {
      const dialog_ref = this.displayDialogData(
        {
          backdrop: true, ignoreBackdropClick: true, keyboard: true,
          modalClass: 'modal-md modal-fluid modal-dialog-centered modal-center modal-notify modal-info',
          data: { data }
        });
      return dialog_ref;
      this.setGlobalData('global_lmsDialog', dialog_ref);
      //this.globals.modalDialog_control.next(dialog_ref);

      this.setGlobalData('global_lmsDialog', dialog_ref);
    }, 0);
  }

  closeLMSModal(returnData?: any) {
    const returnRef = this.getGlobalData('global_lmsDialog');

    return returnRef?.close(returnData);
  }

  displayDialogData2(component: any, options?: any) {
    const global_options = {
      autoFocus: false,
      panelClass: 'lms-dialog-container',
      scrollStrategy: this.overlay.scrollStrategies.noop()
    };
    const dialog_config = { ...global_options, ...options };

    // this.lmsDialogRef = this.modalService.open(LMSDialogComponent, {
    //   backdrop: true, ignoreBackdropClick: true, keyboard: true,
    //   modalClass: 'modal-sm modal-fluid modal-dialog-centered modal-center modal-notify modal-info',
    //   data: {
    //     message: (!message || message == '') ? "Please wait!" : message,
    //     showTimer: withTimer
    //   }
    // });

    this.lmsDialogRef = this.modalService.open(
      component,
      dialog_config
    );
    return this.lmsDialogRef;
  }

  private displayDialogData(options?: any) {
    // const global_options = {
    //   autoFocus: false,
    //   panelClass: 'lms-dialog-container',
    //   scrollStrategy: this.overlay.scrollStrategies.noop()
    // };
    const dialog_config = { ...options };

    // this.lmsDialogRef = this.modalService.open(LMSDialogComponent, {
    //   backdrop: true, ignoreBackdropClick: true, keyboard: true,
    //   modalClass: 'modal-sm modal-fluid modal-dialog-centered modal-center modal-notify modal-info',
    //   data: {
    //     message: (!message || message == '') ? "Please wait!" : message,
    //     showTimer: withTimer
    //   }
    // });
    this.lmsDialogRef = this.modalService.open(
      LMSDialogComponent,
      dialog_config
    );
    return this.lmsDialogRef;
  }

  toggleFormControls(
    form_group: FormGroup,
    control_list: string[],
    to_enable: boolean) {
    const control_count = control_list.length;
    for (let i = 0; i < control_count; i++) {
      const current_control = form_group.get(control_list[i]);
      if (to_enable) {
        current_control?.enable({ emitEvent: false });
      } else {
        current_control?.disable({ emitEvent: false });
      }
    }
  }

  public findInvalidControls(form_group: FormGroup) {
    const invalid: string[] = [];
    const controls = form_group.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    this.info(invalid.join(" "), 'Invalid controls');
    return invalid;
  }

  transformDate(date: string | number | Date): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd')?.toString() || new Date(date).toDateString();
  }

  onRouteActivation() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  navigateToURL(URL: any[], exstras?: NavigationExtras | undefined) {
    this.router.navigate(URL, exstras);
  }

  navigateToLanding() {
    this.router.navigate([this.globals.user_Landing]);
  }

  /* TODO
  getCookie(name: string){
    return this.cookieService.get(name);
  }

  setCookie(name, value){
    this.cookieService.set(name, value, {path: '/', expires: new Date('12/31/9999')});
  }

  deleteCookie(name){
    this.cookieService.delete(name);
  }

  */

  unsubscribeAll(subs: Subscription[]) {
    const sub_count = subs.length;
    for (let i = 0; i < sub_count; i++) {
      const current_sub = subs[i];
      if (current_sub) {
        current_sub.unsubscribe();
      }
    }
  }


  numberOnly(event: any, includeDecimal=false): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if(includeDecimal && (charCode == 44 || charCode == 46)){
      return true;
    }

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  omit_special_char(event: any) {
    const k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  setGlobalData(key: string, value: any) {
    this.globals[key] = value;
  }

  getGlobalData(key: string) {
    return this.globals[key];
  }

  // toggleRow(element: { expanded: boolean; }) {
  //   // Uncommnet to open only single row at once
  //   this.dataSource.forEach(row => {
  //     row.expanded = false;
  //   });
  //   element.expanded = !element.expanded;
  // }

  // manageAllRows(flag: boolean) {
  //   dataSource.forEach(row => {
  //     row.expanded = flag;
  //   });
  // }
}

