import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BranchService } from './branch.service';
import { UploadFileService } from './upload-file.service';
import { ClientDetailsService } from './client-details.service';
import { QuoteStoreService } from './quote-store.service';
import { UserAuditTrailService } from './user-audit-trail.service';
import { RatesAdnFeesService } from './rates-and-fees.service';
import { BiometricsSerivce } from './biometrics.service';
import { LMSConstantsService } from '../constants';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule
  ],
  providers: [
    BranchService,
    UploadFileService,
    //ClientDetailsService,
    //QuoteStoreService,
    UserAuditTrailService,
    RatesAdnFeesService,
    BiometricsSerivce,
    LMSConstantsService
  ]
})
export class RestServicesModule { }
