import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QuoteChangedService {
  private _trackChanges = new BehaviorSubject<boolean>(false);
  private _unsavedChanges = new BehaviorSubject<boolean>(false);

  private _trackEnter = new BehaviorSubject<boolean>(false);
  private _enterPressed = new BehaviorSubject<boolean>(false);
  private isChangesSaved: boolean;
  private allowNavigation: Subject<boolean> = new Subject<boolean>;

  get unsavedChanges() {
    return this._unsavedChanges.asObservable();
  }

  public setUnsavedChanges(changes: boolean) {
    this._unsavedChanges.next(changes);
  }
  get trackChanges() {
    return this._trackChanges.asObservable();
  }

  public setTrackChanges(doTrack: boolean) {
    this._trackChanges.next(doTrack);
  }

  get enterPressed() {
    return this._enterPressed.asObservable();
  }

  public setEnterPressed(enterPressed: boolean) {
    this._enterPressed.next(enterPressed);
  }

  get trackEnter() {
    return this._trackEnter.asObservable();
  }

  public setTrackEnter(doTrack: boolean) {
    this._trackEnter.next(doTrack);
  }

  get NavigationAllowed() {
    return this.isNavigationAllowed;
  }

  private isNavigationAllowed
    (beforeunloadEvent = false): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (!this.trackChanges || !this.unsavedChanges) {
        resolve(true);
      } else {
        if (beforeunloadEvent) {
          resolve(false);
        } else {
          this._unsavedChanges.subscribe(
            hasUnsavedChanges => resolve(!hasUnsavedChanges)
          );
        }
      }
    });
  }

  private isNavigationFromEnter
    (beforeunloadEvent = false): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (!this.trackEnter || !this.enterPressed) {
        resolve(false);
      } else {
        if (beforeunloadEvent) {
          resolve(false);
        } else {
          this._enterPressed.subscribe(
            isEnterPressed => resolve(isEnterPressed)
          );
          this.setEnterPressed(false);
        }
      }
    });
  }
}
