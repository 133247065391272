<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<head>
	<meta
		http-equiv="Cache-Control"
		content="no-cache, no-store, must-revalidate"
	/>
	<meta http-equiv="Pragma" content="no-cache" />
	<meta http-equiv="Expires" content="0" />
</head>
<div class="form-container center-screen">
	<div class="card mt-2">
		<div class="card-body pt-3 px-3">
			<form [formGroup]="searchForm" novalidate>
				<table
					id="clientinfo"
					class="table table-striped table-sm caption-top shadow-1-strong rounded-1 p-1"
					[style.width.px]="900"
				>
					<caption>
						Client and Employment details
					</caption>
					<tbody class="field-4column-label">
            <tr>
							<td>
								<label for="CLientStatus">Client Status</label>
							</td>
							<td>{{clientShowModel.clientStatus}}</td>
							<td>
								<label for="loanStatus">Loan Status</label>
							</td>
							<td>{{clientShowModel.loanStatus}}</td>
						</tr>
						<tr>
							<td>
								<label for="fullNames">Full Names</label>
							</td>
							<td>{{clientShowModel.fullNames}}</td>
							<td>
								<label for="surname">Surname</label>
							</td>
							<td>{{clientShowModel.surname}}</td>
						</tr>
						<tr>
							<td>
								<label for="idNumber">ID Number</label>
							</td>
							<td>{{clientShowModel.idNumber}}</td>
							<td>
								<label for="cellNumber">Cell Number</label>
							</td>
							<td>{{clientShowModel.cellNumber}}</td>
						</tr>
						<tr>
							<td>
								<label for="clientType">Client Type/Source of Funds</label>
							</td>
							<td>
                <span [ngClass]="preAuthorisationModel!.doUpdateEmployment ? 'badge-warning':'badge-success'"
                class="badge p-1 rounded-4"
                style="cursor: pointer;">
								<select [formControlName]="'clientType'"
                lmsSelectOption
                [constantName]="'CLIENTTYPES'"
                required>
									<!-- <option
										*ngFor="let clienttpe of lstClientType"
										[value]="clienttpe.value"
									>
										{{ clienttpe.description }}

									</option> -->

								</select>
                <i class="text-emphasis">{{!preAuthorisationModel!.doUpdateEmployment ? 'existing':'new'}}</i>
              </span>

								<!-- <select formControlName="clientType" placeholder="Client's type *"
              [(ngModel)]="preAuthorisationModel.clientType" required>
              <option *ngFor="let client of lstClientType" [value]="client.value">{{client.description}}
              </option>
            </select> -->
								<div *ngIf="clientType?.invalid">
									<div *ngIf="clientType?.errors?.required && submitted">
										<small class="text-danger">Client type is required.</small>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<label for="maritalStatus">Marital Status</label>
							</td>
							<td>
								<select
									formControlName="maritalStatus"
									placeholder="Marital status *"
                  lmsSelectOption [constantName]="'MARITALSTATUS'"
									required
								>
									<!-- <option
										*ngFor="let marriedstatus of lstMaritalStatus"
										[value]="marriedstatus.value"
									>
										{{marriedstatus.description}}
									</option> -->
								</select>
								<div *ngIf="maritalStatus?.invalid">
									<div *ngIf="maritalStatus?.errors?.required && submitted">
										<small class="text-danger"
											>Marital status is required.</small
										>
									</div>
								</div>
							</td>
							<td>
								<label for="nationality">Nationality</label>
							</td>
							<td>
								<select
									formControlName="nationality"
									(change)="onNationalitySelect($event)"
                  lmsSelectOption
                  [constantName]="'NATIONALITY'"
									[ngClass]="{ 'is-invalid': nationality?.value == 'OTHER' }"
									required
								>
									<!-- <option
										*ngFor="let nat of lstNationality"
										[value]="nat.value"
									>
										{{nat.description}}
									</option> -->
								</select>
								<div *ngIf="nationality?.invalid">
									<div *ngIf="nationality?.errors?.required && submitted">
										<small class="text-danger">
											Only South African nationality is acccepted
										</small>
									</div>
									<div *ngIf="nationality?.value == 'OTHER' && submitted">
										<small class="text-danger">
											Only South African nationality is acccepted
										</small>
									</div>
								</div>
							</td>
						</tr>
						<!-- Loan Amount -->
						<tr>
							<td>
								<label for="loanAmount">Loan Amount</label>
							</td>
							<td>
								<input
									formControlName="loanAmount"
									placeholder="Loan amount *"
									(keypress)="global_utility.numberOnly($event)"
									required
								/>
								<div *ngIf="loanAmount?.invalid">
									<div *ngIf="loanAmount?.errors?.required && submitted">
										<small class="text-danger">Loan amount required.</small>
									</div>
								</div>
							</td>
							<td>
								<label for="loanReason">Loan Reason</label>
							</td>
							<td>
								<select formControlName="loanReason" required>
									<option
										*ngFor="let reason of lstAllLoanReasonCodes"
										[value]="reason.reasonCode"
									>
										{{reason.description}}
									</option>
								</select>
								<div *ngIf="loanReason?.invalid">
									<div *ngIf="loanReason?.errors?.required && submitted">
										<small class="text-danger">Loan reason is required.</small>
									</div>
								</div>
							</td>
						</tr>
						<!-- Source of Wealth -->
						<tr>
							<td>
								<label for="sourceOfWealth">Source of Wealth</label>
							</td>
							<td>
								<select formControlName="sourceOfWealth"
                lmsSelectOption [constantName]="'SOURCEOFWEALTH'"
                required>
									<!-- <option
										*ngFor="let sow of lstSourceOfWealth"
										[value]="sow.value"
									>
										{{sow.description}}
									</option> -->
								</select>
								<div *ngIf="sourceOfWealth?.invalid">
									<div *ngIf="sourceOfWealth?.errors?.required && submitted">
										<small class="text-danger"
											>Source of wealth is required.</small
										>
									</div>
								</div>
							</td>
							<td *ngIf="sourceOfWealth?.value == 'Other'">
								<label for="sourceOfWealthOther">Source of Wealth Other</label>
							</td>
							<td *ngIf="sourceOfWealth?.value == 'Other'">
								<div>
									<input
										formControlName="sourceOfWealthOther"
										placeholder="Source of Wealth *"
										(keypress)="global_utility.omit_special_char($event)"
										[required]="sourceOfWealth?.value == 'Other'"
									/>
								</div>
							</td>
						</tr>
						<!-- Additional Income -->
						<!-- Marketing Options -->
						<tr>
							<td>Marketing Options</td>
							<td colspan="3">
								<mat-select
									[formControlName]="'marketingOptions'"
									multiple
									required
									style="max-width: 215px; padding-left: .3rem;"
                  (valueChange)="updateMarketingOptions($event)"
								>
									<mat-option
										*ngFor="let marketingOption of lstMarketingOptions"
                    [value]="marketingOption.description"
										[disabled]="(marketingOption.description !== 'None' && isMONoneSelected) || (marketingOption.description === 'None' && isMAOtherOptionSelected)"
									>
										{{marketingOption.description}}
									</mat-option>
								</mat-select>
								<!-- <div *ngIf="marketingOptions?.invalid">
									<div *ngIf="marketingOptions?.errors?.required && submitted">
										<small class="text-danger"
											>Marketing options are required.</small
										>
									</div>
								</div> -->
							</td>
						</tr>
						<tr>
							<td>
								<label>Contact Preference</label>
							</td>
							<td>
								<select
									[formControl]="contactPreference"
									placeholder="Contact Preference"
                  (value)="preAuthorisationModel!.contactPreference"
                  lmsSelectOption [constantName]="'CONTACTPREFERENCES'"
									required
								>
									<!-- <option
										*ngFor="let contactPref of lstContactPreferences"
										[value]="contactPref.value"
									>
										{{contactPref.description}}
									</option> -->
								</select>
								<div *ngIf="contactPreference?.invalid && submitted">
									<div *ngIf="contactPreference?.errors?.required">
										<small class="text-danger"
											>Contact preference is required.</small
										>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="4">
								<div *ngIf="ClientTypeSASSA; then showsassa else showsalaried"></div>
								<ng-template #showsassa>
									<!-- Type of grant -->
									<app-sassa-editor
										[sallabel]="preAuthorisationModel.clientType"
										controlKey="sassaTypeControls"
										[Submitted]="submitted"
										[preauthModel]="preAuthorisationModel"
									></app-sassa-editor>
								</ng-template>
								<ng-template #showsalaried>
									<app-salaried-editor
										[sallabel]="preAuthorisationModel.clientType"
										controlKey="salariedTypeControls"
										[Submitted]="submitted"

										[preauthModel]="preAuthorisationModel"
									></app-salaried-editor>
								</ng-template>
							</td>
						</tr>
					</tbody>
				</table>
        <ng-container *ngIf="compuscanOffersEvery">
              <div class="note note-warning d-flex py-3">
                <span>
                  <div class="font-weight-bold">
                    No valid Compuscan offers available
                  </div>
                  No valid Compuscan offers were found for this customer.
                  Please note that NO offers will be available for
                  selection, if you continue.
                </span>
              </div>
        </ng-container>
				<!-- ==   =============   Listing Questions ============= -->
				<table
					id="questions"
					class="table table-striped table-sm mb-2 shadow-1-strong rounded-1 p-1"
					[style.width.px]="850"
				>
					<caption>
						Listing Questions
					</caption>
					<tbody class="field-4column-label">
						<tr>
							<td style="width: 70%;">
								Are you subject to an administration order?
								<div *ngIf="underAdministration?.invalid && submitted">
                  <div *ngIf="underAdministration?.errors?.required">
									<small class="text-danger">
										Under administration option is required.
                    </small>
                  </div>
								</div>
								<div *ngIf="underAdministration?.value == 'yes'">
									<small class="text-danger">
										The client will not qualify for a loan, if subject to an
										administration order.
									</small>
								</div>
							</td>
							<td class="check-end">
								<mat-radio-group
									class="form-control"
									formControlName="underAdministration"
									[value]="preAuthorisationModel.underAdministration"
									required
								>
									<mat-radio-button class="radio-button" value="yes">
										Yes
									</mat-radio-button>
									<mat-radio-button
										class="radio-button"
										value="no"
										color="primary"
									>
										No
									</mat-radio-button>
								</mat-radio-group>
							</td>
						</tr>
						<tr>
							<td style="width: 75%;">
								Are you currently under sequestration?
								<div *ngIf="underSequestration?.invalid && submitted">
                  <div *ngIf="underSequestration?.errors?.required">
                    <small class="text-danger">Under sequestration option is required.</small>
                  </div>
                </div>
								<div *ngIf="underSequestration?.value == 'yes'">
									<small class="text-danger">
										The client will not qualify for a loan, if currently under
										sequestration.
									</small>
								</div>
							</td>
							<td class="check-end">
								<mat-radio-group
									formControlName="underSequestration"
									class="form-control"
                  [value]="preAuthorisationModel.underSequestration"
									required
								>
									<mat-radio-button class="radio-button" value="yes">
										Yes
									</mat-radio-button>
									<mat-radio-button
										class="radio-button"
										value="no"
										color="primary"
									>
										No
									</mat-radio-button>
								</mat-radio-group>
							</td>
						</tr>
						<tr>
							<td style="width: 75%;">
								Are you under debt review or of the intention to apply for debt review?
                <div *ngIf="underDebtReview?.invalid && submitted">
                  <div *ngIf="underDebtReview?.errors?.required">
                    <small class="text-danger">Under debt review option is required.</small>
                  </div>
                </div>
								<div *ngIf="underDebtReview?.value=='yes'">
									<small class="text-danger">
										The client will not qualify for a loan, if currently under
										debt review.
									</small>
								</div>
							</td>
							<td class="check-end">
								<mat-radio-group
									class="form-control"
									formControlName="underDebtReview"
                  [value]="preAuthorisationModel.underDebtReview"
									required
								>
									<mat-radio-button class="radio-button" value="yes">
										Yes
									</mat-radio-button>
									<mat-radio-button
										class="radio-button"
										value="no"
										color="primary"
									>
										No
									</mat-radio-button>
								</mat-radio-group>
							</td>
						</tr>
					</tbody>
				</table>
				<div class="card-footer center-screen">
					<button
						mat-raised-button
						class="btn btn-primary me-2"
						mdbRipple
						color="primary"
						type="button"
						(click)="openRejectOfferDialog()"
					>
						<i class="fas fa-fw fa-xmarks-lines me-1"></i>
						Reject offer
					</button>
					<button
						mat-raised-button
						class="btn btn-primary me-2"
						mdbRipple
						color="primary"
						type="button"
						(click)="valuesDialog()"
					>
						<i class="fas fa-cart-shopping fa-fw me-1"></i>
						View Codix offers
					</button>
					<button
						mat-raised-button
						class="btn btn-primary me-2"
						mdbRipple
						color="primary"
						type="button"
						(click)="lmsDialogHandler.openClientDocuments(clientShowModel)"
					>
						<i class="fas fa-file-invoice fa-fw me-1"></i>
						Client Documents
					</button>
					<button
						class="m-2"
						mat-raised-button
						class="btn btn-primary"
						mdbRipple
						color="primary"
						type="button"
						(dblclick)="global_utility.findInvalidControls(searchForm)"
						(click)="submitClientSearch()"
					> Submit
					</button>
				</div>
			</form>
		</div>
	</div>
</div>
