import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
//import { ClientDetailsService } from '../rest-services/client-details.service';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { AppUtilityService } from 'src/app/app-utility.service';
//import { NextOfKinV2 } from "./nok-editor/nok.model";
import { PageCardheadComponent } from '../../shared/ui/pageheader-card/pageheader-card.component';
import { LMSSelectOptionDirective } from 'src/app/shared/directives/lms-selectOption.directive';
import { NextOfKinModel } from '@lmsModels';

enum CheckBoxType {
  APPLY_FOR_JOB,
  MODIFY_A_JOB,
  NONE,
}

@Component({
  selector: 'app-next-of-kin',
  templateUrl: './next-of-kin.component.html',
  styleUrls: ['./next-of-kin.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LMSSelectOptionDirective,
    PageCardheadComponent,
  ],
})
export class NextOfKinComponent implements OnInit {
  nextOfKin: any;
  // checked = false;
  //indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  //disabled = false;
  clientDetails: any;
  nextOfKinForm: FormGroup;
  nextOfKin2Form: FormGroup;
  nextOfKinModel: NextOfKinModel = new NextOfKinModel();
  hasNextOfKin: boolean;
  hasNextOfKin2: boolean;
  submitted = false;
  isValidForm = false;
  mobNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$';
  addresses: any;
  displayWidth = 390;
  lmsUserProfile: any;
  isReadOnly = false;

  // public lstProvinces = PROVINCES;

  // public lstRelationships = RELATIONSHIP;
  clientStatus: string;

  isSassaClient: boolean = false;

  constructor(
    // public quoteStoreService: QuoteStoreService,
    private formBuilder: FormBuilder,
    public global_utility: AppUtilityService,
    public dialogRef: MdbModalRef<NextOfKinComponent>,
  ) {}

  ngOnInit(): void {
    this.checkClientType();

    this.hasNextOfKin = false;
    this.clientStatus = this.clientDetails.clientStatus;
    //this.nextOfKinModel.onlyOneNextOfKin = this.clientDetails.clientType == 'SASSA' || Array.from(this.nextOfKin).length == 2;
    //this.nextOfKinModel.onlyOneNextOfKin = Array.from(this.nextOfKin).length == 1;

    this.nextOfKinForm = this.formBuilder.group({
      name1: [this.nextOfKinModel.name1, [Validators.required]],
      surname1: [this.nextOfKinModel.surname1, [Validators.required]],
      suburb1: [this.nextOfKinModel.suburb1, [Validators.required]],
      town1: [this.nextOfKinModel.town1, [Validators.required]],
      areaCode1: [
        this.nextOfKinModel.areaCode1,
        [Validators.required, Validators.pattern('[0-9][0-9]{3}')],
      ],
      email1: [this.nextOfKinModel.email1],
      contactNo1: [
        this.nextOfKinModel.contactNo1,
        [Validators.required, Validators.pattern('(0)[0-9]{9}$')],
      ],
      relationship1: [this.nextOfKinModel.relationship1, [Validators.required]],
      addressLine1: [this.nextOfKinModel.addressLine1, [Validators.required]],
      addressLine2: [this.nextOfKinModel.addressLine2],
      province1: [this.nextOfKinModel.province1, [Validators.required]],
    });

    this.nextOfKin2Form = this.formBuilder.group({
      name2: [this.nextOfKinModel.name2, [Validators.required]],
      surname2: [this.nextOfKinModel.surname2, [Validators.required]],
      line1: [this.nextOfKinModel.line1, [Validators.required]],
      line2: [this.nextOfKinModel.line2],
      suburb2: [this.nextOfKinModel.suburb2, [Validators.required]],
      town2: [this.nextOfKinModel.town2, [Validators.required]],
      areaCode2: [
        this.nextOfKinModel.areaCode2,
        [Validators.required, Validators.pattern('[0-9][0-9]{3}')],
      ],
      email2: [this.nextOfKinModel.email2],
      contactNo2: [
        this.nextOfKinModel.contactNo2,
        [Validators.required, Validators.pattern('(0)[0-9]{9}$')],
      ],
      relationship2: [this.nextOfKinModel.relationship2, [Validators.required]],
      province2: [this.nextOfKinModel.province2, [Validators.required]],
    });

    this.validateNextOfKin();
  }

  checkClientType() {
    if (this.clientDetails.clientType == 'SASSA') {
      this.isSassaClient = true;
    }
    this.nextOfKinModel.onlyOneNextOfKin = false;
  }

  onSaveSubmit() {
    this.submitted = true;
    this.isValidForm = true;

    if (this.nextOfKinForm.invalid || this.nextOfKin2Form.invalid) {
      this.isValidForm = false;
      //let invControls = this.findInvalidControls();
      this.global_utility.warning(
        `Please ensure that all the information needed on this page is entered`,
        'Information required',
      );
      return;
    }

    if (this.isSassaClient && this.nextOfKinModel.onlyOneNextOfKin) {
      if (this.nextOfKin2Form.invalid) {
        this.isValidForm = false;
        this.global_utility.warning(
          `SASSA clients must provide details for two Next of Kin`,
          'Information required',
        );
        return;
      }
    }

    if (this.isValidForm) {
      // this.saveNextOfKin();
      // this.updateAddress();
      this.dialogRef.close(this.nextOfKinModel);
    }
  }

  updateAddress() {
    if (this.nextOfKin) {
      const nokSize = this.nextOfKin.length;
      const hasNOK = this.nextOfKin[0];

      if (hasNOK) {
        this.hasNextOfKin = true;
        this.hasNextOfKin2 = false;
      } else {
        if (nokSize == 2) {
          const hasNOK2 = this.nextOfKin[1];
          if (hasNOK2) {
            this.hasNextOfKin2 = true;
          }
        }
      }
    }
  }

  validateNextOfKin() {
    if (this.nextOfKin) {
      const nokSize = this.nextOfKin.length;
      this.hasNextOfKin2 = nokSize > 1;

      //this.nextOfKinModel.onlyOneNextOfKin = !this.hasNextOfKin2;

      const hasNOK = this.nextOfKin[0];
      if (hasNOK) {
        this.hasNextOfKin = true;

        this.nextOfKinModel.name1 = hasNOK.firstName;
        this.nextOfKinModel.relationship1 = hasNOK.relationship;
        this.nextOfKinModel.surname1 = hasNOK.lastName;
        this.nextOfKinModel.email1 = hasNOK.email;
        this.nextOfKinModel.contactNo1 = hasNOK.contactNo1;
        this.nextOfKinModel.address1id = hasNOK.address1id;

        for (const nokaddress of this.addresses) {
          if (
            nokaddress.addressType == 'NEXTOFKIN' &&
            nokaddress.id == hasNOK.homeAddressId
          ) {
            this.nextOfKinModel.addressLine1 = nokaddress.line1;
            this.nextOfKinModel.addressLine2 = nokaddress.line2;
            this.nextOfKinModel.suburb1 = nokaddress.suburb;
            this.nextOfKinModel.province1 = nokaddress.province;
            this.nextOfKinModel.town1 = nokaddress.town;
            this.nextOfKinModel.areaCode1 = nokaddress.code;
          }
        }
      }

      const hasNOK2 = this.nextOfKin[1];
      if (this.hasNextOfKin2 && hasNOK2) {
        this.nextOfKinModel.name2 = hasNOK2.firstName;
        this.nextOfKinModel.relationship2 = hasNOK2.relationship;
        this.nextOfKinModel.surname2 = hasNOK2.lastName;
        this.nextOfKinModel.email2 = hasNOK2.email;
        this.nextOfKinModel.contactNo2 = hasNOK2.contactNo1;
        this.nextOfKinModel.address2id = hasNOK.address2id;

        for (const nok2address of this.addresses) {
          if (
            nok2address.addressType == 'NEXTOFKIN' &&
            nok2address.id == hasNOK2.homeAddressId
          ) {
            console.log(nok2address, 'nok2');

            this.nextOfKinModel.line1 = nok2address.line1;
            this.nextOfKinModel.line2 = nok2address.line2;
            this.nextOfKinModel.suburb2 = nok2address.suburb;
            this.nextOfKinModel.province2 = nok2address.province;
            this.nextOfKinModel.town2 = nok2address.town;
            this.nextOfKinModel.areaCode2 = nok2address.code;
          }
        }
      }
    }
  }

  onReset() {
    this.submitted = false;
    this.nextOfKinForm.reset();
  }

  close() {
    this.dialogRef.close();
  }

  editNextOfKin() {
    this.hasNextOfKin = false;
  }

  get validateNok() {
    return this.nextOfKinForm.controls;
  }

  get validateNok2() {
    return this.nextOfKin2Form.controls;
  }

  get contactNo1() {
    return (
      this.nextOfKinModel.contactNo1 &&
      this.nextOfKinModel.contactNo1.length == 10
    );
  }

  get contactNo2() {
    if (this.nextOfKinModel.onlyOneNextOfKin) {
      return true;
    }

    return (
      this.nextOfKinModel.contactNo2 &&
      this.nextOfKinModel.contactNo2.length == 10
    );
  }

  validateForm() {
    this.isValidForm = true;
    //var control = this.nextOfKinForm.controls;

    if (
      this.nextOfKinForm.invalid ||
      (!this.nextOfKinModel.onlyOneNextOfKin && this.nextOfKin2Form.invalid)
    ) {
      this.isValidForm = false;
    }
  }

  confirmNextOfKinDetails() {
    this.nextOfKinModel.isConfirmedNokDetails = true;
    this.dialogRef.close('Confirmed');
  }
}
