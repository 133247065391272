import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, Observable, of, switchMap, tap } from "rxjs";
import { UserService } from "src/app/core/services/lmsuser.service";
import { environment } from "src/environments/environment";
import { BranchEmployer, quickSearchResult } from "src/app/features/branch-employers/models/branch-employers";
import { EmployerDetailsModel } from "../branch-employers/models/employer-details-model";

@Injectable({ providedIn: "root" })
export class BranchEmployerServiceNew {
  private _preppedforCreate = false;
  private _branchSerial: string;
  employerDetailsModel: EmployerDetailsModel;
  isLoading: Observable<boolean>;

  private _bEMpUrl = `${environment.apiUrl}/loan/api/quote`;
  private _quicksearchUrl = `${this._bEMpUrl}/quickSearch`;

  httpOptions = {
    headers: new HttpHeaders({ 'content-type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
    this._branchSerial = this.userService.userBranchSerial;
  }

  // doesEmailExist(email: string): Observable<boolean> {
  //   const url = `${baseUrl}/contact/emailcheck`;

  //   const content: any = {};
  //   content.email = email;

  //   const response$: Observable<boolean> = this.http.post<boolean>(url, content);

  //   return response$;
  // }

  fetchBranchEmployers(): Observable<BranchEmployer[]> {

    // branch.branchSerial = this._branchSerial;
    // branch.employerName = '';
    let url = `${this._bEMpUrl}/findEmployerByName`;
    console.log("Fetch all employers URL is " + url);

    // return this.http.post<BranchEmployer[]>(getemployerURL, this._branchSerial, this.HttpParamsOptions);
    return this.http
      .post<any>(url, this._branchSerial, this.httpOptions)
      .pipe(
        map((employer) => employer)
      );
  }

  // findEmployersByName(data: any): Observable<any> {
  //   let url = `${baseUrl}/loan/api/quote/findEmployerByName`;
  //   return this.http.post<any>(url, data, this.HttpParamsOptions)
  //     .pipe(
  //       shareReplay(1),
  //     map(employer => {
  //       if(!employer || employer?.length === 0){
  //         return;
  //       }

  //       // if(employer && employer?.length >= 1) {
  //       //   this.employerDetailsModel = employer;
  //       //   this.employer$.next(employer);
  //       // }

  //       return employer;
  //     }));
  // }

  findEmployersByName(data: any): Observable<any> {
    return this.http.get<any>(this._quicksearchUrl, this.httpOptions)
      .pipe(
        //   filter(employer => !!employer),
        catchError(this.handleError<any[]>('quickSearch BranchEmp', [])),

        map(employer => {
          if (employer.length == 0) {
            return;
          }
          if (employer.length == 1) {
            this.employerDetailsModel = employer;
            //this.employer$.next(employer);

          }

          return !employer? of([]): employer;
        }));
  }

  /* GET heroes whose name contains search term */
  quickSearchBranchEmployers(searchdata: any): Observable<quickSearchResult[]> {
    if (!searchdata.ename?.trim()) {
      // if not search term, return empty  array.
      return of([]);
    }

    const qsResult$ = this.http.get<quickSearchResult[]>(`${this._quicksearchUrl}/${searchdata.ename}&${searchdata.bserial}`, this.httpOptions).pipe(
      tap(x => console.log(`:: employers matching "${searchdata.ename}" = ${x.length || 0}`)),
      map((bemp) => bemp),
      catchError(this.handleError<quickSearchResult[]>('quickSearch BranchEmp', []))
    );

    return qsResult$;
  }

  private async handleFetchEmployersResponse(branchEmployers: quickSearchResult[]) {
    //this.branchEmployers = branchEmployers; // .forEach((a) => {id: a.id; employerName: a.employerName});
    //this.bEmployers = branchEmployers;
    //this.setBranchEmps(branchEmployers);
    // this.toastrService.info(this.branchEmployers.length.toString(),'Branch Empoyers recieved');

    //this.filteredEmployers = this.employersearchFormGroup.controls['branchEmployer'].valueChanges.pipe(
    // let filteredEmployers = this.branchEmployer.pipe(
    //   distinctUntilChanged(),
    //   debounceTime(500),
    //   switchMap(value => this.filterEmployer(value))
    // );

    return branchEmployers;
  }


  /**
     * Handle Http operation that failed.
     * Let the app continue.
     *
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(`${operation} failed: ${error.message}`);

      // if (environment.enableDebug) {
      //   this.toastrService.error(`${operation} failed: ${error.message}`, 'Employer Search: ' + error.name);
      // }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
