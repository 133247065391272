import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription,Observable } from 'rxjs';
import { PostcodesService } from 'src/app/core/services/postcodes.service';
import { PostCodeModel } from 'src/app/core/models/postcodes.model';
import { AddressModel } from 'src/app/core/models/address-model.model';
import { AppUtilityService } from "src/app/app-utility.service";
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { MatButtonModule } from '@angular/material/button';
import { DisableControlDirective } from '../../shared/directives/disableControl.directive';
import { NgIf, NgClass } from '@angular/common';
import { PageCardheadComponent } from '../../shared/ui/pageheader-card/pageheader-card.component';

export type PostalType = 'HOME' | 'POSTAL';
export type AddressTypeSearch = 'strcode' | 'area' | 'suburb';
@Component({
    selector: 'app-addresses',
    templateUrl: './addresses.component.html',
    styleUrls: ['./addresses.component.css'],
    standalone: true,
    imports: [PageCardheadComponent, FormsModule, ReactiveFormsModule, NgIf, NgClass,  MatButtonModule]
})
export class AddressesComponent implements OnInit, OnDestroy {
  searchType: AddressTypeSearch = 'strcode';

  private subs = new Subscription();

  addresses: any;
  checked = false;
  disabled = false;
  addressDetailsForm: FormGroup;
  postalDetailsForm: FormGroup;
  addressModel = {} as AddressModel;
  hasAddress: boolean;
  clientDetails: any;
  _postalType: PostalType = 'POSTAL';

  @Input() searchValue = "";
  @Input() debounceTime = 500;
  jsonaddress: PostCodeModel[] = [];
  selectedpCode = {} as PostCodeModel;
  codeslsected = false;

  //homepostalCodes: Observable<any[]>;
  homeAdressSuburb$: Observable<any[]>;
  //homeAdressTown$: Observable<any[]>;


  //postalCodes: Observable<any[]>;
  postalCodeSuburbs$: Observable<any[]>;
  //postalCodeTown$: Observable<any[]>;

  homeAddress: any;
  postalAddress: any;

  isValidFormSubmitted = true;
  // validForm : boolean;
  submitted = false;
  isReadOnly = false;
  clientStatus: string;
  constructor(
    private postcodeService: PostcodesService,
    private formBuilder: FormBuilder,
    public global_utility: AppUtilityService,
    public modalRef: MdbModalRef<AddressesComponent>
  ) { }

  get postalType() {
    return this._postalType;
  }


  ngOnInit(): void {
    this.hasAddress = this.addresses.length > 0;
    this.clientStatus = this.clientDetails.clientStatus;
    this.validateAddress();

    this.addressDetailsForm = this.formBuilder.group({
      'homeAdressLine1': [this.addressModel.homeAdressLine1, [Validators.required]],
      'homeAdressLine2': [this.addressModel.homeAdressLine2],
      'homeAdressSuburb': [this.addressModel.homeAdressSuburb, [Validators.required]],
      'homeAdressTown': [this.addressModel.homeAdressTown, [Validators.required]],
      'homeAdressAreaCode': [this.addressModel.homeAdressAreaCode, [Validators.required, Validators.pattern('[0-9][0-9]{3}')]],
      'homeAdressProvince': [this.addressModel.homeAdressProvince],
    });
    this.postalDetailsForm = this.formBuilder.group({
      postalAdressLine1: [this.addressModel.postalAdressLine1, [Validators.required]],
      postalAdressLine2: [this.addressModel.postalAdressLine2],
      postalAdressSuburb: [this.addressModel.postalAdressSuburb, [Validators.required]],
      postalAdressTown: [this.addressModel.postalAdressTown, [Validators.required]],
      postalAdressAreaCode: [this.addressModel.postalAdressAreaCode, [Validators.required, Validators.pattern('[0-9][0-9]{3}')]],
      postalAdressProvince: [this.addressModel.postalAdressProvince],
      postalType: [this._postalType]
      //'poBoxNumber':[this.addressModel.poBoxNumber]
    });



    // this.homeAdressSuburb$ = this.addressDetailsForm.controls['homeAdressSuburb']?.valueChanges.pipe(
    //   startWith(''),
    //   debounceTime(this.debounceTime),
    //   map(pCodeCity => {
    //     this.switchsearch('suburb');
    //     return this.json_ADDRESS_filter(pCodeCity || '');
    //   })
    // );
  }

  onHomeOptionClick($event: any) {
    const newList = $event.option.value;

    const area = newList.AREA.toLowerCase();
    const suburb = newList.SUBURB.toLowerCase();
    const code = newList.CODE.toLowerCase();

    this.addressModel.homeAdressAreaCode = code;
    this.addressModel.homeAdressSuburb = suburb;
    this.addressModel.homeAdressTown = area;


    //this.addressDetailsForm.controls['homeAdressSuburb'].setValue(suburb);

    if (this.addressModel.sameAsAdress) {
      this.onOptionClick($event);
    }
  }

  resetValue() {
    console.log("Value -> ", this.addressDetailsForm.value.homeAdressTown);
    this.addressDetailsForm.value.homeAdressTown = "test";
    this.addressModel.homeAdressTown == '';
  }

  onOptionClick($event: any) {
    const newList = $event.option.value;

    const area = newList.AREA.toLowerCase();
    const suburb = newList.SUBURB.toLowerCase();
    const code = newList.CODE.toLowerCase();

    this.addressModel.postalAdressAreaCode = code;
    this.addressModel.postalAdressSuburb = suburb;
    this.addressModel.postalAdressTown = area;
    //this.postalDetailsForm.controls['postalAdressSuburb'].setValue(suburb);
  }

  ngOnDestroy(): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  switchsearch(type: AddressTypeSearch) {
    this.searchType = type;
  }

  // JSON ADDRESS Data Filter
  private json_ADDRESS_filter(value: string): string[] {
    //const filterValue = value.toLowerCase();
    const newList: any[] = [];
    // this.jsonaddress.forEach(element => {
    //   if (element.STRCODE.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1) {
    //     newList.push({ 'AREA': element.AREA, 'SUBURB': element.SUBURB, 'CODE': element.STRCODE });
    //   }

    //   if (element.AREA.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1) {
    //     newList.push({ 'AREA': element.AREA, 'SUBURB': element.SUBURB, 'CODE': element.STRCODE });
    //   }

    //   if (element.SUBURB.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1) {
    //     newList.push({ 'AREA': element.AREA, 'SUBURB': element.SUBURB, 'CODE': element.STRCODE });
    //   }

    // });
    return newList.slice(1, 30);
  }


  onCodeClicked(codeItem?: any) {
    this.selectedpCode = codeItem as PostCodeModel;
    this.codeslsected = true;
  }


  onSameAddressChange() {
    this.addressModel.sameAsAdress = !this.addressModel.sameAsAdress;
    this._postalType = 'POSTAL';
  }

  close() {
    this.modalRef.close(); //this.postalDetailsForm.value??
  }

  saveAddress() {
    this.submitted = true;
    let isValidData = true;

    if (this.addressDetailsForm.invalid) {
      isValidData = false;
    }

    if (!this.addressModel.sameAsAdress) {
      if (this.postalDetailsForm.invalid) {
        isValidData = false;
      }
    }

    if (isValidData) {
      this.addressModel.addressType = this._postalType;
      //this.updateAddresses(this.addressModel);
      return this.modalRef.close(this.addressModel);
    }
    //else {
    //alert('Please enter all the address information needed');
    // this.toastrService.warning('Please enter all the address information needed', 'Address information',{ positionClass: 'toast-top-center'});
    //}
  }

  validateAddress() {
    this.hasAddress = false;
    let haspostal = false;

    for (const address of this.addresses) {
      if (address.addressType == 'HOME') {
        this.homeAddress = address;
        this.hasAddress = true;
      }
      if (address.addressType == 'POSTAL') {
        this.postalAddress = address;
        this.hasAddress = true;
        haspostal = true;
      }
    }

    this.addressModel.sameAsAdress = (haspostal && this.postalAddress?.line1 == this.homeAddress?.line1) || false;
    this._postalType = !this.addressModel.sameAsAdress ? 'POSTAL' : 'HOME';
  }

  editAddress() {
    this.hasAddress = false;
    let haspostal = false;

    if (this.postalAddress) {
      haspostal = true;
      this.addressModel.postalAdressLine1 = this.postalAddress.line1;
      this.addressModel.postalAdressLine2 = this.postalAddress.line2;
      this.addressModel.postalAdressSuburb = this.postalAddress.suburb;
      //this.postalDetailsForm.controls['postalAdressSuburb'].setValue(this.postalAddress.suburb);

      this.addressModel.postalAdressTown = this.postalAddress.town;
      this.addressModel.postalAdressAreaCode = this.postalAddress.code;
      this.addressModel.postalAdressProvince = this.postalAddress.province;
    }

    this.addressModel.homeAdressLine1 = this.homeAddress.line1;
    this.addressModel.homeAdressLine2 = this.homeAddress.line2;
    this.addressModel.homeAdressSuburb = this.homeAddress.suburb;
    //this.addressDetailsForm.controls['homeAdressSuburb'].setValue(this.homeAddress.suburb);
    this.addressModel.homeAdressTown = this.homeAddress.town;
    this.addressModel.homeAdressAreaCode = this.homeAddress.code;
    this.addressModel.homeAdressProvince = this.homeAddress.province;
    // this.addressModel.poBoxNumber = this.postalAddress.line1;

    this.addressModel.sameAsAdress = ((this.postalAddress) && this.postalAddress?.line1 == this.homeAddress?.line1) || false;
    this._postalType = (!this.addressModel.sameAsAdress || !this.postalAddress?.line2) && haspostal ? 'POSTAL' : 'HOME';
    //this.addressModel.sameAsAdress = (this.homeAddress.line1 == this.postalAddress?.line1) || true;

    // this.postalCodeSuburbs$ = this.postalDetailsForm.controls['postalAdressSuburb']?.valueChanges.pipe(
    //   startWith(''),
    //   debounceTime(this.debounceTime),
    //   map(pCodeCity => {
    //     this.switchsearch('suburb');
    //     return this.json_ADDRESS_filter(pCodeCity || '');
    //   })
    // );
  }

  updateAddresses(addressModel: any) {
    this.homeAddress.line1 = addressModel.homeAdressLine1;
    this.homeAddress.line2 = addressModel.homeAdressLine2;
    this.homeAddress.suburb = addressModel.homeAdressSuburb;
    this.homeAddress.town = addressModel.homeAdressTown;
    this.homeAddress.code = addressModel.homeAdressAreaCode;
    this.homeAddress.province = addressModel.homeAdressProvince;

    //this.postalAddress.line1 = this.addressModel.postalType == 'POSTAL' ? '' : '' + addressModel.postalAdressLine1;
    this.postalAddress.line1 = addressModel.postalAdressLine1;
    this.postalAddress.line2 = addressModel.postalAdressLine2;
    this.postalAddress.suburb = addressModel.postalAdressSuburb;
    this.postalAddress.town = addressModel.postalAdressTown;
    this.postalAddress.code = addressModel.postalAdressAreaCode;
    this.postalAddress.province = addressModel.postalAdressProvince;
  }

  get validateAddressesInput() {
    return this.addressDetailsForm.controls;
  }

  get validatePostalAddressesInput() {
    return this.postalDetailsForm.controls;
  }

  confirmAddDetails() {
    this.addressModel.isConfirmedAddressDetails = true;
    this.addressModel.quoteId = this.clientDetails.quoteId;

    //this.editAddress();

    this.modalRef.close(this.addressModel);
  }

}

