<div class="center-screen">
    <br>
    <table id="cbsLogin" class="table table-striped table-hover table-sm mb-3 caption-top shadow-1-strong rounded-1"
        style="width:550px">
        <caption>Log into CBS</caption>
        <tbody style="height: 100px;" class="center-screen">
            <tr>
                <td><b>1. Log in to CBS to transfer the funds to the client's bank account.</b></td>
            </tr>
            <tr>
                <td><b>2. Please use the statement reference number when transferring the funds.</b></td>
            </tr>
            <tr>
                <td><b>3. Please pay out the amount reflected in the Disbursed Amount field.</b></td>
            </tr>
        </tbody>
    </table>
</div>
<button mat-raised-button class="btn btn-primary me-2" mdbRipple color="primary" type="button"
    (click)="close()">Close</button>