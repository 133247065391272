export { DocumentViewerComponent } from './document-viewer/document-viewer.component';
export { LmsGridviewComponent } from './lms-gridview/lms-gridview.component';
export * from './lms-paginate/jw-paginate';
export { LmsClientSearchComponent } from './lmsclient-search/lmsclient-search.component';
export { PageCardheadComponent } from './pageheader-card/pageheader-card.component';

// import * as i0 from '@angular/core';
// import * as i1 from '@angular/common';
// import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
// import { MdbValidationModule } from 'mdb-angular-ui-kit/validation';

// //export * from 'src/app/shared/ui';
// //export * from 'src/app/shared/directives';
// //export * from 'src/app/shared/pipes';

// import { PageCardheadComponent } from './ui/pageheader-card/pageheader-card.component';
// import { LMSPageHeadComponent } from '../core/guard/LMSPageHeader.guard';
// import { ClientHeaderComponent } from '../modules/client-header/client-header.component';
// import { LmsClientSearchComponent } from './ui/lmsclient-search/lmsclient-search.component';
// import { LmsGridviewComponent } from './ui/lms-gridview/lms-gridview.component';
// import { LmsPaginateComponent } from './ui/lms-paginate/lms-paginate.component';
// import { LMSDatePickerModule } from '../core/constants/datepicker-adapter';
// import { LMSDialogComponent } from './dialogs/lms-dialog/lms-dialog.component';



// export declare class LMSClientHeaderModule {
//   static ɵfac: i0.ɵɵFactoryDeclaration<LMSClientHeaderModule, never>;
//   static ɵmod: i0.ɵɵNgModuleDeclaration<LMSClientHeaderModule, [typeof ClientHeaderComponent], [typeof i2.MatCommonModule, typeof i3.MatFormFieldModule], [typeof ClientHeaderComponent]>;
//   static ɵinj: i0.ɵɵInjectorDeclaration<LMSClientHeaderModule>;
// }

// // export declare class LMSPageCardheadModule {
// //   static ɵfac: i0.ɵɵFactoryDeclaration<LMSPageCardheadModule, never>;
// //   static ɵmod: i0.ɵɵNgModuleDeclaration<LMSPageCardheadModule, [typeof LMSPageHeadComponent], [typeof i1.CommonModule, typeof i1.Input, typeof i1.OnInit],  [typeof i2.LMSPageCardheadModule]>;
// //   static ɵinj: i0.ɵɵInjectorDeclaration<LMSPageCardheadModule>;
// // }

// export declare class LMSPageCardheadModule {
//   static ɵfac: i0.ɵɵFactoryDeclaration<LMSPageCardheadModule, never>;
//   static ɵmod: i0.ɵɵNgModuleDeclaration<LMSPageCardheadModule, [typeof LMSPageHeadComponent], [typeof i2.CommonModule, typeof i3.FormsModule], [typeof LMSPageHeadComponent]>;
//   static ɵinj: i0.ɵɵInjectorDeclaration<LMSPageCardheadModule>;
// }

// export declare class LmsClientSearchModule {
//   static ɵfac: i0.ɵɵFactoryDeclaration<LmsClientSearchModule, never>;
//   static ɵmod: i0.ɵɵNgModuleDeclaration<LmsClientSearchModule, [typeof i2.LmsClientSearchComponent], [typeof i1.CommonModule, typeof i1.ReactiveFormsModule, typeof i3.MdbFormsModule, typeof MdbValidationModule, typeof i4.NumbersOnlyDirective],  [typeof i2.LmsClientSearchModule]>;
//   static ɵinj: i0.ɵɵInjectorDeclaration<LmsClientSearchModule>;
// }

// export declare class LmsGridViewModule {
//   static ɵfac: i0.ɵɵFactoryDeclaration<LmsGridViewModule, never>;
//   static ɵmod: i0.ɵɵNgModuleDeclaration<LmsGridViewModule, [typeof LmsGridviewComponent], [typeof i1.CommonModule, typeof i1.ReactiveFormsModule, typeof i3.MdbFormsModule, typeof MdbValidationModule, typeof i4.NumbersOnlyDirective],  [typeof LmsGridviewComponent]>;
//   static ɵinj: i0.ɵɵInjectorDeclaration<LmsGridViewModule>;
// }

// export declare class LMSCompononentsModule {
//   static ɵmod: i0.ɵɵNgModuleDeclaration<LMSCompononentsModule, [typeof LmsGridviewComponent], [typeof i5.LmsClientSearchComponent, typeof i5.LmsGridviewComponent, typeof i5.LmsPaginateComponent, typeof i5.LMSDatePickerModule,typeof i5.PageCardheadComponent], never>;
//   static ɵinj: i0.ɵɵInjectorDeclaration<LMSCompononentsModule>;
// }
