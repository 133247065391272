export interface QuoteViewModel {
  quoteId: number;
  idNumber: string;
  firstName: string;
  lastName: string;
  cellNumber: string;
  loanPurpose: string;
  status: string;
  clientNumber: number;
  userName: string;
  branchName: string;
}

export class QuoteModel implements QuoteViewModel {
  quoteId: number;
  idNumber: string;
  firstName: string;
  lastName: string;
  cellNumber: string;
  loanPurpose: string;
  status: string;
  userName: string;
  branchName: string;
  clientNumber: number;
  clientStatus: string;
  rejectOfferReason: string;
  rejectCompliance: string;
  rejectOtherReason: string;
  branchSerial: any;
  branchSerialNumber: any;
  myBenefitsOption: any;
  clientType: any;
  isConfirmedAddressDetails: boolean;
  isConfirmedEmploymentDetailsDetails: boolean;
  isConfirmedBankingDetails: boolean;
  isConfirmedNokDetails: boolean;
  isConfirmedDocScanningDetails: boolean;
  loanProcessingConfirmed: boolean = false;
  newLoanProcessingConfirmed: boolean = false;
}
