<div class="modal-content document-container">
  <div class="modal-header">
    <button class="btn btn-close " color="red"  (click)="dialogRef.close()" ></button>
    <div class="modal-title center-fields">
      <app-page-cardhead style="margin-top: -2.1rem;" [PageName]="'Document Upload'" [PageHeadWidth]=700></app-page-cardhead>
    </div>
  </div>

  <div class="modal-body" >
    <div class="card">
      <div class="card-body p-1">
        <div class="form-container center-screen container-fluid bd-example-row">
      <!-- <div class="card">
        <div class="card-body p-1"> -->
    <!--

      NOTE TO ALL DEVELOPERS. DO NOT CHANGE ANYTHING ON THIS COMPONENT!!!

    -->

      <table id="clientHeader" class="table p-1 table-striped table-hover table-sm mb-3 caption-top shadow-1-strong rounded-1" style="width:800px">
      <caption>Client Details</caption>
        <tbody class="field-4column-label">
          <tr>
            <td>First names</td>
            <td>{{clientDetails.firstName}}</td>

            <td>Surname</td>
            <td>{{clientDetails.lastName}}</td>
          </tr>

          <tr>
            <td>ID number</td>
            <td>{{clientDetails.idNumber}}</td>

            <td>Cell number</td>
            <td>{{clientDetails.cellNumber}}</td>
          </tr>
        </tbody>
      </table>

      <div class="center-screen">
        <table class="table table-striped p-1 table-auto table-hover table-sm mb-3 caption-top shadow-1-strong rounded-1" style="width:980px">
          <caption>Document List</caption>
          <thead>
            <tr>
              <th>Document</th>
              <th>File name</th>
              <th>Upload date</th>
              <th>Uploaded by</th>
              <th>View</th>
              <th *ngIf="!isReadOnly">Delete</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let doc of lstDocuments | paginate: { itemsPerPage: pageSize, currentPage: pager.currentPage };">
              <td>{{doc.documentType}}</td>
              <td style="text-overflow: ellipsis;">{{doc.originalFileName}}</td>
              <td style="width: 130px;">{{covertDate(doc.uploadDate)}}</td>
              <td style="width: 135px;">{{doc.userName}}</td>
              <td style="width: 40px;">
                <button
									mat-icon-button
									class="btn btn-primary btn-rounded"
									mdbRipple
									color="primary"
									(click)="onDownloadPdf(doc.fileName)">
									<i class="fas fa-sm fa-fw fa-eye"></i>
								</button>
              </td>
              <td *ngIf="!isReadOnly" style="width: 40px;">
                <span *ngIf="!(doc.documentType === 'CODIX REPORT')">
                  <button mat-icon-button
                  class="btn btn-primary btn-rounded"
                  mdbRipple
                  color="primary" (click)="deleteDocument(doc)">
                    <i class="fas fa-xs fa-trash"></i>
                  </button>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <br>
        <div class="lms-pager my-2">
          <div *ngIf="pager.pages && pager.pages?.length && pager.pages?.length > 1" class="btn-toolbar" role="toolbar" aria-label="LMS Pager group">
            <div class="btn-group btn-group-sm" role="group" aria-label="Move Previous group">

              <button type="button" class="btn btn-primary  rounded-start" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5; margin: 0;" (click)="setPage(1)" [disabled]="pager.currentPage === 1">First</button>
              <button type="button" class="btn btn-primary  rounded-0" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5; margin: 0;"(click)="setPage(pager.currentPage - 1)" [disabled]="pager.currentPage === 1">Previous</button>
            </div>
            <div class="btn-group btn-group-sm" role="group" aria-label="PageNumbers">
              <button *ngFor="let page of pager.pages" type="button" data-mdb-ripple-color="#7a7676" style="background-color:#edf2fa; margin: 0; color: #7a7676;" [ngClass]="{active:pager.currentPage === page}" (click)="setPage(page)" class="btn btn-outline-secondary page-item number-item  rounded-0">{{page}}</button>
            </div>
            <div class="btn-group btn-group-sm" role="group" aria-label="Move Next group">
              <button type="button" class="btn btn-primary rounded-0" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5; margin: 0;" (click)="setPage(pager.currentPage + 1)" [disabled]="pager.currentPage === pager.totalPages">Next</button>
              <button type="button" class="btn btn-primary rounded-end" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5;margin: 0;" (click)="setPage(pager.totalPages)" [disabled]="pager.currentPage === pager.totalPages">Last</button>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!isReadOnly" class="center-screen">
        <table class="table table-striped table-hover table-sm my-3 caption-top shadow-1-strong rounded-1 p-1" style="width:700px">
          <caption>Document Upload</caption>
          <tbody>
            <tr>
              <td>
                <select mdbInput class="form-control form-select me-1" [(ngModel)]="selectedDocType" style="width: 210px;"
                (ngModelChange)="onReqDocSelect($event)" required>
                  <option value="">-- Select --</option>
                  <option *ngFor="let doc of requiredDocs" [ngValue]="doc">{{doc.documentName}}</option>
                </select>
              </td>

              <td>
                <div class="input-group p-1">
                  <input mdbInput type="file" class="form-control shadow-1-strong align-content-around py-2" id="inputGroupFile02" aria-describedby="inputGroupFileAddon02"
                  (change)="onFileSelected($event)"
                  accept="application/pdf" #fileSelector aria-label="Submit" />
                  <button class="btn btn-primary" mdbWavesEffect type="button" id="inputGroupFileAddon02" (click)="onDocumentSubmit()">
                    Submit
                  </button>
                </div>
              </td>
              <!-- <td>
                <button type="button" mdbBtn color="primary" mdbWavesEffect class="btn btn-primary form-file-button"  (click)="onDocumentSubmit()"
                  [disabled]="false">
                  <div class="submitBtn"> Submit </div>
                </button>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
      </div>
      <div class="card-footer center-screen">
        <div class="center-screen">
          <button class="btn btn-primary me-2" mdbRipple
          (click)="close()"> Close </button>
          <button *ngIf="clientStatus === 'REPEAT' " class="btn btn-primary ms-2" type="button" (click)="confirmDocumentScanningDetails()">
            <i class="fas fa-thumbs-up fa-fw me-1"></i>
            Confirm </button>
        </div>
      </div>
    </div>
  </div>
</div>
