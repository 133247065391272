
import { CommonModule } from "@angular/common";
import { Component,  ViewEncapsulation } from '@angular/core';
import { MdbModalRef, MdbModalService, MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-lmsspinner',
  templateUrl: 'spinner.component.html',
  styleUrls: ['./spinner.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  providers: [
    MdbModalService
  ],
  imports: [CommonModule, MdbModalModule],
})
export class SpinnerComponent {
  showTimer = false;

  message: string | null = null;

  public showClock$ = new BehaviorSubject<boolean>(this.showTimer);
  display = '03:00';

  public constructor(private modalService: MdbModalService, private modalRef: MdbModalRef<SpinnerComponent>) {
    // this.loaderService.isLoading.subscribe((v) => {
    //   console.log(v);
    //   this.loading = v;
    // });
    this.timer(3);
  }

  // public openPopup(dta: SpinnerModel) {
  //   this.modalRef = this.modalService.open(SpinnerComponent, {
  //     modalClass: 'modal-fluid modal-full-height modal-top modal-notify modal-info',
  //     data: dta
  //   });

  //   return this.modalRef;
  // }

  timer(minute: any) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec = 60;
    const prefix = minute < 10 ? "0" : "";

    setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      //if (seconds == 0) {
        //this.keycloak.logout();
      //}
    }, 1000);
  }

  public setNewDataMessage(message?: string) {
    this.message = message == '' || message == undefined ? "Please wait!!" : message;
  }
}
