/**
* LMS ClientApp Date adapter
*/
import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { NgModule} from '@angular/core';
import { Injectable } from '@angular/core';
import { DateAdapter, MatCommonModule, MatNativeDateModule, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Injectable({
  providedIn: 'root'
})
export class LMSDateAdapter extends NativeDateAdapter {

  override format(date: Date, displayFormat: Object): string {

    if (displayFormat === 'input') {

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${year}-${month}-${day}`;
    }

    return date.toDateString();
  }
}

export const LMS_DATE_FORMATS =
{
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthLabel: 'short',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'short', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'short' },
  }
};

@NgModule({
  declarations: [],
  imports: [MatCommonModule],
  exports: [MatDatepickerModule, MatNativeDateModule],
  providers: [
     { provide: DateAdapter, useClass: LMSDateAdapter },
     { provide: MAT_DATE_FORMATS, useValue: LMS_DATE_FORMATS },
     { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: 'yyyy-MM-dd' } },
  ]
})

export class LMSDatePickerModule {

}

// @Injectable()
// export class NgbDateDayjsAdapter extends NgbDateAdapter<dayjs.Dayjs> {
//  fromModel(date: dayjs.Dayjs | null): NgbDateStruct | null {
//    if (date && dayjs.isDayjs(date) && date.isValid()) {
//      return { year: date.year(), month: date.month() + 1, day: date.date() };
//    }
//    return null;
//  }

//  toModel(date: NgbDateStruct | null): dayjs.Dayjs | null {
//    return date ? dayjs(`${date.year}-${date.month}-${date.day}`) : null;
//  }
// }
