import { Directive, Input } from '@angular/core';
import { NgControl } from "@angular/forms";

@Directive({
    selector: '(formControlName, [formControlName], [formControl])[disabledControl]',
    standalone: true
})
export class DisableControlDirective {
  @Input() set disableControl(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    setTimeout(() => {
      this.ngControl.control![action]();
      this.ngControl.control?.updateValueAndValidity();
    });
  }

  constructor(private ngControl: NgControl) {
  }

}
