<app-page-cardhead [PageName]="'New Region Details'" [PageHeadWidth]="500"></app-page-cardhead>

<div class="container-md center-screen">
  <form [formGroup]="regionForm">
    <div class="card me-auto ms-auto" [style.width.px]="500">
      <div class="card-body pt-1 py-2">
          <table class="table table-striped table-hover table-sm caption-top">
            <tbody class="field-4column-label">
              <tr>
                <td>Region name</td>
                <td>
                  <input formControlName="region"
                    [(ngModel)]="regionModel.name"
                    type="text"
                    required>
                  <div *ngIf="regionForm.controls.region.errors?.required && submitted" class="error-label">
                    Region name is required
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
      </div>

      <div class="card-footer center-screen">
        <button mat-raised-button class="btn btn-primary btn-rounded me-2"
          mdbRipple color="primary" (click)="close()"> Close
        </button>
        <button mat-raised-button class="btn btn-primary btn-rounded"
          mdbRipple color="primary"
          (click)="saveRegion()">Save region</button>
      </div>
    </div>
  </form>
</div>
