import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import paginate from '@lmsPaginate';
import { BranchService } from '@lmsrestServices';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { AreaModel, RegionModel } from 'src/app/core/models/region.model';
import { LoadingSpinService } from 'src/app/shared/loaders/spinner/loadspinner.service';
import { AreaAdminDialogComponent } from './area-admin-dialog/area-admin-dialog.component';
import { RegionAdminDialogComponent } from './region-admin-dialog/region-admin-dialog.component';
import { RegionsAddComponent } from './regions-add/regions-add.component';
import { AreaAddComponent } from './area-add/area-add.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { NgFor, NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-regions-admin',
    templateUrl: './regions-admin.component.html',
    styleUrls: ['./regions-admin.component.css'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgFor, MatListModule, MatButtonModule, MdbRippleModule, NgIf, NgClass, NgxPaginationModule]
})
export class RegionsAdminComponent implements OnInit {

  @Input() items: Array<any>;
  @Output() changePage = new EventEmitter<any>(true);
  @Input() initialPage = 1;
  @Input() pageSize = 9;
  @Input() maxPages = 3;
  regionAdminForm: FormGroup;
  //isEditing = false;
  enableEditIndex: number;
  editAreaIndex: number;
  lstAreas: AreaModel[];
  areaSelected: AreaModel = {} as AreaModel;
  isAreaEditing = false;

  pager: any = {};
  dbregions: RegionModel[];
  regionSelected: RegionModel = {} as RegionModel;
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private crudDialog: MatDialog,
    private branchService: BranchService,
    private loadingService: LoadingSpinService,
    private toastrService: ToastrService,
  ) {
    this.regionAdminForm = this.fb.group({
      id: [0],
      regionNumber: [0],
      name: ['', [Validators.required]],
      areas: new FormArray([])
    });

    of(this.branchService.getAllRegions().subscribe(
      {
        next: (regionres) => {
          if (regionres) {

            this.items = regionres;
            this.dbregions = this.items;

            if (this.items && this.items.length) {
              this.setPage(this.initialPage);
            }

          }
          this.loadingService.stopLoading();
        }
      }));
  }
  ngOnInit(): void { }

  selectRegion(region: RegionModel) {
    // check if regionSelected is empty, before assigning a selected region
   // if (Object.keys(this.regionSelected).length === 0) {
      this.regionSelected = region;


    this.crudDialog.open(RegionAdminDialogComponent, {
      disableClose: true,
      autoFocus: true,
      height: 'auto',
      width: 'auto',
      data: {
        region: this.regionSelected,
        //regionNo : lstSize
      },
    }).afterClosed().subscribe((new_region) => {
      if(new_region){
        console.log("new reg" , new_region);

 // for (let area of this.lstAreas) {
      //   console.log(area);
      //   if (area.id == event.target.value) {
      //     this.regionModel.areas.push(area);
      //     this.regionSelected.areas.push(area);
      //   }
      // }

      //SAve to DB
        this.branchService.saveRegion(this.regionSelected).subscribe((res) => {
          if(!res) {
            this.toastrService.error('Update region failed.','Add branch failed!')
          }
           //this.dbregions = res;
          //getAllRegions
          this.branchService.getAllRegions().subscribe((result) => {
              if(!result) {
                this.toastrService.error('Update region failed.','Add branch failed!')
              }
              this.dbregions = result;
          });

          // this.loadingService.stopLoading();
        });
      }

    //  }
      // this.regionSelected = {} as RegionModel;
      // this.areaSelected = {} as AreaModel;
      // this.isEditing = false;
      // this.isAreaEditing = false;
    });
     // oldcode
    // if (Object.keys(this.regionSelected).length === 0) {
    //   this.regionSelected = region;
    //   this.isEditing = true;

    //   this.regionAdminForm.patchValue({
    //     id: this.regionSelected.id,
    //     name: this.regionSelected.name,
    //     regionNumber: this.regionSelected.regionNumber || this.regionSelected.id
    //   });
    //   this.lstAreas = this.regionSelected.areas;

    //   (<FormArray>this.regionAdminForm.get('areas')).clear();

    //   if (this.regionSelected.areas.length > 0) {
    //     for (let arreas of this.regionSelected.areas) {
    //       (<FormArray>this.regionAdminForm.get('areas')).push(
    //         new FormGroup({
    //           id: new FormControl(arreas.id),
    //           name: new FormControl(arreas.name)
    //         })
    //       );
    //     }
    //   }
    // }
  }

  addAreaRow() {
    const newArea = new AreaModel();
    newArea.id = 0;
    newArea.name = '';

    const areasForm = this.fb.group({
      id: [newArea.id, []],
      name: [newArea.name, [Validators.required]]
    });

    this.regionAdminForm.get('areas')?.enable();
    this.regionAdminForm.get('areas')?.addValidators(Validators.required);
    this.regionAdminForm.get('areas')?.updateValueAndValidity();

    this.lstAreas.push(newArea);
    (<FormArray>this.regionAdminForm.get('areas')).push(areasForm);
  }

  get areas() {
    return (this.regionAdminForm?.get('areas') as FormArray).controls;
  }

  createNewRegion() {
    // this.dbregions.unshift({
    //   id: 0,
    //   name: '',
    //   regionNumber: '-',
    //   areas: [{ id: 0, name: '' }]
    // });

    // this.selectRegion(this.dbregions[0]);
    // //this.addAreaRow();
    // this.selectArea(this.dbregions[0].areas[0]);
    let lstSize = this.dbregions.length;

    this.crudDialog.open(RegionsAddComponent, {
      disableClose: true,
      autoFocus: true,
      height: 'auto',
      width: 'auto',
      data: {
        region: this.selectRegion,
        regionNo : lstSize
      },
    }).afterClosed().subscribe((new_region) => {
      if(new_region){
       // new_region.
      //Save to DB
        this.branchService.createRegion(new_region).subscribe((res) => {
          if(!res) {
            this.toastrService.error('Add new branch failed.','Add branch failed!')
          }
           this.dbregions = res;
          // this.loadingService.stopLoading();
        });
      } else {
        console.log("why");
      }

    //  }
      // this.regionSelected = {} as RegionModel;
      // this.areaSelected = {} as AreaModel;
      // this.isEditing = false;
      // this.isAreaEditing = false;
    });
  }


  // createNewArea() {
  //   console.log("addNewArea");
  //   this.crudDialog.open(AreaAddComponent, {
  //     disableClose: true,
  //     autoFocus: true,
  //     height: 'auto',
  //     minWidth: '600px',
  //     data: {
  //       regions: this.dbregions,
  //      // regionNo : lstSize
  //     },
  //   }).afterClosed().subscribe((new_area) => {
  //     if(new_area){
  //     //SAve to DB
  //       this.branchService.saveArea(new_area).subscribe((res) => {
  //         if(!res) {
  //           this.toastrService.error('Add new branch failed.','Add branch failed!')
  //         }
  //         this.dbregions = res;
  //         // this.loadingService.stopLoading();
  //       });
  //     } else {
  //       console.log("why");
  //     }

  //   //  }
  //     // this.regionSelected = {} as RegionModel;
  //     // this.areaSelected = {} as AreaModel;
  //     // this.isEditing = false;
  //     // this.isAreaEditing = false;
  //   });
  // }

  // selectArea(area?: AreaModel | null){
  //   if( !this.isAreaEditing){
  //     //Select an existing area or add a new area with new region
  //     let index = this.lstAreas.map(r => r.id).indexOf(area?.id || 0);
  //     this.areaSelected = (index >= 0 && area) ? area : {id: 0, name: '' };

  //     this.editAreaIndex = index || 0;
  //     this.isAreaEditing = true;

  //     this.crudDialog.open(AreaAdminDialogComponent, {
  //       disableClose: true,
  //       autoFocus: true,
  //       height: 'auto',
  //       minWidth: '600px',
  //       data: {
  //         area: this.areaSelected
  //       },
  //     }).afterClosed().subscribe((new_areaval) => {
  //       if(new_areaval){
  //       //SAve to DB
  //       }
  //       this.regionSelected = {} as RegionModel;
  //       this.areaSelected = {} as AreaModel;
  //      // this.isEditing = false;
  //       this.isAreaEditing = false;
  //     });
  //   }
  // }

 // update() {  //??
    //if (!this.isEditing) {
      // this.dbregions[0] = {
      //   id: 0,
      //   regionNumber: this.regionSelected.regionNumber!,
      //   name: this.regionAdminForm.value.name!,
      //   areas: this.regionAdminForm.value.areas!
      // };
   // }
  //  else {
   //   let index = this.dbregions.map(r => r.id).indexOf(this.regionSelected.id);

      // updates the region at the index selected
      // this.dbregions[index] = {
      //   id: this.regionSelected.id,
      //   regionNumber: this.regionSelected.regionNumber!,
      //   name: this.regionAdminForm.value.name!,
      //   areas: this.regionAdminForm.value.areas!
      // };

      //this.saveRegion();
   // }

    // clean up
    // this.regionSelected = {} as RegionModel;
    // this.areaSelected = {} as AreaModel;

    // this.isAreaEditing = false;
    // //this.isEditing = false;
    // (this.regionAdminForm.get('areas') as FormArray).reset();
    // this.regionAdminForm.reset();
 // }

  public setPage(page: number) {
    // get new pager object for specified page
    this.pager = paginate({totalItems: this.items.length,currentPage: page,pageSize: this.pageSize,maxPages: this.maxPages});

    // get new page of items from items array
    var pageOfItems = this.items.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
    // call change page function in parent component
    this.changePage.emit(pageOfItems);
  }

  // saveRegion() {
  //   if (this.regionAdminForm.valid && this.regionSelected && this.isEditing && !this.isAreaEditing) {
  //     this.loadingService.startLoading('Saving Region...');
  //     this.branchService.saveRegion(this.regionAdminForm.value).subscribe({
  //       next:(res) => {
  //         this.dbregions = res;
  //         this.items = this.dbregions;

  //         if (this.items && this.items.length) {
  //           this.setPage(this.initialPage);
  //         }
  //       },
  //       error:(err) => {
  //         this.toastrService.error(`Latest values were not saved due to errors: ${err}`,'Region Not Saved!');
  //         this.dbregions.splice(0, 1);
  //       },
  //       complete:()=> {
  //         this.loadingService.stopLoading();
  //       },
  //     });
  //   }
  // }

  deleteRegion(region: any) {
    const dlgRef = this.loadingService.startLoading('Deleting region...');
    this.branchService.deleteRegion(region).subscribe((res) => {
      this.items = res;
      this.dbregions = this.items;

      if (this.items && this.items.length) {
        this.setPage(this.initialPage);
      }
      this.loadingService.stopLoading(dlgRef);
    });
  }

  cancel() {
    // if (!this.isEditing || this.isAreaEditing && confirm('All unsaved changes will be removed. Are you sure you want to cancel?')) {
    //   // removes the regionthat was added
    //   this.dbregions.splice(0, 1);
    // }
    (<FormArray>this.regionAdminForm.get('areas')).clear();

    // clears the region selected
    this.regionSelected = {} as RegionModel;
    this.areaSelected = {} as AreaModel;

    //this.isEditing = false;
    this.isAreaEditing = false;
    (this.regionAdminForm?.get('areas') as FormArray).reset();
    this.regionAdminForm.reset();
  }

  omit_special_char(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  // editRegion(region: any) {
  //   this.openCRUDDialog(region).afterClosed().subscribe({
  //     next: (regionres) => {
  //       if (regionres) {
  //         this.loadingService.startLoading('Saving region...');
  //         this.branchService.saveArea(regionres).subscribe((res) => {
  //           this.loadingService.stopLoading();
  //         });
  //       }
  //     }
  //   });
  // }
  // openCRUDDialog(crudObj: any) {
  //   return this.crudDialog.open(RegionsAdminDialogComponent, {
  //     disableClose: true,
  //     autoFocus: true,
  //     height: 'auto',
  //     minWidth: '700px',
  //     data: {
  //       region: crudObj
  //     },
  //   });
  // }
  // editArea(areaval: any) {
  //   if (!this.isAreaEditing) {
  //     this.lstAreas[0] = {
  //       id: 0,
  //       name: this.regionAdminForm.value.name!
  //     };
  //   }
  //   else {
  //     let index = this.lstAreas.map(r => r.id).indexOf(this.areaSelected.id);

  //     if (areaval && this.editAreaIndex >= 0) {
  //       this.editAreaIndex = areaval;
  //       this.areaSelected = this.lstAreas[areaval];
  //       this.isAreaEditing = true;

  //       const areasForm = this.fb.group({
  //         'id': [this.areaSelected.id],
  //         'name': [this.areaSelected.name]
  //       });

  //       this.areas.push(areasForm);
  //     };
  //   }

  // }
}
