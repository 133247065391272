import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { QuoteStoreService } from '@lmsrestServices';
import { UserService } from 'src/app/core/services/lmsuser.service';
import { PreAuthorisationModel } from 'src/app/core/models/pre-authorisation.model';
import { LmsClientSearchComponent } from '../../shared/ui/lmsclient-search/lmsclient-search.component';
import { PageCardheadComponent } from '../../shared/ui/pageheader-card/pageheader-card.component';
import { map } from 'rxjs';
import { AppUtilityService } from 'src/app/app-utility.service';

@Component({
    selector: 'app-reset-loan',
    templateUrl: './reset-loan.component.html',
    standalone: true,
    imports: [PageCardheadComponent, LmsClientSearchComponent]
})
export class ResetLoanComponent implements OnInit {
  @Input() resetLoan: boolean | true;
  @Input() branchSerial!: string;

  preAuthorisationModel = {} as PreAuthorisationModel;
  resetLoanForm: FormGroup;

  constructor(
    private quoteStoreService: QuoteStoreService,
    private formBuilder: FormBuilder,
    private router: Router,
    public userService: UserService,
    private global_utility: AppUtilityService

  ) { }

  ngOnInit(): void {
    this.resetLoanForm = this.formBuilder.group({
      'idNumber': [this.preAuthorisationModel.idNumber],
      'referenceNumber': [this.preAuthorisationModel.referenceNumber]
    });
  }

  searchedClientView(idNumber: any) {
    this.preAuthorisationModel.idNumber = idNumber;
    this.global_utility.startLoading('Viewing client.');
    this.quoteStoreService.getClientQuoteDetails(this.preAuthorisationModel.idNumber).pipe(
      map((validQ: PreAuthorisationModel[]) => validQ.find(f => this.global_utility.ActiveQuoteStatus.includes(f.loanStatus)))
      ).subscribe({
        next: (result: any) => {
          this.quoteStoreService.setQuoteWorkbook(result);
          this.router.navigate(['/agent']);
        },
        error: (err) => {
        },
        complete: () => {
          this.global_utility.stopLoading();
        },
      });
  }
}
