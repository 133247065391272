// lms-selectOption.directive.ts

import { Directive, Input, Renderer2, ElementRef, HostListener, Optional, inject, forwardRef, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { NgSelectOption, SelectControlValueAccessor } from '@angular/forms';
import { LMSConstantsService } from '@lmsConstants';


@Directive({
    selector: '[lmsSelectOption]',
    standalone: true
})
export class LMSSelectOptionDirective extends SelectControlValueAccessor implements AfterViewInit,OnChanges  {
  @Input() constantName:string; // searchText
  @Input() addDefault = false;
  @Input() setFisrtasValue = false;
  //@Input() content: string; // HTML content
  //@Input() classToApply: string; //class to apply for highlighting
  //@Input() setTitle = false; //sets title attribute of HTML
  private _select: SelectControlValueAccessor;
  LMSConstantsService = inject(LMSConstantsService);

  constructor(private lmsConstants: LMSConstantsService, private element: ElementRef<HTMLSelectElement>, private renderer: Renderer2,
    @Optional() select: SelectControlValueAccessor) {
      super(renderer, element);

    this._select = select;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.constantName && changes.constantName.firstChange) {
      return;
    }

    while (this.element.nativeElement.options.length != 0) {
      this.element.nativeElement.options.remove(0);
    }

    this.loadLMSConstant_Option();
  }

  ngAfterViewInit(): void {
    this.loadLMSConstant_Option();
  }

  loadLMSConstant_Option(){
    if(!this.constantName) {
      return;
    }

    if(this.addDefault){
      const option = this.renderer.createElement('option');
      const text   = this.renderer.createText('--  Select  --');
      //this.renderer.setValue(option, '');
      this.renderer.appendChild(option, text);
      this.renderer.appendChild(this.element.nativeElement, option);

      const ngOption = new NgSelectOption(new ElementRef(option), this.renderer, this._select);
      ngOption.ngValue = null;
      ngOption.value = null;
      if(this.setFisrtasValue) {
        this._select.writeValue(ngOption);
      }
    }

    this.lmsConstants.LMSConstants[this.constantName].forEach(
    (co, idx) => {
      const option = this.renderer.createElement('option');
      const text   = this.renderer.createText(co.description);
      //this.renderer.setValue(option, `${co.value}`)

      this.renderer.appendChild(option, text);
      this.renderer.appendChild(this.element.nativeElement, option);

      const ngOption = new NgSelectOption(new ElementRef(option), this.renderer, this._select);
      ngOption.value = co.value;
      ngOption.ngValue = co.value;
      if(!this.addDefault && this.setFisrtasValue && idx===1) {
        this._select.writeValue(ngOption);
      }
      //this.element.nativeElement.options.
      //new NgSelectOption(new ElementRef(option), this.renderer, this._select);
    })
  }

  @HostListener('input', ['$event']) input(event: InputEvent) {
    // const target = event.target as HTMLInputElement;
    // const e = <InputEvent> event;
  }

  getFormattedText() {
    // const re = new RegExp(`(${this.searchedWord})`, 'gi');
    // return this.content.replace(re, `<span class="${this.classToApply}">$1</span>`);
  }
}
