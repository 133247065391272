import { CommonModule } from '@angular/common';
import { Component, EventEmitter,  Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { map, Subject } from 'rxjs';
import { PreAuthorisationModel } from 'src/app/core/models/pre-authorisation.model';

import { MdbValidationModule } from 'mdb-angular-ui-kit/validation';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { QuoteStoreService } from '@lmsrestServices';
import { AppUtilityService } from 'src/app/app-utility.service';
import { NumbersOnlyDirective } from '../../directives';

@Component({
  selector: 'app-lmsclient-search',
  templateUrl: './lmsclient-search.component.html',
  styleUrls: ['./lmsclient-search.component.css'],
  imports:[
    CommonModule,
    ReactiveFormsModule,
    MdbFormsModule,
    MdbRippleModule,
    MdbValidationModule,
    NumbersOnlyDirective
  ],
  standalone:true
})
export class LmsClientSearchComponent implements OnInit {
  @ViewChild('clientSearchForm',{ static: true }) clientSearchForm: FormGroup;

  @Input() preAuthorisationModel = {} as PreAuthorisationModel;
  @Input() OverrideDefaultSearch = false;

  @Input() resetLoan: string | undefined;
  @Input() branchSerial!: string;

  @Output() onGetClient = new EventEmitter();
  @Output() onViewSearchedClient = new EventEmitter<any>();

  mandaterefform = new FormGroup({
    referenceNumber: new FormControl('', {})
  });

  //preAuthorisationModel = {} as PreAuthorisationModel;
  //subscriptions: Subscription[];

  manageEngineReference = '';

  isSearchClient = false;
  hasSearchHistory = false;
  isSubmitted = false;

  tableHistory_config = {
    columns: [
      {
        key: 'quoteId',
        heading: 'Application #'
      },
      {
        key: 'idNumber',
        heading: 'Id Number'
      },
      {
        key: 'firstName',
        heading: 'Name'
      },
      {
        key: 'lastName',
        heading: 'Surname'
      },
      {
        key: 'cellNumber',
        heading: 'Cell Number'
      },
      {
        key: 'status',
        heading: 'Status',
        status: true
      },
      {
        key: 'userName',
        heading: 'User'
      },
      {
        key: 'manageEngineReference',
        heading: 'Manage Engine Reference No.'
      }
    ],
    primary_key_set: ['quoteId'],
    table_data_changer: new Subject<any>(),
    ediTable: {
      add: false,
      edit: true
    }
  };

  @Output() onAccountHistSelect = new EventEmitter<any>();

  constructor(
    public quoteStoreService: QuoteStoreService,
    public global_utility: AppUtilityService
  ) {

    // const subscription = this.quoteStoreService.getBranchValidQuote(this.clientSearchForm.value.idNumber)
    //   .subscribe({
    //     next: (currentValue) => {
    //     },
    //     error: (returnedError) => {
    //     }
    //   });
    // this.preAuthModel$ = this.quoteStoreService.getBranchValidQuote();
    // this.subscriptions.push(subscription);
  }

  ngOnInit() {
    this.clientSearchForm = new FormGroup({
      idNumber: new FormControl('', [Validators.required, Validators.minLength(13), Validators.maxLength(13)])
    });
  }

  get idNumber() {
    return this.clientSearchForm!.get('idNumber');
  }

  onAnimationEvent($event: any) {
    if (!this.isSearchClient) {
      return;
    }
    // in our example, toState either open or closed
    console.warn(`Animation Trigger: ${$event.triggerName}`);
  }

  //subscriptions: Subscription[] = [];
  getClient() {
    // if (this.OverrideDefaultSearch) {
    //   this.onGetClient.emit();
    //   return;
    // }

    this.isSearchClient = false;
    this.isSubmitted = true;
    this.hasSearchHistory = false;

    if(this.idNumber?.invalid){
      return;
    }

    const idNum = this.idNumber?.value || '';

    if (idNum.length < 13) {
      console.log("Invalid Id Number provided!");
      this.idNumber?.setErrors({ maxlength: true });
      return;
    }

    if (!this.branchSerial || !this.branchSerial.length) {
      this.idNumber?.setErrors({ wrongBranch: true });
      return;
    }

    this.global_utility.startLoading('Searching client details.');

    const preAuthModel = {} as PreAuthorisationModel;
    preAuthModel.idNumber = idNum;

    this.quoteStoreService.getBranchClientQuoteDetails({ idNumber: preAuthModel.idNumber, branchSerial: this.branchSerial }).pipe(
      map((validQ: PreAuthorisationModel[]) => validQ.find(f => this.global_utility.ActiveQuoteStatus.includes(f.loanStatus)))
    ).subscribe({
      next: (validQ: PreAuthorisationModel | undefined) => {
        if (!validQ) {
          this.idNumber?.setErrors({ notfound: true });
          return;
        }

        this.preAuthorisationModel = validQ;
        this.isSearchClient = true;
        this.quoteStoreService.setQuoteWorkbook(this.preAuthorisationModel);
      },
      error: (error) => {
        console.error('Branch Client search >>>',error);
        this.idNumber?.setErrors({ notfound: true });
      },
      complete:()=> {
        this.isSubmitted = false;
        this.global_utility.stopLoading();
      },
    });
  }


  viewSearchedClient(viewQuote: PreAuthorisationModel) {
    console.log("PreAuthModel [Searched]: >> ", viewQuote);
    if (this.onViewSearchedClient || this.OverrideDefaultSearch) {
      this.onViewSearchedClient.emit(viewQuote);
      return;
    }

    this.preAuthorisationModel = viewQuote;
  }

  resetLoanApplication(preAuthData: PreAuthorisationModel) {
    console.log("preauth data --------", preAuthData);
    console.log("reference number:--------", this.mandaterefform.value.referenceNumber);

    this.global_utility.startLoading('Viewing client.');
    this.quoteStoreService.resetLoanApplication({ quoteId: preAuthData.id, userName: this.global_utility.getGlobalData('user_LoginName'), referenceNumber: this.mandaterefform.value.referenceNumber }).subscribe({
      next: (result: any) => {       
          if (this.onViewSearchedClient || this.OverrideDefaultSearch) {
            this.onViewSearchedClient.emit(result);
            return;
          }
          // this.quoteStoreService.setQuoteWorkbook(result);
          // this.global_utility.stopLoading();
          // this.global_utility.navigateToURL(['/agent']);        
      },
      error: () => {
        this.global_utility.stopLoading();
      }
    });
  }

}
