export class AddressModel {
  postalAdressLine1: string;
  postalAdressLine2: string;
  postalAdressSuburb: string;
  postalAdressTown: string;
  postalAdressAreaCode: string;
  postalAdressProvince: string;
  homeAdressLine1: string;
  homeAdressLine2: string;
  homeAdressSuburb: string;
  homeAdressAreaCode: string;
  homeAdressTown: string;
  homeAdressProvince: string;
  workAdressLine1: string;
  workAdressLine2: string;
  workAdressSuburb: string;
  workAdressAreaCode: string;
  workAdressTown: string;
  workAdressProvince: string;
  sameAsAdress: boolean;
  addressType: string;
  quoteId: number;
  //poBoxNumber: string;
  userName: any;
  isConfirmedAddressDetails: boolean = false;
}
