<div class="modal-content document-container">
  <div class="modal-header">
    <button class="btn btn-close " color="red"  (click)="dialogRef.close()" ></button>
    <div class="modal-title center-fields">
      <app-page-cardhead style="margin-top: -2.1rem;" [PageName]="'Client Documents'" [PageHeadWidth]="600"></app-page-cardhead>
    </div>
  </div>

  <div class="modal-body" >
    <div class="card">
      <div class="card-body p-1">
        <div class="form-container center-screen container-fluid bd-example-row">

        <app-client-header #clientHeaderComponent align="end" [clientDetails]="clientShow" [showQuote]="!isReadOnly" style="width:800px"></app-client-header>
        <div *ngIf="isDocumentLoading" class="fade show my-1" style="text-align: center; height: 30px">
          <i class="spinner-grow spinner-grow-sm"></i>
          <span class="me-2"> Loading documents, please wait! </span>
        </div>
        <ng-container *ngIf="!isDocumentLoading">
          <table  class="table p-1 table-striped table-auto table-hover table-sm caption-top shadow-1-strong rounded-1" style="width: 950px">
            <caption>Document List</caption>
            <thead>
              <tr>
                <th scope="col">Document</th>
                <th>File name</th>
                <th scope="col">Upload date</th>
                <th scope="col">Uploaded by</th>
                <th scope="col" style="width: 40px;">View</th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let doc of lstDocuments">
                <td>{{doc.documentType}}</td>
                <td style="text-overflow: ellipsis;">{{doc.originalFileName}}</td>
                <td>{{covertDate(doc.uploadDate)}}</td>
                <td>{{doc.userName}}</td>
                <td >
                  <button
                    mat-icon-button
                    class="btn btn-primary"
                    mdbRipple
                    color="primary"
                    (click)="onDownloadPdf(doc.fileName)">
                    <i class="fas fa-sm fa-fw fa-eye"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
