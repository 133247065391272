
export class EmploymentDetailsModel {
  clientNumber: number;
  employerName: string;
  name: string;
  subGroupCode: string;
  subGroupName: string;
  lineManagerFirstName: string;
  lineManagerLastName: string;
  lineManagerEmail: string;
  lineManagerContactNumber: string;
  department: string;
  employmentType: string;
  occupation: string;
  placement: string;
  employmentStartDate: string;
  contractStartDate: string;
  contractEndDate: string;
  wageType: string;
  wageCalendar: string;
  employeeNumber: string;
  //paymentDate: string;
  paymentMethod: string;
  quoteId: number;
  address: number;
  workAdressLine1: string;
  workAdressLine2: string;
  workAdressSuburb: string;
  workAdressAreaCode: string;
  workAdressProvince: string;
  workAdressTown: string;
  hasEmployment: boolean;
  userName: any;
  employmentOtp: string;
  id: number;
  idNumber: string;
  // address: AddressModel = new AddressModel;
  serial: string;
  employerTNR: number;
  parentCompany: string;
  payDate: string;
  shiftingRule: string;
  branch: string;
  branchName: string;
  payFrequency: string;
  company: string;

  confirmed: boolean;
  confirmedByUser: string;

  isConfirmedEmploymentDetails: boolean = false;
}
