import { Component, EventEmitter, Inject, Input, isDevMode, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { PAYDATES, PAYDAYOFWEEK, PAYMETHODS, SHIFTINGRULES, WAGEFREQUENCY } from '@lmsConstants';
import { Subscription } from 'rxjs';
import paginate from '@lmsPaginate';

import { EmploymentDetailsModel } from "src/app/core/models/employment-details-model";
import { BranchEmployerService } from "src/app/core/services/branch-employer.service";
import { ErrorhanderService } from "src/app/core/services/error-handler.service";
import { BranchEmployer } from "src/app/features/branch-employers/models/branch-employers";
import { EmployerDetailsModel } from "src/app/features/branch-employers/models/employer-details-model";
import { UpdateEmployerService } from "src/app/features/branch-employers/update-employer.service";
import { BranchService } from '@lmsrestServices';

import { BranchModel } from 'src/app/core/models/branch.model';
import { AppUtilityService } from "src/app/app-utility.service";
import { NgxPaginationModule } from 'ngx-pagination';
import { UpperCaseInputDirective } from '../../../shared/directives/uppercaseonly.directive';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor, NgClass, UpperCasePipe } from '@angular/common';
import { LMSSelectOptionDirective } from 'src/app/shared/directives/lms-selectOption.directive';
import { NoSpecialCharPipe } from '@lmsSharedUI';

const HQSTAFF = 'HQ Staff';

@Component({
    selector: "app-update-employer",
    templateUrl: "./update-employer.component.html",
    styleUrls: ["./update-employer.component.css"],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, MatButtonModule, NoSpecialCharPipe,UpperCasePipe,
      LMSSelectOptionDirective,
      NgFor, NgClass, UpperCaseInputDirective, NgxPaginationModule]
})
export class UpdateEmployerComponent implements OnInit {

  employerDetailsModel = new EmployerDetailsModel();
  employerDetailsForm: FormGroup;

  employerSearchForm = this.fb.group({
    employerName: ['', Validators.required],
    searchBranch: ['', Validators.required],
    brancName: [[]]
  });

  submitted = false;
  lstEmployers: Array<any> = [];
  userProfile: any;
  canEdit = false;
  employerName = "";
  nameEntered = false;
  dataLoading = false;
  // public lstShiftingRule = SHIFTINGRULES;
  // public lstPayMethod = PAYMETHODS;
  // public lstWageFrequency = WAGEFREQUENCY;
  // public payDates = PAYDATES;
  errorMessages: string[] = [];
  currentStepIndex = 0;
  basicInfoFormSubscription: Subscription;
  formSubmitted = false;
  allFormsValid = false;
  pageTitle = "Add Branch Employer";
  employers: BranchEmployer[];
  lstBranches: BranchModel[];
  userBranch = "";
  searchInput: any;
  selectedBranch: any;

  @Output() changePage = new EventEmitter<any>(true);
  @Input() initialPage = 1;
  @Input() pageSize = 8;
  @Input() maxPages = 3;
  pager: any = {};

  employerNametxt = "";
  HQ_STAFF = HQSTAFF;
  constructor(
    public updateEmployerService: UpdateEmployerService,
    private fb: FormBuilder,
    private branchEmployerService: BranchEmployerService,
    private errorhandlerService: ErrorhanderService,
    private branchService: BranchService,
    public global_utility: AppUtilityService,
    @Optional() public dialogRef?: MatDialogRef<UpdateEmployerComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data?: any
  ) {
    if (data) {
      this.employerDetailsModel = data?.employer;
    }

    if (this.global_utility.getGlobalData('branch_Name') == HQSTAFF) {
      this.branchService.getAllFinbondBranchNames().subscribe(branches => {
        this.lstBranches = branches;

        const homeBranch = this.getBranchByName(this.global_utility.getGlobalData('branch_serial'));// .branches.pipe(map(br => br.filter(fb => fb.branchSerial == )[0] || null));
        this.selectedBranch = homeBranch;
        this.employerSearchForm.controls.searchBranch?.patchValue(homeBranch!.branchName);

      });
    }
  }

  get branchName() {
    return this.employerSearchForm.get('brancName');
  }

  get selbranch() {
    return this.employerSearchForm.value.brancName;
  }

  setSelectedBranch(newValue: any): void {
    console.log('selected BRanch:', newValue);
  }

  getBranchByName(name: string) {
    const fl = this.lstBranches.
      filter(projects =>
        projects.branchSerial === name
      );

    return (fl.length > 0) ? fl[0] : null;
  }

  ngOnInit(): void {
    //for HQ staff pull
    this.userBranch = this.global_utility.getGlobalData('branch_Name');

    if (!this.employerDetailsForm) {
      this.employerDetailsForm = this.fb.group({
        employerName: [this.employerDetailsModel.employerName],
        parentCompany: [this.employerDetailsModel.parentCompany],
        workEmail: [this.employerDetailsModel.workEmail],
        tel1: [this.employerDetailsModel.tel1, [Validators.required]],
        tel2: [this.employerDetailsModel.tel2],
        payDate: [this.employerDetailsModel.payDate, [Validators.required]],
        shiftingRule: [
          this.employerDetailsModel.shiftingRule,
          [Validators.required]
        ],
        paymentMethod: [
          this.employerDetailsModel.paymentMethod,
          [Validators.required]
        ],
        wageFrequency: [
          this.employerDetailsModel.wageFrequency,
          [Validators.required]
        ],

        workAddressLine1: [
          this.employerDetailsModel.workAddressLine1,
          [Validators.required]
        ],
        workAddressLine2: [this.employerDetailsModel.workAddressLine2],
        workAddressSuburb: [
          this.employerDetailsModel.workAddressSuburb,
          [Validators.required]
        ],
        workAddressTown: [
          this.employerDetailsModel.workAddressTown,
          [Validators.required]
        ],
        workAddressAreaCode: [
          this.employerDetailsModel.workAddressAreaCode,
          [Validators.required]
        ],

        postalAddressLine1: [this.employerDetailsModel.postalAddressLine1],
        postalAddressLine2: [this.employerDetailsModel.postalAddressLine2],
        postalAddressTown: [this.employerDetailsModel.postalAddressTown],
        postalAddressAreaCode: [this.employerDetailsModel.postalAddressAreaCode]
      });
    }

    if (this.userBranch != HQSTAFF) {
      this.employerSearchForm.controls.searchBranch.patchValue(this.userBranch);
      this.findBranchesByName();
      //let homeBranch = this.getBranchByName(this.global_utility.getGlobalData('branch_serial'));// .branches.pipe(map(br => br.filter(fb => fb.branchSerial == )[0] || null));
      //this.selectedBranch = homeBranch;
      //this.branchName?.setValue(this.userBranch);
    }

    if (this.branchEmployerService.preppedforCreate) {
      this.newEmployer();
    }
  }


  findEmployer(forceEdit = false) {
    if (this.employerSearchForm.valid) {
      this.global_utility.startLoading("Refreshing employers..");

      this.canEdit = false;
      this.submitted = false;
      this.nameEntered = true;

      this.employerDetailsModel = new EmployerDetailsModel();
      this.employerDetailsModel.employerName = this.employerSearchForm.value.employerName || '';
      this.employerDetailsModel.branchSerial = (this.selbranch! as BranchModel).branchSerial || this.selectedBranch.branchSerial;
      this.employerDetailsModel.branchName = (this.selbranch! as BranchModel).branchName || this.selectedBranch.branchName;

      // if (!this.selectedBranch && this.selectedBranch == '') {
      //   this.employerDetailsModel.branchSerial = this.global_utility.getGlobalData('branch_serial');
      //   this.employerDetailsModel.branchName = this.global_utility.getGlobalData('branch_Name');
      // } else {
      //   this.employerDetailsModel.branchSerial = this.branchModel.branchSerial;
      //   this.employerDetailsModel.branchName = this.branchModel.branchName;
      // }

      this.updateEmployerService.findEmployersByName(this.employerDetailsModel)
        .subscribe({
          next: (result: any) => {
            // this.handleEmployers(result);

            if (result && Array.isArray(result)) {
              this.lstEmployers = result;

              if (this.lstEmployers.length === 0) {
                this.canEdit = true;
              }
            }

            if (this.lstEmployers && this.lstEmployers.length > 0) {
              this.setPage(this.initialPage);
            }

            this.global_utility.stopLoading();
          },
          error: (err) => {
            this.canEdit = false;
            this.submitted = false;
            this.nameEntered = true;

            this.global_utility.warning(
              "Error finding employer: " +
              `${err.error.error || err.message || ""}`,
              "Employer search fail"
            );
            this.global_utility.stopLoading();
          }
        });
    }
  }

  onWageFrequencyChange(event: any) {
    const numb: any = 7;
    // if (event.target.value === "Monthly") {
    //   this.payDates = PAYDATES;
    //   return;
    // }
    //this.payDates = PAYDAYOFWEEK;
    if (this.employerDetailsModel.payDate > numb) {
      this.employerDetailsModel.payDate = 1;
    }
  }

  editEmployer(employer: any) {
    this.submitted = false;
    this.lstEmployers = [];

    this.employerDetailsModel.employerName = employer.name || employer.employerName;
    this.employerNametxt = this.employerDetailsModel.employerName;
    this.employerDetailsModel.id = employer.id;
    this.employerDetailsModel.parentCompany = employer.parentCompany;
    this.employerDetailsModel.workEmail = employer.workEmail || employer.email;
    this.employerDetailsModel.email = employer.email;
    this.employerDetailsModel.tel1 = employer.tel1;
    this.employerDetailsModel.tel2 = employer.tel2;
    this.employerDetailsModel.payDate = employer.payDate;
    this.employerDetailsModel.shiftingRule = employer.luPaydateShiftDesc;
    this.employerDetailsModel.paymentMethod = employer.luSalaryMethodDesc;
    this.employerDetailsModel.wageFrequency = employer.luEmpPayFrequency;

    // this.employerDetailsModel.addressId = employer.addressId;
    this.employerDetailsModel.workAddressLine1 = employer.physicalAddress1;
    this.employerDetailsModel.workAddressLine2 = employer.physicalAddress2;
    this.employerDetailsModel.workAddressSuburb = employer.physicalSuburb;
    this.employerDetailsModel.workAddressTown = employer.physicalTown;
    this.employerDetailsModel.workAddressAreaCode = employer.physicalCode;
    // this.employerDetailsModel.workAddressProvince = employer.ph;

    this.employerDetailsModel.postalAddressLine1 = employer.postalAddress1;
    this.employerDetailsModel.postalAddressLine2 = employer.postalAddress2;
    this.employerDetailsModel.postalAddressTown = employer.postalTown;
    this.employerDetailsModel.postalAddressAreaCode = employer.postalCode;

    this.employerDetailsModel.employerTNR = employer.tnumber || employer.employerTNR;
    this.employerDetailsModel.branchSerial = employer.serial || employer.branchSerial;

    this.employerDetailsModel.branchName = this.userBranch == HQSTAFF
      ? this.selectedBranch.branchName
      : employer.branchName || this.userBranch;

    this.canEdit = true;
  }

  deleteEmployer(employerId: any) {
    // console.log("employer", employer);
    // this.employerDetailsModel.id = employer.id;
    this.updateEmployerService
      .deleteEmployer(employerId)
      .subscribe((result: any) => {
        console.log("result", result);
        this.canEdit = false;
        this.global_utility.success("", result.message);
      });
  }

  saveEmployer() {
    this.submitted = true;

    if (!this.employerDetailsForm.invalid) {
      this.global_utility.startLoading("Saving employer.");
      if (!this.employerDetailsModel?.employerTNR) {
        this.employerDetailsModel.employerTNR = 0;
      }

      this.employerDetailsModel.userName = this.global_utility.getGlobalData('userDisplay'); // if brancj model rue
      this.employerDetailsModel.employerBranch = this.employerDetailsModel.branchName;

      this.updateEmployerService.saveEmployer(this.employerDetailsModel).subscribe({
          next: (result) => {
            this.global_utility.stopLoading();
            if (result) {
              if (result.errorMessage) {
                this.errorhandlerService.handleString(result.errorMessage);
                this.canEdit = true;
                return;
              }

              this.employerName = result.employerName;
              // this.findEmployer();

              this.canEdit = false;
              this.global_utility.success(
                `Employer ${result.name} saved with TNR = ${result.tnumber}!`,
                "Employer saved!");

              if (this.userBranch != HQSTAFF) {
                this.employerSearchForm.controls.employerName.reset();
                this.employerSearchForm.updateValueAndValidity();
              }

              if (
                this.branchEmployerService?.preppedforCreate &&
                this.dialogRef
              ) {
                this.branchEmployerService.preppedforCreate = false;
                this.dialogRef!.close(this.mapEmployerToEmployment(result));
                // return this.dialogRef.close(this.employmentDetailsForm.value);
              }
            }
          },
          error: (err) => {
            if (
              (err.error &&
                err.error.type === "error" &&
                err.statusText === "Unknown Error") ||
              err.status === 504
            ) {
              // Offline
              this.errorhandlerService.handleOffline(
                "Server Communication to Delfin failed.",
                "Server OFFLINE"
              );
            } else {
              this.global_utility.stopLoading();
              this.editEmployer(err.error);
            }
          }
        });
    }
  }

  cancelEdit() {
    this.canEdit = false;
    if (this.userBranch != HQSTAFF) {
      this.employerSearchForm.controls.employerName.reset();
      this.employerSearchForm.updateValueAndValidity();
    }

    if (this.branchEmployerService.preppedforCreate) {
      this.dialogRef!.close();
    }
  }

  goforNew() {
    this.global_utility.warning(
      "Something amazing is about to happen",
      "New employer required"
    );
  }

  // saveEmployerDetail(employerDateil: BranchEmployerDetails) {
  //   this.submitted = true;

  //   if (this.employerDetailsForm.valid) {
  //     const quotedlg = this.loaderService.startLoading('Updating employer.');
  //     if (!this.employerDetailsModel?.employerTNR) {
  //       this.employerDetailsModel.employerTNR = 0;
  //     }

  //     this.employerDetailsModel.userName = this.userService.userLoginName;
  //     this.employerDetailsModel.branchSerial = this.userService.userBranchSerial;

  //     this.updateEmployerService.saveEmployer(this.employerDetailsModel).subscribe((result: any) => {
  //       if (result) {
  //         //console.log('result', result);
  //         this.employerName = result.employerName || '';
  //         //this.findEmployer();
  //       }
  //       this.loaderService.stopLoading(quotedlg);
  //       this.canEdit = true;

  //     });
  //   }
  // }

  mapEmployerToEmployment(employer: any): EmploymentDetailsModel {
    const employer2employment = new EmploymentDetailsModel();

    employer2employment.serial = employer.branchSerial || employer.serial;
    employer2employment.employerName = employer.name || employer.employerName;
    employer2employment.parentCompany = employer.parentCompany;
    employer2employment.payDate = employer.payDate;
    employer2employment.shiftingRule = employer.luPaydateShiftDesc;
    employer2employment.branch = employer.employerBranch;
    employer2employment.branchName = employer.branchName;
    employer2employment.company = employer.company;
    employer2employment.wageCalendar = employer.luEmpPayFrequency;
    employer2employment.paymentMethod = employer.luSalaryMethodDesc;
    employer2employment.workAdressLine1 =
      employer.physicalAddressLine1 || employer.physicalAddress1;
    employer2employment.workAdressLine2 =
      employer.physicalAddressLine2 || employer.physicalAddress2;
    employer2employment.workAdressSuburb =
      employer.physicalAddressSuburb || employer.physicalSuburb;
    employer2employment.workAdressTown = employer.physicalTown;
    employer2employment.workAdressAreaCode = employer.physicalCode;
    employer2employment.employerTNR = employer.tnumber;
    employer2employment.lineManagerEmail = employer.email;
    employer2employment.lineManagerContactNumber = employer.tel1;

    return employer2employment;
  }

  newEmployer() {
    this.lstEmployers = [];
    // if (this.branchEmployerService.preppedforCreate) {
    this.editEmployer(this.employerDetailsModel);
    //}

    // this.employerDetailsModel ||= new EmployerDetailsModel();
    this.canEdit = true;
  }

  findBranchesByName() {
    //if (this.userService.userBranchName === HQSTAFF) {
    console.log("br =", this.employerSearchForm.value.searchBranch);
    // if (this.employerSearchForm.value.searchBranch == '') {
    //   this.lstBranches;
    // }
    // let filteredBranches = this.lstBranches.filter(br => br.branchName.startsWith(this.employerSearchForm.value.searchBranch!));
    this.branchService.getBranchByName(this.employerSearchForm.value.searchBranch)
      .subscribe((result) => {
        //this.loadingService.stopLoading();
        if (!result || Object.keys(result).length == 0) {
          this.global_utility.warning('No branches found with name: ' + this.employerSearchForm.value.searchBranch, 'Empty Search result');
        } else {
          this.lstBranches = result;
          this.selectedBranch = this.lstBranches[0];
        }
      });
    //}
  }

  get validateEmployerInput() {
    return this.employerDetailsForm.controls;
  }

  get validateSearchInput() {
    return this.employerSearchForm.controls;
  }



  ngOnChanges(changes: SimpleChanges) {
    // reset page if items array has changed
    if (changes['lstEmployers'].currentValue !== changes['lstEmployers'].previousValue) {
      this.setPage(this.initialPage);
    }
  }

  public setPage(page: number) {
    // get new pager object for specified page
    this.pager = paginate({
      totalItems: this.lstEmployers.length, currentPage: page,
      pageSize: this.pageSize, maxPages: this.maxPages
    });

    // get new page of items from items array
    const pageOfItems = this.lstEmployers.slice(this.pager.startIndex, this.pager.endIndex + 1);

    // call change page function in parent component
    this.changePage.emit(pageOfItems);
  }
}
