export class NextOfKinModel {
    name1:string;
    name2:string;
    surname1: string; 
    surname2: string; 
    addressLine1: string;
    addressLine2: string;
    line1: string;
    line2: string;
    suburb1: string;
    suburb2: string;
    province1: string;
    province2: string;
    areaCode1: string;
    areaCode2: string;
    email1: string;
    email2: string;
    quoteId: number;
    onlyOneNextOfKin: boolean;
    contactNo1:string;
    contactNo2: string;
    relationship1: string;
    relationship2: string;
    nextOfKinType: string;
    address1id: number;
    address2id: number;
    userName: any;
    town1: string; 
    town2: string;
    isConfirmedNokDetails: boolean = false;
}
