import { Component,  OnInit } from '@angular/core';

import { UserService } from 'src/app/core/services/lmsuser.service';
import { NgIf } from '@angular/common';
import { AppUtilityService } from '../app-utility.service';
import { Observable, tap } from 'rxjs';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class HomeLandingComponent implements OnInit {
  
  public isLoggedIn$ : Observable<boolean>;

  constructor(
    private userService: UserService,
    public global_utility: AppUtilityService
  ) {
    this.isLoggedIn$ = this.userService.ValidUserLoaded$;

    this.userService.LMSUserEvent$.subscribe({
      next:(kevent: any) => { 
        console.log("Home(LMSUser Event): ", kevent.type);       
        if(kevent.type == 5) {
          this.isLoggedIn$.pipe(tap((valid) => {
            if(valid){
              this.global_utility.stopLoading();
              this.global_utility.navigateToLanding();
            }
          })
          );          
        }        
      }
    });

    this.userService.onLogginChanged.subscribe((loginChanged) => {
      if(loginChanged) {
        console.log("Home(OnLoggingChanged): ", loginChanged);
        setTimeout(() => {   
          this.global_utility.stopLoading();
          this.global_utility.navigateToLanding();  
        },600);
      }
    });
  }

  ngOnInit(): void {
    this.global_utility.startLoading('CLMS loading...');
      //this.userService.getLMSProfileObs().subscribe((lmsuser: any) => {
        //if (lmsuser && lmsuser.id!) {
          //this.router.navigate(['/lmsuser']);

          //redirect could happen here, but for now just land on beautifull home page.

          //const component = lmsuser!.jobTitle?.component || '/';
          //this.router.navigate([component]);
       // }
    // });
    //this.onUserProfileLoaded();
    //this.userService.onLMSUserProfile();
  }

}
