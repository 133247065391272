import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SpinnerModel } from 'src/app/shared/loaders/spinner/spinner.model';
import { UserService } from 'src/app/core/services/lmsuser.service';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { PageCardheadComponent } from '../../ui/pageheader-card/pageheader-card.component';

@Component({
    selector: 'app-lms-dialog',
    templateUrl: './lms-dialog.component.html',
    styleUrls: ['./lms-dialog.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
    standalone: true,
    imports: [PageCardheadComponent, MatDialogModule, MdbRippleModule]
})
export class LMSDialogComponent implements OnInit {

  form: FormGroup;
  title!: string;
  dlgData: any;
  message!: string;
  description: string;

  constructor(private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: SpinnerModel, private dialogRef: MatDialogRef<LMSDialogComponent>) {
    this.dlgData = data;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      description: [this.description, []],
    });
  }

  saveLmsDialog() {
    this.dialogRef.close(this.form.value);
  }

  closeLmsDialog(val: any) {
    this.dialogRef.close(val);
  }

  close() {
    this.dialogRef.close(false);
  }



  // openDialog() {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = true;

  //   dialogConfig.data = {
  //     id: 1,
  //     title: 'Angular For Beginners'
  //   };

  //   this.dialog.open(LMSDialogComponent, dialogConfig);
  // }
}

