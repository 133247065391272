import { BranchEmployer } from "src/app/features/branch-employers/models/branch-employers";

export class EmployerDetailsModel {
  id: number | 0;
  employerName: string | "";
  employerBranch?: string;

  tel1: string;
  tel2?: string;
  email: string;
  workEmail?: string;
  // quoteId: number;
  physicalAddressLine1: string | "";
  physicalAddressLine2?: string;
  physicalAddressSuburb?: string;
  physicalAddressAreaCode: string;
  physicalAddressTown: string;

  workAddressLine1: string;
  workAddressLine2?: string;
  workAddressTown: string;
  workAddressSuburb?: string;
  workAddressAreaCode: string;

  postalAddressLine1?: string;
  postalAddressLine2?: string;
  postalAddressTown?: string;
  postalAddressAreaCode?: string;

  employerTNR: number | 0;
  parentCompany?: string;
  payDate: number | string; // Weekday
  shiftingRule: string;
  status: string;
  // [W - WEEKLY, F - FORTNIGHTLY, M- MONTHLY, D - WEEKDAY, F - Last Friday of Month, E - Last day of Month, S - Second last Weekday]
  wageFrequency: string;
  paymentMethod?: string;
  name: string;
  luPaydateShiftDesc?: string;
  luSalaryMethodDesc?: string;
  luEmpPayFrequency?: string;
  userName?: string;
  branchName?: string;
  branchSerial: string;
  // company: string;
  // userUUID?: string;
}
