import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-remove-loan-type',
    templateUrl: './remove-loan-type.component.html',
    styleUrls: ['./remove-loan-type.component.css'],
    standalone: true,
    imports: [FormsModule, MatButtonModule]
})
export class RemoveLoanTypeDialogComponent implements OnInit {
  pageName = "Delete Product";
  loanTypeData: any;
  submitted = false;

  constructor(
    public dialogRef: MdbModalRef<RemoveLoanTypeDialogComponent>) { }

  ngOnInit(): void {
    // this.keycloak.loadUserProfile().then(user => {
    //   this.userProfile = user;
    // });
  }

  removeLoanTypeConfirmation() {
    this.submitted = true;
    let isValidData = true;

    if (this.loanTypeData.id == null) {
      isValidData = false;
      console.log("Form fields are null or empty...");
    }

    if (isValidData) {
      return this.dialogRef.close(this.loanTypeData);
    }
  }

  close() {
    this.dialogRef.close();
  }

}
