export class ClientShowModel {
  id?: number;
  loanPurpose?: string;
  createdBy?: string;
  firstName?: string;
  fullNames: string;
  surname: string;
  lastName?: string;
  idNumber: string;
  clientId?: number;
  cellNumber: string;
  clientType: string;
  clientNumber: string;
  loanStatus?: string;
  clientStatus?: string;
  branchName?: string;
}
