import { HttpRequest } from '@angular/common/http';
import { HostListener, Injectable, isDevMode } from '@angular/core';
import { BehaviorSubject, fromEvent, map, merge, Observable, Observer, Subject } from 'rxjs';

@Injectable({
  providedIn:'root'
})
export class ApiloaderService {
  private requestsDur: number[] = [];
  private requests2: HttpRequest<any>[] = [];
  public isLoading = new Subject<boolean>();

  public isOffline = new BehaviorSubject(false);
  public isAPIOffline = new BehaviorSubject(false);

  constructor() {
    this.createOnline$().subscribe((isOnline) => {
      this.isOffline.next(!isOnline);
      this.isAPIOffline.next(!isOnline);
    });
  }

  setRequest = (req: HttpRequest<unknown>) => {
    this.requests2.push(req);
    this.requestsDur.push(performance.now());
    if(isDevMode()){
      console.warn('Loader Handled >> ', req.url);
    }
    this.isLoading.next(true);    
    return req;
  }

  removeRequest = (req: HttpRequest<any>) => {
    const ireq = this.requests2.indexOf(req);    
    if (ireq >= 0) {
      this.logRequestTime(this.requestsDur[ireq], req.url, req.method);      
      this.requests2.splice(ireq, 1);
    }    
    this.isLoading.next(this.requests2.length > 0);    
  }

  private logRequestTime(startTime: number, url: string, method: string) {
    const requestDuration = `${performance.now() - startTime}`;
    console.info(`${method} ${url} - ${requestDuration} milliseconds`);
  }

  @HostListener('window:online', ['$event'])
  createOnline$(): Observable<boolean> {
    const observable = new Observable((observer: Observer<boolean>)=>{
      observer.next(navigator.onLine);
      observer.complete();
    });

    return merge(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      observable)
  }
}
