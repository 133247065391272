import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'nospecials',
    standalone: true
})
export class NoSpecialCharPipe implements PipeTransform {

  transform(value: string): string {
    const newVal = value?.replace(/[^\w\s]/gi, '').toLocaleLowerCase();// Remove non word characters;
    return this.titleCase(newVal);
  }
  titleCase(str: string) {
    const splitStr = str.toLowerCase().split(' ');

    for (let k = 0; k < splitStr.length; k++) {
      if ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)) {
        splitStr[k] = splitStr[k].substring(1);
      }

    }
    return splitStr.join('');
  }

}
