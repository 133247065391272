import { HttpContextToken, HttpEvent, HttpHandler,  HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, isDevMode } from '@angular/core';
import { Observable, of, throwError, TimeoutError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { LoadingSpinService } from "src/app/shared/loaders/spinner/loadspinner.service";
import { ErrorhanderService } from "../services/error-handler.service";

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');
export const DEFAULT_TIMEOUTCTX = new HttpContextToken<number>(() => 120000);

// export function TimeoutInterceptorFn(req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
//   const toastrService = inject(ToastrService);
//   const loaderService = inject(LoadingSpinService);
//   const errorService =  inject(ErrorhanderService);
//   const reqWithHeader = req.clone({
//     headers: req.headers.delete('timeout'),
//   })

//   if(reqWithHeader.headers.has('timeout')) {
//     console.error('TIMEOUT FAIL for', req.url);
//   }
//   if(isDevMode()){
//     console.warn('Timeout Handled >> ', req.url);
//   }
//   const timeoutValue = reqWithHeader.context.get(DEFAULT_TIMEOUTCTX);
//   return next(req).pipe(timeout(timeoutValue),
//     catchError((returnedError) => {
//       //if (!returnedError.url?.endsWith('doLoanCreate')) { }
//       if (returnedError instanceof TimeoutError) {
//         toastrService.warning('Timeout occured', `LMS Request Time-Out`, { positionClass: 'toast-top-center', disableTimeOut: true, closeButton: true, progressAnimation: 'increasing' });
//         //this.errorService.handleOffline(returnedError.message, 'Server not responding');
//         loaderService.stopLoading();
//         return throwError(() => {
//            const error = new Error(returnedError.message);
//            return error;
//         });
//       }

//       return of(returnedError);
//     })
// );
// }

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(
    public loaderService: LoadingSpinService,
    public errorService: ErrorhanderService,
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeoutValue = req.context.get(DEFAULT_TIMEOUTCTX) || this.defaultTimeout;
    const reqWithHeader = req.clone({
      headers: req.headers.delete('timeout'),
    })

    if(reqWithHeader.headers.has('timeout')) {
      console.error('TIMEOUT FAIL for', req.url);
    }
    if(isDevMode()){
      console.warn('Timeout('+timeoutValue+'ms) STRT >> ', req.url);
    }

    return next.handle(reqWithHeader).pipe(
      timeout(timeoutValue),
      catchError((returnedError) => {
        //if (!returnedError.url?.endsWith('doLoanCreate')) { }
        if (returnedError instanceof TimeoutError) {
          //this.errorService.handleOffline(returnedError.message, 'Server not responding');
          console.error('TIMOUT ERROR>> ', returnedError.message);
          return throwError(() => {
            const error = new Error(returnedError.message);
            return error;
          });
        }

        if(returnedError instanceof Error){
          console.error('TIMEOUT ERROR>> rethrow error ', returnedError.message);
          return throwError(() => returnedError);
        }

        return of(returnedError);
      })
    );
  }
}
