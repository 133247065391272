import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LMSReasonCodesModel } from 'src/app/core/models/lms-reason-codes.model';
import { environment } from 'src/environments/environment';
import { RejectType } from '../reject-offer/reject-offer.component';

@Injectable({
  providedIn: 'root'
})
export class RejectReasonCodesService {
  http: HttpClient;
  RejectReasonCode_apiURL = environment.apiUrl+'/loan/api/admin';
  lmsReasonCodesModel: LMSReasonCodesModel;
  rejectReasonsData: any;
    data: any;

  HttpParamsOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      })
  };

  constructor(http: HttpClient) {
    this.http = http;
  }

setRejectReasonCode(data: any) {
  this.lmsReasonCodesModel = data;
}


  /** REJECT REASON CODES- get only returns the response (does not populate rejectReasonsData)  **/
  getAllRejectReasonCodes() {
    return this.http.
        get<any>(this.RejectReasonCode_apiURL + '/findAllRejectReasonCodes');
  }

  /** REJECT REASON CODES- find populcates the loanReasonsData property  **/
  findAllRejectReasonCodes(): Observable<any> {
    return this.http
    .get<any>(this.RejectReasonCode_apiURL+'/findAllRejectReasonCodes')
    .pipe(
      map((responseData) => {
        this.rejectReasonsData = responseData;
        return responseData;
      })
    );
  }

  getAllRejectReasonByReasonType(reasonType:RejectType = "Rejection"): Observable<any> {
    return this.http
    .get<any>(this.RejectReasonCode_apiURL+'/getRejectReasonByReasonType/'+ reasonType)
    .pipe(
      map((responseData) => {
        this.rejectReasonsData = responseData;
        return responseData;
      })
    );
  }
  saveRejectReasonCodesData(data: any) {
    return this.http
        .post<any>(this.RejectReasonCode_apiURL+'/saveRejectReasonCode', data)
        .pipe(
            map((responseData) => {
                return responseData;
            })
        );
  }

  removeRejectReasonCodes(id: any): Observable<any> {
      return this.http
          .delete(this.RejectReasonCode_apiURL+'/removeRejectReasonCode/' + id)
          .pipe(
              map((responseData) => {
                  return responseData;
              })
          );
  }
  updateReasonCodes(data: any) {
    return this.http
        .post<any>(this.RejectReasonCode_apiURL+'/updateRejectReasonCode', data)
        .pipe(
            map((responseData) => {
              this.rejectReasonsData = responseData;
                return responseData;
            })
        );
}

}
