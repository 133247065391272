/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PaySlipModel1 } from 'src/app/core/models/pay-slip-model.model';
import { NgClass, NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
//import { LMSSharedUiModule } from '@lmsSharedUI';
import { NumbersOnlyDirective } from 'src/app/shared/directives/numbersOnly.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AppUtilityService } from 'src/app/app-utility.service';
import { MatButtonModule } from '@angular/material/button';
import { PageCardheadComponent } from 'src/app/shared/ui/pageheader-card/pageheader-card.component';
import { MdbValidationModule } from 'mdb-angular-ui-kit/validation';

@Component({
  selector: 'app-payslip-dialog',
  templateUrl: './payslip-dialog.component.html',
  styleUrls: ['./payslip-dialog.component.css'],
  standalone: true,
  imports: [
    PageCardheadComponent,
    FormsModule,
    ReactiveFormsModule,
    PageCardheadComponent,
    MatFormFieldModule,
    MatDatepickerModule,
    NgIf, NgClass,
    MatButtonModule,
    NumbersOnlyDirective,
  ],
})
export class PayslipDialogComponent implements OnInit {
  @ViewChild('nettIncome') nettIncome: ElementRef;

  PayDatesFilter = (d: Date): boolean => {
    const day = d.getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  };

  payslipModel: PaySlipModel1;
  payslipForm: FormGroup;
  // grossIncome: string;
  // deductions: string;
  snettIncome: string;
  otherIncome = 0;
  submitted = false;
  payDateError: string;
  invalidDate = false;
  //calculateError: boolean;
  maxDate = new Date();
  clientType = '';

  constructor(
    private fb: FormBuilder,
    public toastrService: ToastrService,
    public dialogRef: MatDialogRef<PayslipDialogComponent>,
    public global_utility: AppUtilityService,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {
     this.payslipModel = data.payslipModel;
     this.payslipModel.month = data.month;
     this.clientType = data.clientType;
  }

  ngOnInit(): void {
    console.log(this.clientType);
    this.payslipForm = this.fb.group({
      month: [this.payslipModel.month, [Validators.required]],
      date: [
        this.payslipModel.payslipDate || this.payslipModel.date,
        [Validators.required],
      ],
      nettIncome: [
        this.payslipModel.nettIncome || null,
        [Validators.required, Validators.min(0)],
      ],
      otherIncome: [this.payslipModel.otherIncome || 0, [Validators.min(0)]],
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  save() {
    this.submitted = true;

    if (this.payslipForm.invalid) {
      this.toastrService.warning(
        'Please enter required payslip details',
        'Income details',
      );
      return;
    }

    this.payslipModel.payslipDate = this.payslipModel.date = this.payslipForm.value.date;
    this.payslipModel.nettIncome = this.payslipForm.value.nettIncome;
    this.payslipModel.otherIncome = this.payslipForm.value.otherIncome;
    return this.dialogRef.close(this.payslipModel);
  }

  get payslipInput() {
    return this.payslipForm!.controls;
  }

  // payDateChange(event :any){
  //   this.nettIncome.nativeElement.focus();
  // }
}
