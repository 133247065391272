import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import paginate from '@lmsPaginate';
import { Subject, Subscription } from 'rxjs';
import { QuoteStoreService } from 'src/app/core/rest-services/quote-store.service';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';
import { PageCardheadComponent } from '../../shared/ui/pageheader-card/pageheader-card.component';

@Component({
    selector: 'app-audit-trail',
    templateUrl: './audit-trail.component.html',
    styleUrls: ['./audit-trail.component.css'],
    standalone: true,
    imports: [MatDialogModule, PageCardheadComponent, NgIf, MatTableModule, NgFor, NgClass, DatePipe]
})
export class AuditTrailComponent implements OnInit, OnDestroy {
  idNumber: string;
  quoteId: number;
  //PAGINATOR CODE
  @Input() initialPage = 1;
  @Input() pageSize = 6;
  @Input() maxPages = 3;

  listArray : any;
  selectedItem: any;
  sum = 20;
  direction = "";
  isauditLoading = false;
  @Input() items: Array<any>;

  @Output() onRowSelect = new EventEmitter<any>();

  @Output() onRowAdd = new EventEmitter<any>();
  @Output() onRowEdit = new EventEmitter<any>();
  @Output() onLoaded = new EventEmitter<number>();

  pager: any = {};

  displayed_columns!: string[];
  private data_change_sub!: Subscription;
  table_data_source!: any;
  updated_row_index = -1;
  table_config:any = {
    columns: [
      {
        key: 'time',
        heading: 'Date',
        datecol: true
      },
      {
        key: 'userName',
        heading: 'User',
        hideCol: true
      },
      {
        key: 'action',
        heading: 'Action',
        hideCol: true
      },
      {
        key: 'newData',
        heading: 'New Data'
      },
      {
        key: 'previousData',
        heading: 'Previous Data'
      },
    ],
    primary_key_set: ['time'],
    table_data_changer: new Subject<any>(),
    ediTable: {
      add: false,
      edit: false
    }
  };
  constructor(
    private quoteStoreService: QuoteStoreService,
    public dialogRef: MatDialogRef<AuditTrailComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
    //private clientSearch: ClientDetailsService
  ) {
    //this.idNumber = data.IdNumber;
    this.quoteId = data.quoteid;
    //this.auditTrail = data.auditTrail;

    }

  ngOnInit(): void {
    this.setDisplayedColumns(this.table_config.columns);
    this.isauditLoading = true;

    this.data_change_sub = this.quoteStoreService.searchAuditTrailByQuoteId(this.quoteId).subscribe(
      (table_data) => {
        this.table_config.table_data_changer.next(
          table_data
        );
        this.isauditLoading = false;
    });

    if (!!this.table_config.table_data_changer) {
      // if there is a scope to update data
      this.trackDataChange();
    }
  }
  setDisplayedColumns(column_config: any[]) {
    let col_count = column_config.length;
    let columns_to_display: any[] = [];
    for (let i = 0; i < col_count; i++) {
      if(!column_config[i].hideCol){
        let col_config = column_config[i];
        columns_to_display.push(col_config.key);
      }
    }
    this.displayed_columns = columns_to_display;
  }

  ngAfterViewInit(): void {
    if (!!this.table_data_source) {
      //   //this.table_data_source.paginator = this.paginator;
      this.setPage(this.initialPage);
    }
  }

  trackDataChange() {
    this.data_change_sub = this.table_config.table_data_changer.subscribe(
      (new_data: any) => {
        //this.table_data_source = new_data;
        this.table_data_source = new MatTableDataSource<any>(new_data.data);
        this.items = new_data;
        if (!!new_data.highlight) {
          //if it is needed to highlight the updated/new row
          this.goToUpdatedPage(new_data.highlight, new_data.data);
        }
        this.onLoaded.emit(this.items?.length);
        console.log("onLoaded emit = " + this.items?.length);
        if (new_data && new_data.length) {
          this.setPage(this.initialPage);
        }

      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    // reset page if items array has changed
    if (changes.currentValue! != changes.previousValue!) {
      this.setPage(this.initialPage);
    }
  }

  goToUpdatedPage(updated_row: any, data: any[]) {
    //get the index of the updated row
    let updated_index = data.findIndex(
      (row) => {
        let is_matching = true;
        let primary_key_count = this.table_config.primary_key_set.length;
        for (let i = 0; i < primary_key_count; i++) {
          let column = this.table_config.primary_key_set[i];
          if (row[column] != updated_row[column]) {
            is_matching = false;
            break;
          }
        }
        return is_matching;
      }
    );
    //get the page the updated row is and navigate to it after 1sec
    setTimeout(() => {
      if (updated_index >= 0) {
        let page_size = this.pageSize;
        let current_page_index = this.pager.currentPage;
        let calculated_page_index = Math.ceil((updated_index + 1) / page_size) - 1;
        if (calculated_page_index != current_page_index) {
          if (calculated_page_index == 0) {
            //if the first page is to be navigated to
            //this.pager.pageIndex = 1;
            this.setPage(1)
          } else {
            //this.pager.pageIndex = calculated_page_index - 1;
            this.setPage(calculated_page_index - 1);
            //this.paginator.nextPage();
          }
        }
        this.updated_row_index = updated_index - (page_size * calculated_page_index);
        setTimeout(() => {
          this.updated_row_index = -1;
        }, 4000);
      }
    }, 100);
  }

  setPage(page: number) {
    // get new pager object for specified page

    this.pager = paginate({totalItems: this.items.length,currentPage: page,pageSize: this.pageSize,maxPages: this.maxPages});

    // get new page of items from items array
    if (Array.isArray(this.items)) {
      const pageOfItems = this.items.slice(this.pager.startIndex, this.pager.endIndex + 1);
      this.table_data_source = pageOfItems;
      // call change page function in parent component
      //this.changePage.emit(this.table_data_source);
    }

  }
   ngOnDestroy(): void {
    this.data_change_sub!.unsubscribe();
  }


  close() {
    this.dialogRef.close();
  }
}
