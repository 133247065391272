import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { RiskScoreModel } from 'src/app/core/models/risk-score-model.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RiskScoresService {
    http: HttpClient;
    riskScores_apiURL = environment.apiUrl+'/loan/api/admin';
    riskScoreModel: RiskScoreModel;
    riskScorecard: any;
    data: any;

    HttpParamsOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(http: HttpClient) {
        this.http = http;
    }

    getRiskScorecard() {
        return this.riskScorecard;
      }

      setRiskScorecard(data: any) {
        this.riskScorecard = data;
      }


    saveRiskScoreData(data: any) {
        return this.http
            .post<any>(this.riskScores_apiURL+'/save', data)
            .pipe(
                map((responseData) => {
                    return responseData;
                })
            )
    }

    updateRiskScoreData(data: any){
        return this.http
        .post<any>(this.riskScores_apiURL+'/updateRiskScore', data)
        .pipe(
            map((responseData) => {
                return responseData;
            })
        )
    }


    findByParameterType(data: any){
        return this.http
        .post<any>(this.riskScores_apiURL+'/findByParameterType', data)
        .pipe(
            map((responseData) => {
                this.riskScorecard = responseData;
                return responseData;
            })
        )

    }

    removeScorecard(id: any): Observable<any> {
        return this.http
          .delete(this.riskScores_apiURL+'/removeScorecard/' + id)
         .pipe(
          map((responseData) => {
            return responseData;
          })
        );

      }

}
