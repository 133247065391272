import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import paginate from '@lmsPaginate';
import { MenuService } from "src/app/core/rest-services/menu.service";
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { MenusDialogComponent } from '../menus-dialog/menus-dialog.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatButtonModule } from '@angular/material/button';
import { NgFor, NgIf, NgClass } from '@angular/common';



// /**
//  * Node for to-do item
//  */
// export class TodoItemNode {
//   children: TodoItemNode[];
//   item: string;
// }

// /** Flat to-do item node with expandable and level information */
// export class TodoItemFlatNode {
//   item: string;
//   level: number;
//   expandable: boolean;
// }

// /**
//  * The Json object for to-do list data.
//  */
// const TREE_DATA = {
//   Groceries: {
//     'Almond Meal flour': null,
//     'Organic eggs': null,
//     'Protein Powder': null,
//     Fruits: {
//       Apple: null,
//       Berries: ['Blueberry', 'Raspberry'],
//       Orange: null,
//     },
//   },
//   Reminders: ['Cook dinner', 'Read the Material Design spec', 'Upgrade Application to Angular'],
// };

// /**
//  * Checklist database, it can build a tree structured Json object.
//  * Each node in Json object represents a to-do item or a category.
//  * If a node is a category, it has children items and new items can be added under the category.
//  */
// @Injectable({providedIn:'root'})
// export class ChecklistDatabase {
//   dataChange = new BehaviorSubject<TodoItemNode[]>([]);

//   get data(): TodoItemNode[] {
//     return this.dataChange.value;
//   }

//   constructor() {
//     this.initialize();
//   }

//   initialize() {
//     // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
//     //     file node as children.
//     const data = this.buildFileTree(TREE_DATA, 0);

//     // Notify the change.
//     this.dataChange.next(data);
//   }

//   /**
//    * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
//    * The return value is the list of `TodoItemNode`.
//    */
//   buildFileTree(obj: { [key: string]: any; }, level: number): TodoItemNode[] {
//     return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
//       const value = obj[key];
//       const node = new TodoItemNode();
//       node.item = key;

//       if (value != null) {
//         if (typeof value === 'object') {
//           node.children = this.buildFileTree(value, level + 1);
//         } else {
//           node.item = value;
//         }
//       }

//       return accumulator.concat(node);
//     }, []);
//   }

//   /** Add an item to to-do list */
//   insertItem(parent: TodoItemNode, name: string) {
//     if (parent.children) {
//       parent.children.push({ item: name } as TodoItemNode);
//       this.dataChange.next(this.data);
//     }
//   }

//   updateItem(node: TodoItemNode, name: string) {
//     node.item = name;
//     this.dataChange.next(this.data);
//   }
// }

@Component({
    selector: 'app-menus-admin',
    templateUrl: './menus-admin.component.html',
    styleUrls: ['./menus-admin.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgFor, NgIf, MatButtonModule, NgClass, NgxPaginationModule]
})
export class MenusAdminComponent implements OnInit {
  menusAdminForm: FormGroup;
  menus: any;
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  // flatNodeMap: Map<TodoItemFlatNode, TodoItemNode> = new Map<TodoItemFlatNode, TodoItemNode>();

  // /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  // nestedNodeMap: Map<TodoItemNode, TodoItemFlatNode> = new Map<TodoItemNode, TodoItemFlatNode>();

  // /** A selected parent node to be inserted */
  // selectedParent: TodoItemFlatNode | null = null;

  // /** The new item's name */
  // newItemName: string = '';

  // treeControl: FlatTreeControl<TodoItemFlatNode>;

  // treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  // dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  // /** The selection for checklist */
  // checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);
  @Input() items: Array<any>;
  @Output() changePage = new EventEmitter<any>(true);
  @Input() initialPage = 1;
  @Input() pageSize = 8;
  @Input() maxPages = 3;

  pager: any = {};
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */

  constructor(
    private formBuilder: FormBuilder,
    private editDialog: MatDialog,
    private router: Router,
    public dialog: MatDialog,
    private menuService: MenuService,
    // private _database: ChecklistDatabase
  ) {
    // this.treeFlattener = new MatTreeFlattener(
    //   this.transformer,
    //   this.getLevel,
    //   this.isExpandable,
    //   this.getChildren,
    // );
    // this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    // this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    // _database.dataChange.subscribe(data => {
    //   this.dataSource.data = data;
    // });
  }

  public setPage(page: number) {
    // get new pager object for specified page
    this.pager = paginate({ totalItems: this.items.length, currentPage: page, pageSize: this.pageSize, maxPages: this.maxPages });

    // get new page of items from items array
    const pageOfItems = this.items.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );

    // call change page function in parent component
    this.changePage.emit(pageOfItems);
  }

  ngOnInit(): void {
    // this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
    //   this.isExpandable, this.getChildren);
    // this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    // this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


    this.menusAdminForm = this.formBuilder.group({});
    this.findAll();
  }
  // getLevel = (node: TodoItemFlatNode) => node.level;

  // isExpandable = (node: TodoItemFlatNode) => node.expandable;

  // getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  // hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  // hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  // transformer = (node: TodoItemNode, level: number) => {
  //   const existingNode = this.nestedNodeMap.get(node);
  //   const flatNode =
  //     existingNode && existingNode.item === node.item ? existingNode : new TodoItemFlatNode();
  //   flatNode.item = node.item;
  //   flatNode.level = level;
  //   flatNode.expandable = !!node.children?.length;
  //   this.flatNodeMap.set(flatNode, node);
  //   this.nestedNodeMap.set(node, flatNode);
  //   return flatNode;
  // };

  // /** Whether all the descendants of the node are selected. */
  // descendantsAllSelected(node: TodoItemFlatNode): boolean {
  //   const descendants = this.treeControl.getDescendants(node);
  //   const descAllSelected =
  //     descendants.length > 0 &&
  //     descendants.every(child => {
  //       return this.checklistSelection.isSelected(child);
  //     });
  //   return descAllSelected;
  // }

  // /** Whether part of the descendants are selected */
  // descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
  //   const descendants = this.treeControl.getDescendants(node);
  //   const result = descendants.some(child => this.checklistSelection.isSelected(child));
  //   return result && !this.descendantsAllSelected(node);
  // }

  // /** Toggle the to-do item selection. Select/deselect all the descendants node */
  // todoItemSelectionToggle(node: TodoItemFlatNode): void {
  //   this.checklistSelection.toggle(node);
  //   const descendants = this.treeControl.getDescendants(node);
  //   this.checklistSelection.isSelected(node)
  //     ? this.checklistSelection.select(...descendants)
  //     : this.checklistSelection.deselect(...descendants);

  //   // Force update for the parent
  //   descendants.forEach(child => this.checklistSelection.isSelected(child));
  //   this.checkAllParentsSelection(node);
  // }

  // /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  // todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
  //   this.checklistSelection.toggle(node);
  //   this.checkAllParentsSelection(node);
  // }

  // /* Checks all the parents when a leaf node is selected/unselected */
  // checkAllParentsSelection(node: TodoItemFlatNode): void {
  //   let parent: TodoItemFlatNode | null = this.getParentNode(node);
  //   while (parent) {
  //     this.checkRootNodeSelection(parent);
  //     parent = this.getParentNode(parent);
  //   }
  // }

  // /** Check root node checked state and change it accordingly */
  // checkRootNodeSelection(node: TodoItemFlatNode): void {
  //   const nodeSelected = this.checklistSelection.isSelected(node);
  //   const descendants = this.treeControl.getDescendants(node);
  //   const descAllSelected =
  //     descendants.length > 0 &&
  //     descendants.every(child => {
  //       return this.checklistSelection.isSelected(child);
  //     });
  //   if (nodeSelected && !descAllSelected) {
  //     this.checklistSelection.deselect(node);
  //   } else if (!nodeSelected && descAllSelected) {
  //     this.checklistSelection.select(node);
  //   }
  // }

  // /* Get the parent node of a node */
  // getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
  //   const currentLevel = this.getLevel(node);

  //   if (currentLevel < 1) {
  //     return null;
  //   }

  //   const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

  //   for (let i = startIndex; i >= 0; i--) {
  //     const currentNode = this.treeControl.dataNodes[i];

  //     if (this.getLevel(currentNode) < currentLevel) {
  //       return currentNode;
  //     }
  //   }
  //   return null;
  // }

  // /** Select the category so we can insert the new item. */
  // addNewItem(node: TodoItemFlatNode) {
  //   const parentNode = this.flatNodeMap.get(node);
  //   this._database.insertItem(parentNode!, '');
  //   this.treeControl.expand(node);
  // }

  // /** Save the node to database */
  // saveNode(node: TodoItemFlatNode, itemValue: string) {
  //   const nestedNode = this.flatNodeMap.get(node);
  //   this._database.updateItem(nestedNode!, itemValue);
  // }
  findAll() {
    this.menuService.findAll().subscribe((res: any) => {
      this.menus = res;
      this.items = this.menus;
      if (this.items && this.items.length) {
        this.setPage(this.initialPage);
      }
    });
  }

  editMenu(menu: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = 'auto';
    dialogConfig.minWidth = '850px';
    dialogConfig.data = { menu: menu };

    const dialogRef = this.editDialog.open(MenusDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((menu) => {
      //console.log(menu, '..result');
    });
  }

  deleteMenu(menu: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '650px',
      height: 'auto',
      autoFocus: true,
      data: {
        title: 'Menu Delete',
        message: 'Are you sure you want to delete?',
      },
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      const confirmResult = dialogResult;

      if (confirmResult == true) {
        for (const b in menu.submenus) {
          const parentMenu = { id: menu.id };
          const newSubmenu = {
            submenu: menu.submenus[b].submenu,
            component: menu.submenus[b].component,
            menu: parentMenu,
          };
          menu.submenus[b] = newSubmenu;
        }

        this.menuService.delete(menu).subscribe((res: any) => {
          this.menus = res;
          this.router.navigate(['/menuAdmin']);
        });
      }
    });
  }

  addMenu() {
    const submenus: any = [];
    const menu = {
      id: 0,
      menu: '',
      component: '',
      userRole: '',
      hasSubmenu: '',
      trigger: '',
      submenus: submenus,
    };

    const dialogRef = this.editDialog.open(MenusDialogComponent, {
      disableClose: true,
      autoFocus: true,
      height: 'auto',
      minWidth: '850px',
      data: { menu: menu }
    });

    dialogRef.afterClosed().subscribe((menu) => {
      // console.log(menu, '..result');
    });
  }
}
