import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, map, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AddressModel } from "src/app/core/models/address-model.model";
import { EmploymentDetailsModel } from "src/app/core/models/employment-details-model";
import { EmployerDetailsModel } from "src/app/features/branch-employers/models/employer-details-model";

@Injectable({providedIn:'root'})
export class BranchEmployerService {
  branchemployer_apiURL = `${environment.apiUrl}/loan/api/admin`;
  quote_apiURL = `${environment.apiUrl}/loan/api/quote`;

  employerDetailsModel: EmployerDetailsModel;

  employmentDetailsModel: EmploymentDetailsModel;


  private employer = new BehaviorSubject<any>(null);

  isEditing = false;

  addressModel: AddressModel = new AddressModel();

  private _preppedforCreate = false;

  HttpParamsOptions = {
    headers: new HttpHeaders({
      "content-type": "application/json"
    })
  };

  constructor(private httpClient: HttpClient) { }

  employer$ = this.employer.asObservable();
  public get quoteEmployersValue() {
    return this.employer.value;
  }

  setquoteEmployerObs = (emplr: any) => { this.employer.next(emplr); };
  // const findEmployerLimitRequest => of(getContinents(keys))
  // .pipe(
  //   tap(_ => console.log(`API CALL at ${new Date()}`))
  // );

  // getEmployerRequest(data: any): Observable<any> {
  //   const getemployerURL = `${this.branchemployer_apiURL}/findEmployersByName`;
  //   return this.httpClient
  //     .post<any>(getemployerURL, data, this.HttpParamsOptions)
  //       .pipe(
  //      //   filter(employer => !!employer),
  //       catchError(this.handleError),
  //       map(employer => {
  //         if(employer.length == 0){
  //           return;
  //         }
  //         if(employer.length == 1) {
  //           this.employerDetailsModel = employer;
  //           this.employer$.next(employer);

  //         }

  //         if(!employer){
  //           this.employerDetailsModel = defaultemp;
  //           return defaultemp;
  //         }

  //         return employer;
  //       }));
  // }

  // findEmployersByName(data: any): Observable<any> {
  //   const getemploymentURL = `${this.quote_apiURL}/findEmployerByName`;
  //   return this.httpClient
  //     .post<any>(getemploymentURL, data, this.HttpParamsOptions)
  //     .pipe(
  //       map(employer => {
  //         if (!employer || employer?.length == 0) {
  //           return;
  //         }

  //         if (employer && employer?.length >= 1) {
  //           this.employerDetailsModel = employer[0];
  //           this.setquoteEmployerObs(employer);
  //         }

  //         return employer;
  //       }));
  // }
  findEmployerBySerialTNR(data: any): Observable<any> {
    const getemploymentURL = `${this.quote_apiURL}/findEmployerBySerialTNR`;
    return this.httpClient
      .post<any>(getemploymentURL, data, this.HttpParamsOptions);
    /*
    .pipe(
      map(employer => {
        if (!employer || employer?.length == 0) {
          return;
        }

        if (employer && employer?.length >= 1) {
          this.employerDetailsModel = employer[0];
          this.setquoteEmployerObs(employer);
        }

        return employer;
      }));
    */
  }

  public set preppedforCreate(value: boolean) {
    this._preppedforCreate = value;
  }
  public get preppedforCreate(): boolean {
    return this._preppedforCreate;
  }

  saveEmployer(employerDetails: EmployerDetailsModel) {
    return this.httpClient
      .post<any>(`${this.branchemployer_apiURL}/saveEmployer`, employerDetails,this.HttpParamsOptions)
      .pipe(
        // catchError(this.handleError),
        map((responseData) => responseData)
      );
  }

  deleteEmployer(employerId: any): Observable<any> {
    return this.httpClient
      .delete(`${this.branchemployer_apiURL}/deleteEmployer/${employerId}`)
      .pipe(
        // catchError(this.handleError),
        map((responseData) => responseData)
      );
  }
}
