import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject, tap } from 'rxjs';
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

import { LMSReasonCodesModel } from "src/app/core/models/lms-reason-codes.model";

@Injectable({
  providedIn: 'root'
})
export class LoanReasonCodesService {
    loanReasonCode_apiURL = environment.apiUrl+'/loan/api/admin';
    lmsReasonCodesModel = {} as LMSReasonCodesModel;
    loanReasonsData: Subject<LMSReasonCodesModel[]>;

    HttpParamsOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private http: HttpClient) {
    }

    setLoanReasonCode(data: any) {
      this.lmsReasonCodesModel = data;
    }

    /** LOAN REASON CODES- get only returns the response (does not populate loanReasonsData)  **/
    getAllLoanReasonCodes() {
      return this.http.
        get<LMSReasonCodesModel[]>(this.loanReasonCode_apiURL+'/findAllLoanReasonCodes');
    }
    /** LOAN REASON CODES- find populcates the loanReasonsData property  **/
    findAllLoanReasonCodes(): Observable<any> {
      return this.http
      .get<any>(this.loanReasonCode_apiURL+'/findAllLoanReasonCodes')
      .pipe(
        tap((resdata) => {
          this.loanReasonsData.next(resdata);
        }),
        map((responseData) => {
          this.loanReasonsData.next(responseData);
          return responseData;
        })
      );
    }

    saveLoanReasonCodesData(data: any) {
      return this.http
          .post<any>(this.loanReasonCode_apiURL+'/saveLoanReasonCode', data)
          .pipe(
              map((responseData) => {
                this.loanReasonsData = responseData;
                  return responseData;

              })
          );
    }
    // updateLoanReasonCodesData(data: any) {
    //     return this.http
    //         .post<any>(this.loanReasonCode_apiURL+'/updateLoanReasonCode', data)
    //         .pipe(
    //             map((responseData) => {
    //                 return responseData;
    //             })
    //         );
    // }
    removeLoanReasonCodes(id: any): Observable<any> {
        return this.http
            .delete(this.loanReasonCode_apiURL+'/removeLoanReasonCode/' + id)
            .pipe(
                map((responseData) => {

                    return responseData;
                })
            );
    }
    updateReasonCodes(data: any) {
      return this.http
          .post<any>(this.loanReasonCode_apiURL+'/updateLoanReasonCode', data)
          .pipe(
              map((responseData) => {
                this.loanReasonsData = responseData;
                  return responseData;
              })
          );
  }

    updateTableData(row_data:any, is_new_row_being_added:boolean){
      let API_key = is_new_row_being_added? 'table_row_add' : 'table_row_edit';
      return this.http
      .post<any>(this.loanReasonCode_apiURL+'/updateLoanReasonCode', row_data)
      .pipe(
          map((responseData) => {
            this.loanReasonsData = responseData;
              return responseData;
          })
      );
    }
}
