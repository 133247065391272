import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeycloakService } from 'keycloak-angular';
import { RiskScoreModel } from 'src/app/core/models/risk-score-model.model';
import { RiskScoresService } from '../risk-scores.service';
import { FormsModule } from '@angular/forms';
import { PageCardheadComponent } from '../../../shared/ui/pageheader-card/pageheader-card.component';

@Component({
    selector: 'app-risk-score-remove-dialog',
    templateUrl: './risk-score-remove-dialog.component.html',
    styleUrls: ['./risk-score-remove-dialog.component.css'],
    standalone: true,
    imports: [PageCardheadComponent, FormsModule]
})
export class RiskScoreRemoveDialogComponent implements OnInit {
  riskScoreModel: RiskScoreModel = new RiskScoreModel();
  userProfile: Keycloak.KeycloakProfile = {};
  riskScorecardInfo: any;
  submitted: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<RiskScoreRemoveDialogComponent>,
    public riskScoreService: RiskScoresService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) { this.riskScorecardInfo = data.riskScorecard }

  ngOnInit(): void {
    // this.keycloak.loadUserProfile().then(user => {
    //   this.userProfile = user;
    // });
  }

  removeScoreCardConfirmation() {
    this.submitted = true;
    var isValidData = true;

    if (this.riskScorecardInfo.id == null) {
      isValidData = false;
      console.log("Form data is invalid...");
    }

    if (isValidData) {
      return this.dialogRef.close(this.riskScoreModel);
    }
  }

  close() {
      this.dialogRef.close();
  }
}
