<div class="modal-content">
  <div class="modal-body" >
    <div class="center-screen container-fluid bd-example-row">
      <form novalidate>
        <br>
        <p align="center" style="color: red; font-size: 15px;">
            <b>Are you sure you want to delete this Product? This is permanent.</b></p>

        <table id="clientInfo" class="table table-striped table-light table-hover table-sm mb-3 caption-top" style="width:700px">
            <thead class="table-center-header">
                <tr>
                    <td colspan="4">Product Details</td>
                </tr>
            </thead>

            <tbody>
                <tr align="left">
                    <td style="width: 25%;">Code</td>
                    <td style="width: 25%;">{{loanTypeData.code}}</td>

                    <td style="width: 25%;">Description</td>
                    <td style="width: 25%;">{{loanTypeData.description}}</td>
                </tr>

                <tr align="left">
                    <td>Type</td>
                    <td>{{loanTypeData.type}}</td>

                    <td>Regulatory Type</td>
                    <td>{{loanTypeData.regulatoryType}}</td>
                </tr>
            </tbody>
        </table>
        <br />
        <button class="btn btn-primary me-2" mdbRipple  (click)="removeLoanTypeConfirmation()"> Yes </button>
        <button  class="btn btn-primary " mdbRipple  (click)="close()"> No </button>
      </form>
    </div>
  </div>
</div>
