import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';

@Directive({
  selector: 'appJobtitleSpecific',
  providers: [{ provide: NG_VALIDATORS, useExisting: JobtitleSpecificValidatorDirective, multi: true }]
})
export class JobtitleSpecificValidatorDirective implements Validator {

  constructor() { }
  validate(control: AbstractControl): ValidationErrors | null {
    return JobtitleSpecificValidator(control);
  }
  registerOnValidatorChange?(fn: () => void): void {
    throw new Error('Method not implemented.');
  }
}


export const JobtitleSpecificValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const areas = control.get('managedAreas');
  const branch = control.get('selectedBranch');
  const region = control.get('region');
  const jobtitle = control.get('selectedJobtitle');

  if (!jobtitle?.value || jobtitle?.value == '' || !jobtitle?.touched || !jobtitle?.dirty) {
    return null;
  }
  const jobTitleValue = jobtitle?.value.jobTitle;

  const isAreaManager = jobTitleValue === 'Area Manager';
  const isRegionalManagerManager = jobTitleValue === 'Regional Manager';

  if (branch && jobtitle && (jobTitleValue == 'Branch Consultant' || jobTitleValue == 'Branch Manager')) {
    branch.addValidators(Validators.required);
  }
  else{
    branch?.clearValidators();
  }

  if (areas && isAreaManager) {
    areas.addValidators(Validators.required);
  } else
  {
    areas?.clearValidators();
  }

  if (region && isRegionalManagerManager) {
    region.addValidators(Validators.required);
  } else {
    region?.clearValidators();
  }

  const retval = ((areas?.value && areas?.value.length == 0 && isAreaManager) ||
    (region?.value && region?.value.length == 0 && isRegionalManagerManager) ||
    (!(isAreaManager || isRegionalManagerManager) && branch?.value && branch?.value.length == 0)) ? { jobtitleSpecific: true } : null;
  return retval;
};
