<div class="modal-content">
  <div class="modal-header">
    <div class="modal-title center-fields">
      <app-page-cardhead style="margin-top: -1.1rem;"  [PageName]=title [PageHeadWidth]="450"></app-page-cardhead>
    </div>
  </div>

  <div class="modal-body myh-300 py-3 px-1 text-center">
    <h6>{{message}}</h6>
  </div>

  <hr class="hr hr-blurry">
  <div class="center-screen">
    <button class="btn btn-sm btn-primary m-2" mdbRipple color="primary" (click)="close()"> Close </button>
  </div>
</div>
