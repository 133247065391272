<div class="modal-content">
  <div class="modal-header">
    <button class="btn btn-close " color="red"  (click)="dialogRef.close()" ></button>
    <div class="modal-title center-fields">
      <app-page-cardhead style="margin-top: -2.1rem;" [PageName]="'Product Setup'" [PageHeadWidth]="740"></app-page-cardhead>
    </div>
  </div>

  <div class="modal-body" >
    <div class="card">
      <div class="card-body p-1">
        <div class="center-screen container-fluid bd-example-row">
          <form [formGroup]="addLoanTypeForm" novalidate>
              <mat-accordion>
                <table id="productRates" class="table p-1 table-striped table-hover table-sm mb-3 caption-top shadow-1-strong rounded-1" [style.width.px]="900">
                  <thead class="table-center-header">
                    <tr>
                      <td colspan="4">Product Details</td>
                    </tr>
                  </thead>
                  <tbody class="field-4column-label">
                    <tr>
                      <td><label for="code">Code</label></td>
                      <td>
                      <input mat-input type="text" formControlName="code" [(ngModel)]="ratesAndFeesModel.code">
                      </td>

                      <td><label for="description">Description</label></td>
                      <td>
                        <input mat-input type="text" formControlName="description" [(ngModel)]="ratesAndFeesModel.description"
                          required>
                        <div *ngIf="validateRatesAndFeesValues.description.errors?.required && submitted" class="error-label">
                          Description is required
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Minimum Loan Amount</td>
                      <td>
                        <input mat-input type="text" formControlName="minimumLoan" [(ngModel)]="ratesAndFeesModel.minimumLoan"
                          required>
                        <div *ngIf="validateRatesAndFeesValues.minimumLoan.errors?.required && submitted" class="error-label">
                          Minimum loan is required
                        </div>
                      </td>

                      <td>Maximum Loan Amount</td>
                      <td>
                        <input mat-input type="text" formControlName="maximumLoan" [(ngModel)]="ratesAndFeesModel.maximumLoan"
                          required>
                        <div *ngIf="validateRatesAndFeesValues.maximumLoan.errors?.required && submitted" class="error-label">
                          Maximum Loan is required
                        </div>
                      </td>
                    </tr>
                    <!-- interestRate1st: number;
                          interestRateOther: number; -->
                    <tr>
                      <td>Interest rate 1st loan (%)</td>
                      <td>
                        <input mat-input type="text" formControlName="interestRate1st"
                          [(ngModel)]="ratesAndFeesModel.interestRate1st" required>
                        <div *ngIf="validateRatesAndFeesValues.interestRate1st.errors?.required && submitted" class="error-label">
                          Interest rate 1st loan is required
                        </div>
                      </td>

                      <td>Interest rate other loans (%)</td>
                      <td>
                        <input mat-input type="text" formControlName="interestRateOther"
                          [(ngModel)]="ratesAndFeesModel.interestRateOther" required>
                        <div *ngIf="validateRatesAndFeesValues.interestRateOther.errors?.required && submitted"
                          class="error-label">
                          Interest rate other is required
                        </div>
                      </td>
                    </tr>
                    <!-- initFeeBelow1000: number;
                          initFeeFirst1000: number; -->
                    <tr>
                      <td>Initiation fee if cap below 1000</td>
                      <td>
                        <input mat-input type="text" formControlName="initFeeBelow1000"
                          [(ngModel)]="ratesAndFeesModel.initFeeBelow1000">
                      </td>

                      <td>Initiation fee first 1000</td>
                      <td>
                        <input mat-input type="text" formControlName="initFeeFirst1000"
                          [(ngModel)]="ratesAndFeesModel.initFeeFirst1000">
                      </td>
                    </tr>

                    <!-- initiationFee: number;
                          initiationCap: number; -->
                    <tr>
                      <td>Initiation fee (%)</td>
                      <td>
                        <input mat-input type="text" formControlName="initiationFee" [(ngModel)]="ratesAndFeesModel.initiationFee"
                          required>
                        <div *ngIf="validateRatesAndFeesValues.initiationFee.errors?.required && submitted" class="error-label">
                          Initiation fee is required
                        </div>
                      </td>

                      <td>Initiation fee cap</td>
                      <td>
                        <input mat-input type="text" formControlName="initiationCap" [(ngModel)]="ratesAndFeesModel.initiationCap"
                          required>
                        <div *ngIf="validateRatesAndFeesValues.initiationCap.errors?.required && submitted" class="error-label">
                          Initiation fee cap is required
                        </div>
                      </td>
                    </tr>
                    <!-- myBenefits: number;
                              term:  number; -->
                    <tr>
                      <td>My Benefits (%)</td>
                      <td>
                        <input mat-input type="text" formControlName="myBenefits" [(ngModel)]="ratesAndFeesModel.myBenefits"
                          required>
                        <div *ngIf="validateRatesAndFeesValues.myBenefits.errors?.required && submitted" class="error-label">
                          My Benefits is required
                        </div>
                      </td>

                      <td>Term in months</td>
                      <td>
                        <input mat-input type="text" formControlName="term" [(ngModel)]="ratesAndFeesModel.term" required>
                        <div *ngIf="validateRatesAndFeesValues.term.errors?.required && submitted" class="error-label">
                          Term is required
                        </div>
                      </td>
                    </tr>
                    <!-- factor1stLoan: number;
                          factorOtherLoan: number; -->
                    <tr>
                      <td>Factor 1st loan</td>
                      <td>
                        <input mat-input type="text" formControlName="factor1stLoan"
                          [(ngModel)]="ratesAndFeesModel.factor1stLoan">
                        <!-- <div *ngIf="validateRatesAndFeesValues.factor1stLoan.errors?.required && submitted"
                                      class="error-label">
                                      Factor 1st loan is required
                                  </div> -->
                      </td>

                      <td>Factor other loans</td>
                      <td>
                        <input mat-input type="text" formControlName="factorOtherLoan"
                          [(ngModel)]="ratesAndFeesModel.factorOtherLoan">
                        <!-- <div *ngIf="validateRatesAndFeesValues.factorOtherLoan.errors?.required && submitted"
                                      class="error-label">
                                      Factor other loans is required
                                  </div> -->
                      </td>
                    </tr>
                    <!-- factorUpTo850: number;
                          factorUpTo1000: number; -->
                    <tr>
                      <td>Factor up to 850</td>
                      <td>
                        <input mat-input type="text" formControlName="factorUpTo850"
                          [(ngModel)]="ratesAndFeesModel.factorUpTo850">
                        <!-- <div *ngIf="validateRatesAndFeesValues.factorUpTo850.errors?.required && submitted"
                                      class="error-label">
                                      Factor up to 850 is required
                                  </div> -->
                      </td>

                      <td>Factor up to 1000</td>
                      <td>
                        <input mat-input type="text" formControlName="factorUpTo1000"
                          [(ngModel)]="ratesAndFeesModel.factorUpTo1000">
                        <!-- <div *ngIf="validateRatesAndFeesValues.factorUpTo1000.errors?.required && submitted"
                                      class="error-label">
                                      Factor up to 1000 is required
                                  </div> -->
                      </td>
                    </tr>
                    <!-- monthlyServiceFee: number; initServiceFeeCap: -->
                    <tr>
                      <td>Monthly service fee</td>
                      <td>
                        <input mat-input type="text" formControlName="monthlyServiceFee"
                          [(ngModel)]="ratesAndFeesModel.monthlyServiceFee" required>
                        <div *ngIf="validateRatesAndFeesValues.monthlyServiceFee.errors?.required && submitted"
                          class="error-label">
                          Monthly service fee is required
                        </div>
                      </td>

                      <td>Initiation service fee cap (%)</td>
                      <td>
                        <input mat-input type="text" formControlName="initServiceFeeCap"
                          [(ngModel)]="ratesAndFeesModel.initServiceFeeCap" required>
                        <div *ngIf="validateRatesAndFeesValues.initServiceFeeCap.errors?.required && submitted"
                          class="error-label">
                          Initiation service fee cap is required
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Max days before first payment</td>
                      <td>
                        <input formControlName="maxDays" [(ngModel)]="ratesAndFeesModel.maxDays" required>
                        <div *ngIf="validateRatesAndFeesValues.maxDays.errors?.required && submitted" class="error-label">
                          Max days before first payment is required
                        </div>
                      </td>
                      <td>Regulatory type</td>
                      <td>
                        <select formControlName="regulatoryType" [(ngModel)]="ratesAndFeesModel.regulatoryType" required>
                          <option *ngFor="let regType of lstRegulatory;" [value]="regType.value">
                            {{regType.description}}
                          </option>
                        </select>
                        <div *ngIf="validateRatesAndFeesValues.regulatoryType.errors?.required && submitted" class="error-label">
                          Regulatory type is required
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>MyBenefits Document tier</td>

                      <td>
                        <select formControlName="mybenefitsTier" [(ngModel)]="ratesAndFeesModel.mybenefitsTier"
                        lmsSelectOption [constantName]="'MYBENEFIT_TIER'"
                        required>
                          <!-- <option *ngFor="let regType of lstMyBenefitTier;" [value]="regType.value">
                            {{regType.description}}
                          </option> -->
                        </select>
                        <div *ngIf="validateRatesAndFeesValues.mybenefitsTier.errors?.required && submitted" class="error-label">
                          MyBenefits document tier is required
                        </div>
                      </td>
                      <td colspan="2"></td>
                    </tr>
                  </tbody>
                </table>
              </mat-accordion>
              <br>
              <!-- Product Fee calculations -->
              <mat-accordion>
                <table id="serviceFees" class="table p-1 table-striped table-hover table-sm mb-3 caption-top shadow-1-strong rounded-1" [style.width.px]="900">
                  <thead class="table-center-header">
                    <tr>
                      <td colspan="4">Product Fee Calculations</td>
                    </tr>
                  </thead>
                  <tbody class="field-4column-label">
                    <tr>
                      <td>Loan Type</td>
                      <td>
                        <input style="width: 250px;" formControlName="type" [(ngModel)]="ratesAndFeesModel.type">
                        <div *ngIf="validateRatesAndFeesValues.calculationType.errors?.required && submitted" class="error-label">
                          Loan type is required
                        </div>
                      </td>

                      <td>Regulatory type</td>
                      <td>
                        <input style="width: 250px;" formControlName="regulatoryType"
                          [(ngModel)]="ratesAndFeesModel.regulatoryType">
                        <div *ngIf="validateRatesAndFeesValues.regulatoryType.errors?.required && submitted" class="error-label">
                          Regulatory type is required
                        </div>
                      </td>
                    </tr>
                    <!-- calculationType: string;
                          maxDays: number; -->
                    <tr>
                      <td>Max days before first payment</td>
                      <td>
                        <input style="width: 250px;" formControlName="maxDays" [(ngModel)]="ratesAndFeesModel.maxDays" required>
                        <div *ngIf="validateRatesAndFeesValues.maxDays.errors?.required && submitted" class="error-label">
                          Max days before first payment is required
                        </div>
                      </td>

                      <td>Calculation type</td>
                      <td>
                        <input style="width: 250px;" formControlName="calculationType"
                          [(ngModel)]="ratesAndFeesModel.calculationType">
                        <!-- <div *ngIf="validateRatesAndFeesValues.calculationType.errors?.required && submitted"
                                      class="error-label">
                                      Calculation type is required
                                  </div> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </mat-accordion>


          </form>
        </div>
      </div>
    </div>
    <div class="card-footer center-screen py-3">
      <button mat-raised-button class="me-2" mdbRipple color="primary"
      (click)="close()"> Close </button>
      <button mat-raised-button class="me-2" mdbRipple color="primary"
      (click)="saveLoanType()">Save Product </button>
    </div>
  </div>
</div>
