<app-page-cardhead [PageName]="userVerificationOpen ? 'Verifiy User Biometrics': verificationOpen ? 'Verifiy Client Biometrics':'Enroll Biometrics'" [PageHeadWidth]=700></app-page-cardhead>

<div class="readerDivButtons">
  <button  type="button" class="btn btn-primary me-2" (click)="openReader()">Reader</button>

  <button [hidden]="biometricType === 'client' || biometricType === 'user'"  type="button" class="btn btn-primary me-2"
     (click)="openCapture()">Client Verification</button>

  <button [hidden]="biometricType === 'client' || biometricType === 'user'"  type="button" class="btn btn-primary me-2"
    (click)="openUserCapture()">User Verification</button>

  <button [hidden]="biometricType === 'client-offer'"  type="button" class="btn btn-primary me-2"
     (click)="openCapture()">Capture</button>
  <button [hidden]="biometricType === 'client-offer'"  type="button" class="btn btn-primary "
     (click)="openDisableFinger()">Disable Fingers</button>
</div>

<!--CLIENT READER -->
<div *ngIf="biometricType === 'client'">
  <div *ngIf="readerOpen">
    <div>
      <h4 style="margin-left: 2%; margin-top: 2%;">Select Reader :</h4>
      <input id="device" class="form-control" style="width: 95%; margin-left: 2%;" readonly [(ngModel)]="deviceSerial">
    </div>
    <div class="readerDivButtons" style="margin-top: 2%;">
      <button  type="button" class="btn btn-primary me-2" id="refreshList" (click)="getDevice()">
        Refresh List</button>
      <!-- <button  type="button" color="primary" id="capabilities" style="margin-left: 10px;"
        (click)="getDeviceInfo()"> Reader Info</button> -->

    </div>
    <div *ngIf="deviceInfo" style="margin-left: 2%;">
      <label>Device ID:</label>
      {{deviceSerial}}
      <br>
      <label>Uid Type:</label>
      {{deviceUIDType}}
      <br>
      <label>Device Tech:</label>
      {{deviceTechnology}}
      <br>
      <label>Device Modality:</label>
      {{deviceModality}}
    </div>
  </div>

  <!-- CLIENT CAPTURE -->
  <br>
  <div [style.display]="captureOpen ? 'block' : 'none'">
    <div class="Scores">
      <h5>Scan Quality : <input type="text" id="qualityInputBox" size="15" readonly [(ngModel)]="readQuality"
          style="background-color:#DCDCDC;text-align:center; margin-top: 10px;"></h5>
    </div>
    <br>
    <div>
      <div class="input-group d-flex align-items-center justify-content-center ms-auto">
          <select #allFingersSelect class="allFingersSelect" [disabled]="lstAllFingersDisable" style="max-width: 150px; "
            [(ngModel)]="selectedFingerName" (change)="onClientChange()"
            lmsSelectOption [constantName]="'ALLFINGERS'"  [addDefault]="true"
            required>
            <!-- <option value=""> --- Select ---</option> -->
            <!-- <option *ngFor="let finger of lstAllFingers;let fingerIndex = index;" [value]="finger.value">{{finger.title}}
            </option> -->
          </select>

          <button  type="button" class="btn btn-primary pb-2 pt-2"
            [disabled]="enrollDisabled || numberReadings < 4 || lstAllFingersDisable || clientEnrolled.length === 4 " (click)="enrollClient()">Enroll
            Finger</button>

          <button  type="button" class="btn btn-primary pb-2 pt-2" [disabled]="verifyDisabled"
            (click)="verifyClientIntermediate()">Verify Finger</button>
        </div>
    </div>
    <div *ngIf="!selectedFingerName.includes(this.selectedFingerName) || selectedFingerName === '' || selectedFingerName === 'null'"
      style="margin-left: 19%; color: red" [ngClass]="'error'">
      Please select a valid option.
    </div>

    <div class="content-capture mx-2">
      <div *ngFor="let data of sampleData.slice(0,4); let sampleDataIndex = index;" style="margin-left:2%;">
        <label style="margin-top:5px;" [for]="'sampleData' + sampleDataIndex">Finger Data {{ sampleDataIndex + 1 }}
        </label>
        <label sampleDataIndex>: Captured success</label>
      </div>
      &nbsp;

      <div *ngFor="let totFingers of clientEnrolled.slice(0,4); let totalFingersEnrolled = index;">
        <table class="table table-striped table-hover table-sm mb-3 w-75">
          <tbody class="field-4column-label">
            <tr>
              <td>Finger Already Enrolled</td>
              <td>{{totFingers.fingerName}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      &nbsp;
      <!-- Enable Skip Begin -->
      <div *ngIf="enableSkip">
        <table class="table table-striped table-hover table-sm mb-3 w-75">
          <tbody class="field-4column-label">
            <tr>
              <td>Branch Manager Username:
              </td>
              <td>
                <div class="input-group input-group-sm d-flex align-items-center justify-content-end ms-auto">
                  <input class="form-control" placeholder="BM Username" [(ngModel)]="biometricsModel.bmUserName" required aria-describedby="button-addon1">
                    <button type="button" id="button-addon1" class="btn btn-primary pb-2 pt-2" (click)="verifyBranchManager()"> Verify
                    Biometrics
                  </button>
                </div>
                <!-- <div *ngIf="biometricsModel.bmUserName == ''" class="error-label">
                  Please enter a valid Branch Manager Username.
                </div> -->
              </td>
            </tr>
            <tr>
              <td>
                Verify Finger Response:
              </td>
              <td>
                {{enrollmentMessage}}
              </td>
            </tr>
          </tbody>
        </table>

        <!-- <div *ngIf="isCellVisible ">
          <select style="max-width: 150px; margin-left: 20%;" [(ngModel)]="biometricsModel.skipReason"
           (change)="onSkipReasonChange($event)" required>
            <option value=""> --- Select ---</option>
            <option *ngFor="let skipReasons of lstBiometricsSkipReasons" [value]="skipReasons.value">
              {{skipReasons.description}}
            </option>
          </select>
          <button  type="submit" color="primary" style="margin-left: 10px;" *ngIf="enableSkip"
            (click)="skipRegistration(biometricsModel.skipReason)" > Continue Application
          </button>
          <button  type="submit" color="primary" style="margin-left: 10px;" (click)="backToOffers()">
            Try
            Again
          </button>
          <div style="margin-left: 20%;" *ngIf="biometricsModel.skipReason == '' || !submitted" class="error-label">
            Please select a valid Skip Reason.
          </div>
        </div> -->
      </div>

      &nbsp;
      <div class="readerDivButtons">
        <button  type="button" class="btn btn-primary me-2" [disabled]="startAcquisitionDisabled"
          (click)="startCapture()">Start</button>

        <!-- <button  type="button" color="primary" style="margin-left: 10px;"
          [disabled]="stopAcquisitionDisabled || getClientFingersCount()" (click)="stopClientCapture()">Stop</button> -->

        <button [disabled]="continueApplicationDisabled"  type="submit" class="btn btn-primary me-2"
           (click)="continueApplication()"> Continue Application
        </button>

        <button [disabled]="!skipBiometrics"  type="submit" class="btn btn-primary me-2"  (click)="continueLoan()">
          Skip Biometrics
        </button>

        <button  type="button" class="btn btn-primary me-2" (click)="closeScanFingers()">
         Close
        </button>

        <!-- <button [hidden]="noBiometrics"  type="submit" color="primary" style="margin-left: 10px;"
          (click)="backToPreAuth()">
          Back
        </button> -->
      </div>
    </div>
  </div>
</div>

<!-- USER READER -->
  <div [style.display]="biometricType === 'user' ? 'block' : 'none'"  class="mx-1">
    <div *ngIf="readerOpen">
      <div>
        <h4 style="margin-left: 2%; margin-top: 2%;">Select Reader :</h4>
        <input id="device" class="form-control" style="width: 95%; margin-left: 2%;" readonly [(ngModel)]="deviceSerial">
      </div>
      <div class="readerDivButtons" style="margin-top: 2%;">
        <button  type="button" class="btn btn-primary me-2" id="refreshList" (click)="getDevice()">
          Refresh List</button>
        <button  type="button" class="btn btn-primary me-2" id="capabilities"
          (click)="getDeviceInfo()"> Reader Info</button>
        <!-- <button  type="button" class="btn btn-primary me-2" id="capabilities" style="margin-left: 10px;"
          (click)="close()"> Back</button> -->

      </div>
      <div *ngIf="deviceInfo" style="margin-left: 2%;">
        <label>Device ID:</label>
        {{deviceSerial}}
        <br>
        <label>Uid Type:</label>
        {{deviceUIDType}}
        <br>
        <label>Device Tech:</label>
        {{deviceTechnology}}
        <br>
        <label>Device Modality:</label>
        {{deviceModality}}
      </div>
    </div>

  <!-- USER CAPTURE -->
  <br>
<!-- <div *ngIf="captureOpen"> -->
  <div [style.display]="captureOpen ? 'block' : 'none'">
    <div id="Scores" class="Scores">
      <h5>Scan Quality : <input type="text" id="qualityInputBox" size="15" class="text-center mt-1" readonly [(ngModel)]="readQuality"
          style="background-color:#DCDCDC;"></h5>
    </div>
    <br>
    <div>
      <select #allFingersSelect class="allFingersSelect" style="max-width: 150px; margin-left: 20%;"
        [(ngModel)]="selectedFingerName" (change)="onUserChange($event)"
        lmsSelectOption [constantName]="'ALLFINGERS'" [addDefault]="true"
        required>
        <!-- <option value=""> --- Select ---</option>
        <option *ngFor="let finger of lstAllFingers;let fingerIndex = index;" [value]="finger.value">{{finger.title}}
        </option> -->
      </select>
      <button  type="button" class="btn btn-primary ms-1 me-2"
        [disabled]="enrollDisabled || numberReadings < 4 || lstAllFingersDisable || userEnrolled.length === 10 "
        (click)="enrollUser()">Enroll
        Finger</button>
      <button  type="button" class="btn btn-primary me-2" [disabled]="verifyDisabled"
        (click)="verifyUserIntermediate()">Verify Finger</button>
    </div>
    <div *ngIf="!selectedFingerName.includes(this.selectedFingerName) || selectedFingerName === '' || selectedFingerName === 'null'"
      style="margin-left: 19%; color: red" [ngClass]="'error'">
      Please select a valid option.
    </div>

    &nbsp;
    <div class="content-capture">
      <div *ngFor="let data of sampleData.slice(0,4); let sampleDataIndex = index;" style="margin-left:2%;">
        <label style="margin-top:5px;" [for]="'sampleData' + sampleDataIndex">Finger Data {{ sampleDataIndex + 1 }}
        </label>
        <label sampleDataIndex>: Captured success</label>
      </div>
      &nbsp;
      <div *ngFor="let totFingers of userEnrolled | paginate: { itemsPerPage: pager.pageSize, currentPage: pager.currentPage }; let totalFingersEnrolled = index;">
        <table class="table table-striped table-light table-hover table-sm mb-3 caption-top" style="width: 98%;">
          <tbody class="field-4column-label">
            <tr>
              <td>Finger Already Enrolled</td>
              <td>{{totFingers.fingerName}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      &nbsp;
      <div class="lms-pager">
        <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
          <li [ngClass]="{disabled: pager.currentPage === 1}" class="page-item first-item">
            <a (click)="setPage(1)" class="page-link">First</a>
          </li>
          <li [ngClass]="{disabled: pager.currentPage === 1}" class="page-item previous-item">
            <a (click)="setPage(pager.currentPage - 1)" class="page-link">Previous</a>
          </li>
          <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}"
            class="page-item number-item">
            <a (click)="setPage(page)" class="page-link">{{page}}</a>
          </li>
          <li [ngClass]="{disabled: pager.currentPage === pager.totalPages}" class="page-item next-item">
            <a (click)="setPage(pager.currentPage + 1)" class="page-link">Next</a>
          </li>
          <li [ngClass]="{disabled: pager.currentPage === pager.totalPages}" class="page-item last-item">
            <a (click)="setPage(pager.totalPages)" class="page-link">Last</a>
          </li>
        </ul>
      </div>
      <div class="readerDivButtons">
        <button  type="button" class="btn btn-primary me-2" [disabled]="startAcquisitionDisabled"
          (click)="startCapture()">Start</button>

        <!-- <button  type="button" color="primary" style="margin-left: 10px;"
          [disabled]="stopAcquisitionDisabled || getUserFingersCount()" (click)="stopUserCapture()">Stop</button> -->

        <button [disabled]="continueApplicationDisabled"  type="submit" class="btn btn-primary me-2"
          (click)="close()"> Continue Application
        </button>

        <button  type="button" class="btn btn-primary ms-1 me-2"
          (click)="close()">
          Back
        </button>
      </div>
    </div>
  </div>
</div>

<!--CLIENT DISABLE -->
<div *ngIf="biometricType === 'client'"  class="mx-1" cdk-scrollable>
  <div *ngIf="disableOpen && !captureOpen && !readerOpen">
    <div *ngFor="let enroll of clientEnrolled">
      <table class="table table-striped table-light table-hover table-sm mb-3 caption-top" style="width: 98%;">
        <tbody class="field-4column-label">
          <tr>
            <td>Finger Id</td>
            <td>{{enroll.id}}</td>
          </tr>
          <tr>
            <td>Finger Name</td>
            <td>{{enroll.fingerName}}</td>
          </tr>
          <tr>
            <td>
              <button  class="btn btn-primary me-2 readerDivButtons" mdbRipple
              (click)="disableClientFinger(enroll.id)">Disable Finger</button>
              <!-- <div>
                <button [disabled]="!reasonSelected"  class="btn btn-primary btn-rounded" mdbRipple
                  color="primary" class="readerDivButtons"
                  (click)="disableClientFinger(enroll.id)">Disable
                  Finger</button>
                <select style="max-width: 150px; margin-left: 5%;" (change)="fetchDisableReason($event)"
                  required>
                  <option value=""> --- Select ---</option>
                  <option *ngFor="let disabledFinger of lstDisableClientFingers;let fingerIndex = index;">
                    {{disabledFinger.description}}
                  </option>
                </select>
              </div> -->
              <div
                *ngIf="enrollmentError === 'SUCCESS' && enrollmentMessage === 'Finger Disabled' && biometricsModel.id === enroll.id"
                [ngClass]="'error'" style="color: green;">
                Finger Disabled.
              </div>
              <div
                *ngIf="enrollmentError === 'FAILURE' && enrollmentMessage !== 'Finger Disabled' && biometricsModel.id === enroll.id"
                [ngClass]="'error'" style="color: red; float:right; margin-right: 303px;">
                Finger NOT Disabled.
              </div>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="readerDivButtons">
      <button [disabled]="getClientFingersCount()"  type="button" class="btn btn-primary me-2"
        (click)="close()">Close</button>
      <button  type="button" class="btn btn-primary ms-1 me-2"
        (click)="disableAllClientFingers()">Disable All
        Fingers</button>
    </div>
  </div>
</div>

<!--USER DISABLE -->
<div *ngIf="biometricType === 'user'"  class="mx-1" cdk-scrollable>
  <div *ngIf="disableOpen && !captureOpen && !readerOpen">
    <div *ngFor="let enroll of userEnrolled | paginate: { itemsPerPage: pager.pageSize, currentPage: pager.currentPage }">
      <table class="table table-striped table-light table-hover table-sm mb-3 caption-top" style="width: 98%;">
        <tbody class="field-4column-label">
          <tr>
            <td>Finger Id</td>
            <td>{{enroll.id}}</td>
          </tr>
          <tr>
            <td>Finger Name</td>
            <td>{{enroll.fingerName}}</td>
          </tr>
          <tr>
            <td>
              <button  class="btn btn-primary me-2 readerDivButtons" mdbRipple
                (click)="disableUserFinger(enroll.id)">Disable Finger</button>
              <div
                *ngIf="enrollmentError === 'SUCCESS' && enrollmentMessage === 'Finger Disabled' && biometricsModel.id === enroll.id"
                [ngClass]="'error'" style="color: green;">
                Finger Disabled.
              </div>
              <div
                *ngIf="enrollmentError === 'FAILURE' && enrollmentMessage !== 'Finger Disabled' && biometricsModel.id === enroll.id"
                [ngClass]="'error'" style="color: red; float:right; margin-right: 303px;">
                Finger NOT Disabled.
              </div>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="lms-pager">
      <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
        <li [ngClass]="{disabled: pager.currentPage === 1}" class="page-item first-item">
          <a (click)="setPage(1)" class="page-link">First</a>
        </li>
        <li [ngClass]="{disabled: pager.currentPage === 1}" class="page-item previous-item">
          <a (click)="setPage(pager.currentPage - 1)" class="page-link">Previous</a>
        </li>
        <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}"
          class="page-item number-item">
          <a (click)="setPage(page)" class="page-link">{{page}}</a>
        </li>
        <li [ngClass]="{disabled: pager.currentPage === pager.totalPages}" class="page-item next-item">
          <a (click)="setPage(pager.currentPage + 1)" class="page-link">Next</a>
        </li>
        <li [ngClass]="{disabled: pager.currentPage === pager.totalPages}" class="page-item last-item">
          <a (click)="setPage(pager.totalPages)" class="page-link">Last</a>
        </li>
      </ul>
    </div>
    <div class="readerDivButtons">
      <button [disabled]="getUserFingersCount()"  type="button" class="btn btn-primary me-2"
        (click)="close()">Close</button>
      <button  type="button" class="btn btn-primary ms-1 me-2"
        (click)="disableAllUserFingers()">Disable All
        Fingers</button>
    </div>
  </div>
</div>

<!-- CLIENT OFFER VERIFICATION -->
<div *ngIf="biometricType === 'client-offer'"  class="mx-1">
  <div *ngIf="readerOpen">
    <div>
      <h4 style="margin-left: 2%; margin-top: 2%;">Select Reader :</h4>
      <input id="device" class="form-control" style="width: 95%; margin-left: 2%;" readonly [(ngModel)]="deviceSerial">
    </div>
    <div class="readerDivButtons" style="margin-top: 2%;">
      <button  type="button" class="btn btn-primary me-2" id="refreshList" (click)="getDevice()">
        Refresh List</button>
      <button  type="button" class="btn btn-primary ms-1 me-2" id="capabilities"
        (click)="getDeviceInfo()"> Reader Info</button>

    </div>
    <div *ngIf="deviceInfo" style="margin-left: 2%;">
      <label>Device ID:</label>
      {{deviceSerial}}
      <br>
      <label>Uid Type:</label>
      {{deviceUIDType}}
      <br>
      <label>Device Tech:</label>
      {{deviceTechnology}}
      <br>
      <label>Device Modality:</label>
      {{deviceModality}}
    </div>
  </div>

  <!-- CLIENT VERIFICATION -->
  <div *ngIf="verificationOpen"  class="mx-1">
    <div class="Scores">
      <h5>Scan Quality : <input type="text" id="qualityInputBox" size="15" readonly [(ngModel)]="readQuality"
          style="background-color:#DCDCDC;text-align:center; margin-top: 10px;"></h5>
    </div>
    <div>
      <button  type="button" class="btn btn-primary" [disabled]="verifyDisabled"
        style="margin-left: 40%; margin-top: 1%;" (click)="verifyClientIntermediate()">Verify Finger</button>
    </div>
    &nbsp;
    <div class="content-capture">
      <div *ngFor="let data of sampleData.slice(0,4); let sampleDataIndex = index;" style="margin-left:2%;">
        <label style="margin-top:5px;" [for]="'sampleData' + sampleDataIndex">Finger Data {{ sampleDataIndex + 1 }}
        </label>
        <label sampleDataIndex>: Captured success</label>
      </div>
      &nbsp;
      <div *ngFor="let totFingers of clientEnrolled.slice(0,4); let totalFingersEnrolled = index;">
        <table class="table table-striped table-light table-hover table-sm mb-3 caption-top" style="width: 98%;">
          <tbody class="field-4column-label">
            <tr>
              <td>Finger Already Enrolled</td>
              <td>{{totFingers.fingerName}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      &nbsp;
      <div class="readerDivButtons">
        <button  type="button" class="btn btn-primary me-2" (click)="startCapture()">Start</button>
        <button  type="button" class="btn btn-primary" (click)="closeScanFingers()"> Close
        </button>
      </div>
    </div>
  </div>

  <!-- USER VERIFICATION -->
  <div *ngIf="userVerificationOpen" class="mx-1">
    <div class="Scores">
      <h5>Scan Quality : <input type="text" id="qualityInputBox" size="15" readonly [(ngModel)]="readQuality"
          style="background-color:#DCDCDC;text-align:center; margin-top: 10px;"></h5>
    </div>
    <div>
      <button  type="button" class="btn btn-primary" [disabled]="verifyDisabled"
        style="margin-left: 40%; margin-top: 1%;" (click)="verifyUserIntermediate()">Verify Finger</button>
    </div>
    &nbsp;
    <div class="content-capture">
      <div *ngFor="let data of sampleData.slice(0,4); let sampleDataIndex = index;" style="margin-left:2%;">
        <label style="margin-top:5px;" [for]="'sampleData' + sampleDataIndex">Finger Data {{ sampleDataIndex + 1 }}
        </label>
        <label sampleDataIndex>: Captured success</label>
      </div>
      &nbsp;
      <div *ngFor="let totFingers of userEnrolled.slice(0,4); let totalFingersEnrolled = index;">
        <table class="table table-striped table-light table-hover table-sm mb-3 caption-top" style="width: 98%;">
          <tbody class="field-4column-label">
            <tr>
              <td>Finger Already Enrolled</td>
              <td>{{totFingers.fingerName}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      &nbsp;
      <div class="readerDivButtons">
        <button  type="button" class="btn btn-primary me-2" (click)="startCapture()">Start</button>
        <button [disabled]="!verifiedUser"  type="submit" class="btn btn-primary me-2" (click)="proceed()"> Proceed
        </button>
        <button  type="button" class="btn btn-primary" (click)="closeScanFingers()"> Close
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="signatureOpen" style="height:200px;width: auto;">
    <div style="margin-left:25%;">
      Client Signature
      <select [disabled]="lstAllFingersDisable" style="max-width: 150px; margin-left: 5%;"
        [(ngModel)]="selectedFingerName" (change)="fetchFinger(selectedFingerName)" required>
        <option value=""> --- Select ---</option>
        <option *ngFor="let finger of clientEnrolled;let fingerIndex = index;">{{finger.fingerName}}
        </option>
      </select>
    </div>
    &nbsp;
    <div style="margin-left: 22%;">
      Consultant Signature
      <select [disabled]="lstAllFingersDisable" style="max-width: 150px; margin-left: 2.5%;"
        [(ngModel)]="selectedConsultantFingerName" (change)="fetchConsultantFinger(selectedConsultantFingerName)"
        required>
        <option value=""> --- Select ---</option>
        <option *ngFor="let Userfinger of userEnrolled;let fingerIndex = index;">{{Userfinger.fingerName}}
        </option>
      </select>
    </div>
    &nbsp;
    <div>
      <button  type="button" class="btn btn-primary" style="margin-left: 43.6%;"
        (click)="signDocument(selectedFingerName, selectedConsultantFingerName)">Sign
        Document</button>
    </div>
  </div>

</div>
