<div class="container-sm my-3">
<!-- <div class="card p-2" >
  <div class="card-body p-2" > -->
    <div class="idlight ms-auto me-auto rounded-1 shadow-2-strong py-3 w-50" style="max-width:450px">
      <form  [formGroup]="clientSearchForm"  class="inline" >
        <div class="input-group w-auto justify-content-center align-content-center gap-lg-3 gap-md-1 gap-sm-0 ">
          <label for="idNumber" class="input-group-text border-0">Id Number</label>
          <input mdbValidate mdbInput type="search" class="form-control rounded"
            (input)="isSearchClient = false;"
            placeholder="Enter Id Number" formControlName="idNumber"
            lmsNumbersOnly [allowDecimals]="false"
            autofocus />
          <div class="input-group-text border-0 ">
            <button mdbBtn class="btn btn-primary" type="button" mdbRipple data-mdb-ripple-color="#fafafa" color="primary"
            (click)="getClient()">Filter</button>
          </div>
          <div *ngIf="idNumber!.invalid">
            <mdb-error mdbLabel *ngIf="idNumber?.errors!.notfound" >
              Id Number not found!
            </mdb-error>
            <mdb-error *ngIf="idNumber?.errors!.maxlength || idNumber!.errors?.minlength" >
              Must be a valid Id Number
            </mdb-error>
            <mdb-error *ngIf="idNumber!.errors?.wrongBranch" >
              Incorrect Branch selection!
            </mdb-error>
          </div>
        </div>
      </form>
    </div>
  </div>

    <ng-container *ngIf="isSearchClient">
      <div class="idlight d-flex ms-auto me-auto p-3 bg-info w-75 rounded">
        <div class="container mt-2">
          <div class="row justify-content-center center-section ">
            <table class="table table-striped table-light table-hover table-sm lms-table__grid caption-top">
              <caption>
                Client / Account filter
              </caption>
              <thead class="center-header">
                <tr>
                  <th>Application #</th>
                  <th>Id Number</th>
                  <th>Name </th>
                  <th>Surname</th>
                  <th>Cell Number</th>
                  <th>Status</th>
                  <th>User</th>
                  <th *ngIf="resetLoan"> Manage Reference No.</th>
                  <th> View </th>
                </tr>
              </thead>
              <tbody role="rowgroup" class="mat-mdc-row">
                <tr>
                  <td>{{preAuthorisationModel.id}}</td>
                  <td>{{preAuthorisationModel.idNumber}}</td>
                  <td>{{preAuthorisationModel.firstName}}</td>
                  <td>{{preAuthorisationModel.lastName}}</td>
                  <td>{{preAuthorisationModel.cellNumber}}</td>
                  <td class="mat-mdc-cell ">
                    <ng-container *ngIf="!!preAuthorisationModel.loanStatus">
                    <span  class="rounded-pill d-inline badge"
                    [ngClass]="{'badge-primary': (preAuthorisationModel.loanStatus === 'Active'),
                    'badge-info':(preAuthorisationModel.loanStatus === 'New' || preAuthorisationModel.loanStatus=== 'Pending'),
                    'badge-success':(preAuthorisationModel.loanStatus === 'Submitted'),
                    'badge-dark': (preAuthorisationModel.loanStatus === 'Cancelled' || preAuthorisationModel.loanStatus === 'Rejected'),
                    'badge-secondary': (preAuthorisationModel.loanStatus === 'Disbursed' || preAuthorisationModel.loanStatus === 'NuPay')}">
                    {{preAuthorisationModel.loanStatus}}</span>
                  </ng-container>


                  </td>
                  <!-- <td style="width: 100px;">{{preAuthorisationModel.loanStatus}}</td> -->
                  <td>{{preAuthorisationModel.userName}}</td>
                  <td *ngIf="!resetLoan" style="width: 100px;">
                      <a (click)="viewSearchedClient(preAuthorisationModel)"
                        data-mdb-ripple-color="#ffffff"
                        style="background-color:#3f51b5; color: #fafafa;"
                      class="btn btn-sm btn-primary rounded-1"
                      [textContent]="preAuthorisationModel.status === 'Submitted' ? 'View' : 'Continue'"></a>

                    <!-- <button mat-raised-button class="btn btn-primary rounded-1" mdbRipple type="button" color="primary"
                      [textContent]="preAuthorisationModel.status == 'Submitted' ? 'View' : 'Continue'"
                      (click)="viewSearchedClient(preAuthorisationModel)">
                    </button> -->
                  </td>

                  <td *ngIf="resetLoan">
                    <input #referenceNumber placeholder="Manage Engine Reference No." required>
                  </td>

                  <td *ngIf="resetLoan">
                    <button mat-raised-button class="btn btn-primary rounded-1" mdbRipple type="button" color="primary"
                      (click)="resetLoanApplication(preAuthorisationModel)">Reset
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ng-container>
