import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LMSPageTitleService {
  pageheadWidth: number = 650;
  private _pageName = new BehaviorSubject<string>('');
  private _lmspageTitle: string;
  constructor() { }

  get PageTitle() {
    return this._pageName.asObservable();
  }

  public setPageTitle(pageTitle: string) {
    this._pageName.next(pageTitle);
  }

  get PageTitleText() {
    return this.PageTitle;
  }

  get LMSPageHeadTitle() {
    return this.sslmsPageTitle;
  }

  private sslmsPageTitle
    (someparam = false): Promise<string> {
    return new Promise<string>((resolve) => {
      if (this._lmspageTitle) {
        resolve(this._lmspageTitle);
      } else {
        if (someparam) {
          resolve('Text based on someParam');
        } else {
          this.PageTitle.subscribe(
            pgName => resolve(pgName)
          );
        }
      }
    });
  }
}
