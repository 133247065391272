<app-page-cardhead  [PageName]="'Income '+ payslipModel.month"  [PageHeadWidth]="420"></app-page-cardhead>
<div class="card">
  <div class="card-body my-2 mx-3 p-1">
    <form [formGroup]="payslipForm">
      <table class="table table-striped table-sm shadow-1-strong rounded-1 p-1">
        <tbody class="field-4column-label">
          <tr>
            <td>Pay Date:</td>
            <td>
              <input
                [matDatepicker]="picker"
                [max]="maxDate"
                placeholder="YYYY-MM-DD"
                formControlName="date"
                style="width: 120px;"
                required>
              <!-- (dateChange)="onPayslipDateSelect($event)" -->
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker startView="year"></mat-datepicker>
              <div *ngIf="payslipInput.date.errors?.required && submitted">
                <small class="text-danger">Pay date is required</small>
              </div>
              <div *ngIf="invalidDate">
                <small class="text-danger">{{payDateError}}</small>
              </div>
            </td>
          </tr>
          <tr>
            <td>Nett Income:</td>
            <td>
              <input
                class="form-control"
                placeholder="NETT Income"
                formControlName="nettIncome"
                lmsNumbersOnly
                [allowDecimals]="true"
                [allowSign]="false"
                #nettIncome
                style="width: 120px;"
                [ngClass]="{ 'is-invalid': payslipInput!.nettIncome.invalid }"
                [class.ng-valid]="payslipInput!.nettIncome.valid"
                [class.ng-invalid]="payslipInput!.nettIncome.invalid"
                min="0">
              <div *ngIf="payslipInput!.nettIncome.invalid">
                <div *ngIf="payslipInput!.nettIncome.errors?.required">
                  <small class="text-danger">Income amount is required</small>
                </div>
                <div *ngIf="payslipInput!.nettIncome.errors?.numberOnly">
                  <small class="text-danger">Only numeric values!</small>
                </div>
              </div>
              <!-- <div *ngIf="calculateError"  class="error-label">
                Payslip values are incorrect
              </div> -->
            </td>
          </tr>
          <tr *ngIf="clientType === 'Salaried' || clientType === 'Pensioner'">
            <td>Other Income:</td>
            <td>
              <input
                class="form-control"
                lmsNumbersOnly
                [allowDecimals]="true"
                [allowSign]="false"
                min="0"
                style="width: 120px;"
                placeholder="Other Income"
                [ngClass]="{ 'is-invalid': payslipInput!.otherIncome.invalid }"
                [class.ng-valid]="payslipInput!.nettIncome.valid"
                [class.ng-invalid]="payslipInput!.otherIncome.hasError('numberOnly') || payslipInput!.otherIncome.value! || 0 == 0"
                noSpecial uppercase
                formControlName="otherIncome">
                <div *ngIf="payslipInput!.otherIncome.errors?.numberOnly">
                  <small class="text-danger">Only numeric values!</small>
                </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="center-screen mt-3 mb-1">
        <button
          class="btn btn-primary btn-sm me-3"
          type="button"
          color="primary"
          (click)="closeDialog()">
          Close
        </button>
        <button
          class="btn btn-primary btn-sm"
          type="button"
          (click)="save()"
          color="primary"
          [disabled]="invalidDate ? 'disabled' : null">
          Save
        </button>
      </div>
    </form>
  </div>
</div>
