import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

declare let Fingerprint: any;

@Injectable({
  providedIn: 'root',
})
export class BiometricsSerivce {
  biometricsParameters: any;

  private _sampleData: string[] = [];
  public sampleData$ = new BehaviorSubject<string[]>(this._sampleData);
  public readQuality$ = new BehaviorSubject<string>('');
  public status$ = new BehaviorSubject<string>('');
  public numberReadings$ = new BehaviorSubject<number>(0);
  public acquisitionStarted$ = new BehaviorSubject<boolean>(false);
  public deviceList$ = new BehaviorSubject<string[]>([]);
  currentFormat = Fingerprint.SampleFormat.Intermediate;
  private _devicesList: string[];
  deviceID: string;
  deviceUIDType: string;
  deviceModality: string;
  deviceTechnology: string;
  readerOpen = true;
  acquisitionStarted: boolean;
  deviceInfo = false;
  private _fpReader: any;
  private websdk: any;

  HttpParamsOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor (private http: HttpClient) {
    this._devicesList = [];
    this.deviceUIDType = '';
    this.deviceID = '';
    this.deviceModality = '';
    this.deviceTechnology = '';

    this._fpReader = new Fingerprint.WebApi();
    this._fpReader.onAcquisitionStarted = () => {
      this.onAcquisitionStarted();
    };
    this._fpReader.onDeviceConnected = () => {
      this.onDeviceConnected();
    };
    this._fpReader.onDeviceDisconnected = () => {
      this.onDeviceDisconnected();
    };
    this._fpReader.onConnectionFailed = (e: any) => {
      this.onErrorOccurred(e);
    };
    this._fpReader.onErrorOccurred = (e: any) => {
      this.onErrorOccurred(e);
    };
    this._fpReader.onSamplesAcquired = (s: any) => {
      this.onSamplesAcquired(s);
    };
    this._fpReader.onQualityReported = (e: { quality: any }) => {
      this.onQualityReported(e);
    };

    //this._fpReader = new FingerprintReader();
    // this._fpReader.on("DeviceConnected", this.onDeviceConnected);
    // this._fpReader.on("onAcquisitionStarted", this.onAcquisitionStarted);
    // this._fpReader.on("DeviceDisconnected", this.onDeviceDisconnected);
    // this._fpReader.on("SamplesAcquired", this.onSamplesAcquired);
    // this._fpReader.on("onQualityReported", this.onQualityReported);
    // this._fpReader.on("onErrorOccurred", this.onErrorOccurred);
  }

  public async $onDestroy () {
    await this._fpReader.stopAcquisition();
    // this._fpReader.off("DeviceConnected", this.onDeviceConnected);
    // this._fpReader.off("DeviceDisconnected", this.onDeviceDisconnected);
    // this._fpReader.off("SamplesAcquired", this.onSamplesAcquired);
    // OR

    // alternatively, call this._fpReader.off() to unsubscribe from all events at once.
  }

  private onDeviceConnected = () => {
    console.debug('Device Connected');
    this.status$.next('Place your finger');
    this.readerOpen = true;
    //this.getDeviceInfo();
  };
  private onAcquisitionStarted = () => {
    console.debug('Acquisition Started');
    this.status$.next('Place your finger');
  };
  private onDeviceDisconnected = () => {
    this.acquisitionStarted = false;
    // Detects if device gets disconnected - provides deviceUid of disconnected device
    console.debug('Device disconnected');
    this.status$.next('Device disconnected');
  };
  private onQualityReported = (event: any) => {
    console.debug(`Device[${event.deviceId}] reported: Quality (${event.type}) >> ${event.quality}`);
    // Quality of sample aquired - Function triggered on every sample acquired
    this.readQuality$.next(Fingerprint.QualityCode[event.quality]);
    this.status$.next('Quality Reported');
    if (Fingerprint.QualityCode[event.quality] == 'Good') {
      this.numberReadings$.next(this.numberReadings$.value + 1);
    }
  };
  private onErrorOccurred = (e: any) => {
    this.acquisitionStarted = false;
    console.debug('Error Occurred during capture');
    this.status$.next(`Reader error: ${e}`);
  };
  private onSamplesAcquired = (event: any) => {
    // Sample acquired event triggers this function
    if (this.currentFormat !== event.sampleFormat) {
      this.status$.next('Incompatible format used');
      console.error(
        `Expected format: ${Fingerprint.SampleFormat.Intermediate}, Sample format received: ${event.sampleFormat}`
      );
    }

    console.debug(`Sample ${event.type}-[${event.sampleFormat}]: ${event.samples}`);
    this.status$.next('Finger acquired');
    const samples = JSON.parse(event.samples);
    const finger = samples[0];

    console.debug('sampleAcquired currentFormat >> ', this.currentFormat);
    if (this.currentFormat == Fingerprint.SampleFormat.PngImage) {
      console.debug('processing png...');

      localStorage.setItem('imageSrc', `data:image/png;base64,${Fingerprint.b64UrlTo64(finger)}`);
      const vDiv = document.getElementById('imagediv');
      vDiv!.innerHTML = '';

      const image = document.createElement('img');
      image.id = 'image';
      image.src = localStorage.getItem('imageSrc') || '';

      vDiv!.appendChild(image);
      this.updateData(this.numberReadings$.value - 1, Fingerprint.b64UrlTo64(finger));
    } else if (this.currentFormat == Fingerprint.SampleFormat.Raw) {
      console.debug('processing raw...');
      const decodedDataRAW = JSON.parse(Fingerprint.b64UrlToUtf8(finger));

      localStorage.setItem('raw', Fingerprint.b64UrlTo64(decodedDataRAW.Data));
      const vRaw = document.getElementById('imagediv');
      vRaw!.innerHTML = `<div id="animateText" >RAW Sample Acquired <br>${Date()}</div>`;

      this.updateData(this.numberReadings$.value - 1, Fingerprint.b64UrlTo64(decodedDataRAW.Data));
    } else if (this.currentFormat == Fingerprint.SampleFormat.Compressed) {
      console.debug('processing wsq...');
      const decodedDataWSQ = JSON.parse(Fingerprint.b64UrlToUtf8(finger));

      localStorage.setItem(
        'wsq',
        `data:application/octet-stream;base64,${Fingerprint.b64UrlTo64(decodedDataWSQ.Data)}`
      );

      const vWsq = document.getElementById('imagediv');
      vWsq!.innerHTML = `<div id="animateText" style="display:none">WSQ Sample Acquired <br>${Date()}</div>`;
      this.updateData(this.numberReadings$.value - 1, Fingerprint.b64UrlTo64(decodedDataWSQ.Data));
    } else {
      //intermediate
      //      this.sampleData[this.numberReadings - 1] = Fingerprint.b64UrlTo64(finger.Data);
      this.updateData(this.numberReadings$.value - 1, finger.Data);

      // var vImage = document.getElementById('image');
      // // @ts-ignore
      // vImage.remove();
    }
  };

  updateData (index: number, value: any) {
    if (index < 0) {
      index = 0;
      this.numberReadings$.next(1);
    }

    this._sampleData[index] = value;
    this.sampleData$.next(this._sampleData);
    console.log(`this.sampleData[${index}] :- `, value);
  }

  resetReadings () {
    this._sampleData = [];
    this.sampleData$.next(this._sampleData);
    this.numberReadings$.next(0);
    this.readQuality$.next('');
  }

  getDevices () {
    this._fpReader
      .enumerateDevices()
      .then((devices) => {
        this._devicesList = devices;
        console.debug('FP Readers:\r\n', this._devicesList.join('\r\n '));
        this.deviceList$.next(this._devicesList);
      })
      .catch((reason) => {
        console.error('Device connection failed: ', reason);
        this._devicesList = [];
        this.deviceList$.next(this._devicesList);
      });
  }

  async startAcquisition (resetreading: boolean): Promise<void> {
    /// TODO: Do promise
    if (resetreading) {
      this._sampleData = [];
      this.numberReadings$.next(0);
    }
    this.currentFormat = Fingerprint.SampleFormat.Intermediate;
    console.log('Starting capture with format:', this.currentFormat);
    // return this._fpReader.startAcquisition(this.currentFormat).then(() => {
    //   this.status$.next("Scan your finger");
    // })
    await this._fpReader.startAcquisition(this.currentFormat);
  }

  async stopAcquisition (): Promise<void> {
    /// TODO: Do promise
    this.resetReadings();

    await this._fpReader.stopAcquisition();
  }

  getBiometricsParameters (): any {
    return this.biometricsParameters;
  }

  setBiometricsParameters (data: any) {
    this.biometricsParameters = data;
  }

  enrollClientFinger (data: any) {
    return this.http.post<any>(`${environment.apiBioUrl}/biometric/api/enroll-finger`, data);
  }

  verifyClientFinger (data: any) {
    return this.http.post<any>(`${environment.apiBioUrl}/biometric/api/verify-finger`, data);
  }

  verifyUserFinger (data: any) {
    return this.http.post<any>(`${environment.apiBioUrl}/biometric/user-api/verify-finger`, data);
  }

  enrolledFingersDetails (data: any) {
    return this.http.post<any>(`${environment.apiBioUrl}/biometric/api/enrolledfinger-details`, data);
  }

  enrolledSingleFingersDetails (data: any) {
    return this.http.post<any>(`${environment.apiBioUrl}/biometric/api/enrolledSinglefinger-details`, data);
  }

  enrolledConsultantSingleFingersDetails (data: any) {
    return this.http.post<any>(
      `${environment.apiBioUrl}/biometric/user-api/enrolledConsultantSinglefinger-details`,
      data
    );
  }

  disableFinger (data: any) {
    return this.http.post<any>(`${environment.apiBioUrl}/biometric/api/disable-finger`, data);
  }

  enrollUserFinger (data: any) {
    return this.http.post<any>(`${environment.apiBioUrl}/biometric/user-api/enroll-finger`, data);
  }

  enrolledUserFingersDetails (data: any) {
    return this.http.post<any>(`${environment.apiBioUrl}/biometric/user-api/enrolledfinger-details`, data);
  }

  disableUserFinger (data: any) {
    return this.http.post<any>(`${environment.apiBioUrl}/biometric/user-api/disable-finger`, data);
  }

  disableAllClientFingers (data: any) {
    return this.http.post<any>(`${environment.apiBioUrl}/biometric/api/disable-allFingers`, data);
  }

  disableAllUserFingers (data: any) {
    return this.http.post<any>(`${environment.apiBioUrl}/biometric/user-api/disable-allFingers`, data);
  }

  saveBiometrics (): Observable<any> {
    return this.http.get<any>(`${environment.apiBioUrl}/biometric/api/is-available`);
  }

  skipBiometrics (data: any): any {
    return this.http.post<any>(`${environment.apiBioUrl}/biometric/api/skip-biometrics`, data).pipe(
      map((responseData: Record<string, any>) => {
        return responseData;
      })
    );
  }

  updateBranchNameOrFaisRep (data: any) {
    return this.http.post<any>(`${environment.apiBioUrl}/biometric/user-api/updateBranchNameOrFaisRep`, data).pipe(
      map((responseData: Record<string, any>) => {
        return responseData;
      })
    );
  }
}
