<div class="d-flex align-content-center justify-content-center">
  <div class="center-screen">
    <form [formGroup]="lexisNexisReportForm">
 
    <table id="lexisNexisSearch" style="width:800px; border: 1px; border-width: 1px;">
      <tbody>
        <tr>
          <td>Lexis Nexis by date range</td>
          <td>
            <mat-form-field class="input-group-sm mb-1">
              <mat-label class="small">Enter a date range</mat-label>
              <mat-date-range-input [rangePicker]="picker1">

                <input matStartDate
                  placeholder="Start date" 
                  formControlName="startDate"
                  [(ngModel)]="startDate">

                <input matEndDate
                  placeholder="End date" 
                  formControlName="endDate" 
                  [(ngModel)]="endDate">

              </mat-date-range-input>
              <mat-hint>YYYY-MM-DD – YYYY-MM-DD</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-date-range-picker #picker1></mat-date-range-picker>

            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td colspan="3" align="center">
            <button mat-raised-button class="btn btn-primary btn-rounded me-2" color="primary" (click)="search()">Search
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    </form>
  </div>
</div>
<br/>

<!-- <section  *ngIf="lstLexisNexis.length > 0">  -->
  <div class="d-flex align-content-center justify-content-center">
    <div class="center-screen table-responsive" #TABLE> 
      <table mat-table *ngIf="lstLexisNexis.length > 0"
         #table 
        class="table table-sm table-striped table-borderless table-primary ms-auto me-auto table-center-header shadow-2-strong rounded-1 p-1 w-75" 
        style="width:1200px;">
        <caption>Search Results</caption>
      
        <thead>
          <tr>
            <th scope="col" >Full name</th>
            <th>Id number</th>
            
            <th scope="col" style="width:150px;">No result found</th>
            <th scope="col" style="width:150px;">Sanctions List Confirmed</th>
            <th scope="col" style="width:150px;">Sanctions List Possible</th>
            <th scope="col" style="width:150px;">Pep or Pip</th>
            <th >Query Date</th>
          </tr>
        </thead>
        <tbody class="center-fields table-bordered  text-bg-light table-light  table-hover table-group-divider ">
          <tr *ngFor="let lexis of lstLexisNexis | paginate: { itemsPerPage: pageSize, currentPage: pager.currentPage };">
            <td style="width:450px;">{{lexis.firstName}} {{lexis.lastName}}</td>
            <td style="width:150px; text-align: center;">{{lexis.idNumber}}</td>
            <td style="width:150px; text-align: center;">{{lexis.noResultsFound}}</td>
            <td style="width:150px; text-align: center;">{{lexis.sanctionsListConfirmed}}</td>
            <td style="width:150px; text-align: center;">{{lexis.sanctionsListPossible}}</td>
            <td style="width:150px; text-align: center;">{{lexis.pepOrPip}}</td>
            <td style="width:150px;">{{lexis.queryDateTime | date}}</td>
          </tr>
        </tbody>
      </table>
      <br />  

      <div class="lms-pager my-2">
        <div *ngIf="pager.pages && pager.pages?.length && pager.pages?.length > 1" class="btn-toolbar" role="toolbar" aria-label="LMS Pager group">
          <div class="btn-group btn-group-sm" role="group" aria-label="Move Previous group">
  
            <button type="button" class="btn btn-primary  rounded-start" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5; margin: 0;" (click)="setPage(1)" [disabled]="pager.currentPage === 1">First</button>
            <button type="button" class="btn btn-primary  rounded-0" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5; margin: 0;"(click)="setPage(pager.currentPage - 1)" [disabled]="pager.currentPage === 1">Previous</button>
          </div>
          <div class="btn-group btn-group-sm" role="group" aria-label="PageNumbers">
            <button *ngFor="let page of pager.pages" type="button" data-mdb-ripple-color="#7a7676" style="background-color:#edf2fa; margin: 0; color: #7a7676;" [ngClass]="{active:pager.currentPage === page}" (click)="setPage(page)" class="btn btn-outline-secondary page-item number-item  rounded-0">{{page}}</button>
          </div>
          <div class="btn-group btn-group-sm" role="group" aria-label="Move Next group">
            <button type="button" class="btn btn-primary rounded-0" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5; margin: 0;" (click)="setPage(pager.currentPage + 1)" [disabled]="pager.currentPage === pager.totalPages">Next</button>
            <button type="button" class="btn btn-primary rounded-end" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5;margin: 0;" (click)="setPage(pager.totalPages)" [disabled]="pager.currentPage === pager.totalPages">Last</button>
          </div>
        </div>
      </div>
    </div>
  </div> 
<!-- </section> -->

<div *ngIf="lstLexisNexis.length > 0">
  <div class="d-flex align-content-center justify-content-center">
    <button mat-raised-button class="btn btn-primary btn-rounded me-2" color="primary" (click)="exportToExcel()">Export
      to Excel</button>
  </div>