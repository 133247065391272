/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive, forwardRef, HostListener } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR  } from '@angular/forms';

@Directive({
  selector: '[lmsNoSpecials]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LMS_NoSpecialsDirective),
    }],
  standalone: true,
})
export class LMS_NoSpecialsDirective extends DefaultValueAccessor {
  val= "" // this is the updated value that the class accesses

  /**
  * Event handler for host's paste event
  * @param e
  */
  @HostListener('paste', ['$event']) onPaste(e:ClipboardEvent) {
    // get and validate data from clipboard
    const clipvalue = e!.clipboardData?.getData('text/plain');
    this.validateValue(clipvalue!);
    e.preventDefault();
  }

  validateValue(value: string): void {
    const newVal = value?.replace(/[^\w\s ]/gi, '').trim();// Remove non word characters;
    this.val = newVal;
  }

  override onChange: any = () => {
    this.validateValue(this.val);
    this.writeValue(this.val);
  }

  // // this method sets the value programmatically
  // writeValue(value: any){
  //   this.value = value
  // }

  // // upon UI element value changes, this method gets triggered
  // registerOnChange(fn: any){
  //   this.onChange = fn
  // }

  // // upon touching the element, this method gets triggered
  // registerOnTouched(fn: any){
  //   this.onTouch = fn
  // }
}
