export class RegionModel {
  id: number;
  name: string;
  regionNumber: string;
  areas: Array<AreaModel>;
}

export class AreaModel {
  id: number;
  name: string;
  region? = {} as RegionModel;
}
