import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { AppUtilityService } from "src/app/app-utility.service";
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MatButtonModule } from '@angular/material/button';
import { PageCardheadComponent } from '../../ui/pageheader-card/pageheader-card.component';
//import { ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-lexisnexis-dialog',
    templateUrl: './lexisnexis-dialog.component.html',
    styleUrls: ['./lexisnexis-dialog.component.css'],
    standalone: true,
    imports: [PageCardheadComponent, MatButtonModule, MdbRippleModule]
})
export class LexisnexisDialogComponent implements OnInit {
  title: string;
  message: string;
  showClose: boolean;
  isErrorOccured: boolean;
  lexisnexisForm: FormGroup;

  constructor(public global_utility: AppUtilityService,
    public modalRef: MdbModalRef<LexisnexisDialogComponent>) {
    // this.title = data.title;
    // this.message = data.message;
    // this.showClose = data.showClose;
    // this.isErrorOccured = true;
  }

  ngOnInit(): void { }

  close(): void {
    this.modalRef.close(true);
  }

}


export class ConfirmDialogModel {
  constructor(
    public title: string,
    public message: string,
    public showClose: boolean,
    public isErrorOccured: boolean,
    public autoConfirm: false
  ) { }

}






