import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/core/services/lmsuser.service';
import { LMSNote } from 'src/app/core/models/lms-notes.model';
import { ClientnoteService } from '../clientnote.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule, DatePipe } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PageCardheadComponent } from 'src/app/shared/ui/pageheader-card/pageheader-card.component';

@Component({
  selector: 'lms-client-notes',
  templateUrl: './client-notes.component.html',
  styleUrls: ['./client-notes.component.css'],
  standalone: true,
  imports:[
    CommonModule,
    ReactiveFormsModule,
    PageCardheadComponent,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    DatePipe,
  ]
})
export class ClientNotesComponent implements OnInit {
  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    console.log('event:', event);
    const cou = this.table_data_source.length;
    event.returnValue = cou;
  }
  private _clientid: number;
  public isNotesOpened = false;
  @Output() clientNoteCount = new EventEmitter<number>();
  table_config: any = {
    columns: [
      {
        key: 'createdBy',
        heading: 'User',
        hideCol: false
      },
      {
        key: 'createdOn',
        heading: 'Date',
        datecol: false
      },
      {
        key: 'noteStr',
        heading: 'Note',
        hideCol: false
      },
    ],
    primary_key_set: ['time'],
    table_data_changer: new Subject<any>(),
    ediTable: {
      add: false,
      edit: false
    }
  };
  clientNotes: LMSNote[] = [];
  savingNote = false;

  clientNoteForm: FormGroup;

  constructor(private fb: FormBuilder,
    private clientNoteService:ClientnoteService,
    private userservice: UserService,
    private dialogRef: MatDialogRef<ClientNotesComponent>,
    @Inject(MAT_DIALOG_DATA)  data: any) {

    this.clientNoteForm = this.fb.group({
      'clientId': [0, [Validators.required]],
      'noteStr': ['', [Validators.required]],
      'userName': ['', [Validators.required]],
    });

    this.clientNotes = data?.existingNotes;
    this.clientid = data?.clientid;
  }

  ngOnInit() {
    this.setDisplayedColumns(this.table_config.columns);
    this.dialogRef.beforeClosed().subscribe(_ => {
      const cou = this.table_data_source.data.length;
      this.dialogRef.close(cou);
    });
  }

  displayed_columns!: string[];
  table_data_source!: any;
  setDisplayedColumns(column_config: any[]) {
    const col_count = column_config.length;
    const columns_to_display: any[] = [];
    for (let i = 0; i < col_count; i++) {
      if (!column_config[i].hideCol) {
        const col_config = column_config[i];
        columns_to_display.push(col_config.key);
      }
    }
    this.displayed_columns = columns_to_display;
  }
  get noteStr(){
    return this.clientNoteForm.controls['noteStr'];
  }

  public trackByFn(index, item) {
    if (!item) return null;
    return item.id;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  addClientNote() {
    this.savingNote = true;
    if (this.clientNoteForm.invalid){
      return;
    }
    this.clientNoteService.addClientNote(this.clientNoteForm.value).subscribe((noteres: any) => {
      this.clientNotes = noteres;
      this.table_data_source = new MatTableDataSource<any>(this.clientNotes);
      this.resetNotesForm();

      this.clientNoteCount.emit(this.clientNotes.length);
      this.savingNote = false;
    });
  }

  private resetNotesForm() {
    this.clientNoteForm.reset();
    this.clientNoteForm.get('clientId')?.patchValue(this.clientid);
    this.clientNoteForm.get('userName')?.patchValue(this.userservice.userLoginName);
    this.clientNoteForm.updateValueAndValidity();
  }

  openNotes() {
    this.isNotesOpened = true;
    return this.isNotesOpened;
  }

  closeHideNotes() {
    this.isNotesOpened = false;
    return this.isNotesOpened;
  }

  public get clientid(): number {
    return this._clientid;
  }

  @Input() set clientid(value: number | 0) {
    if (value && value > 0) {
      this._clientid = value;
      this.clientNoteService.getClientNotes(this.clientid).subscribe(
        (cnote: any) => {
          this.clientNotes = cnote;
          this.table_data_source = new MatTableDataSource<any>(this.clientNotes);
          this.resetNotesForm();
          this.clientNoteCount.emit(cnote.length);
        }
      );
    }
  }
}
