<div matDialogTitle class="dialog-title">
  <app-page-cardhead [PageName]="'Customer Notes'" align="end" [PageHeadWidth]="400" cdkDrag
    cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle></app-page-cardhead>
  <button mat-icon-button aria-label="close dialog" mat-dialog-close (close)="closeDialog()">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
</div>
<form [formGroup]="clientNoteForm">
  <div class="card m-2">
    <div class="card-body p-1">
      <div fxFlex class="note-capture-holder mat-elevation-z2" style="max-height: 700px">
        <mat-toolbar class="mat-elevation-z4">
          <table class="container-fluid input-container px-1 d-flex align-content-end justify-content-end">
            <tbody>
                <td>
                  <input formControlName="noteStr"  placeholder="Client note" required>
                </td>
                <td>
                  <button [disabled]="savingNote" mat-raised-button class="btn btn-primary btn-rounded mx-1" mdbRipple color="primary" (click)="addClientNote()">
                    Add Note</button>
                </td>
            </tbody>
            <!-- <div *ngIf="clientNoteForm.invalid && noteStr.errors?.required" class="error-label">
              <small class="text-danger">
                Note is required before saving
              </small>
            </div> -->
          </table>
        </mat-toolbar>
      <div fxLayout="row" class="ms-auto my-1 p-md-2 p-lg-3 p-1" fxLayoutAlign="start start" fxLayoutGap="10px">
        <table mat-table [dataSource]="table_data_source!" class="clientnote-list-wrapping table table-striped  table-hover table-sm caption-top" style="max-height: 500px" >
          <ng-container *ngFor="let column of table_config.columns; index as i" [matColumnDef]="column.key">
            <th mat-header-cell *matHeaderCellDef>{{column.heading}}</th>
            <td mat-cell *matCellDef="let table_row">
              <ng-container *ngIf="column.key === 'createdBy'">
                <span class="text-emphasis text-start">
                  <div class="font-weight-bold"></div>{{table_row[column.key]}}</span><br>
                  <span class="ms-auto text-emphasis text-end">
                    <small class="ms-auto text-muted text-end">{{table_row['createdOn'] | date: 'yyyy-MM-dd HH:mm:ss'}}</small>
                  </span>
              </ng-container>
              <!-- <ng-container *ngIf="column.key === 'createdOn'">
                <span class="text-emphasis text-end">
                  <small class="ms-auto text-muted text-end">{{table_row['createdOn'] | date: 'yyyy-MM-dd HH:mm:ss'}}</small>
                </span>
              </ng-container> -->
              <ng-container *ngIf="column.key === 'noteStr'">
                <div class="d-flex thisnote text-center note note-info">
                  <span class="list-note-item">
                    <div class="font-weight-bold">Note: </div>
                    {{table_row[column.key]}}
                  </span>
                </div>
              </ng-container>
            </td>
            </ng-container>

            <!-- <tr mat-header-row *matHeaderRowDef="displayed_columns" class="table-center-header"></tr> -->
            <tr mat-row *matRowDef="let row; columns: displayed_columns; let i = index;">
        </table>
      </div>
        <!-- <mat-list class="clientnote-list-wrapping">
          <mat-list-item *ngFor="let cnote of clientNotes;">
              <div class="d-flex thisnote text-center note note-info">
                <span class="text-emphasis text-start">{{cnote.createdBy}}</span>
                <small class="ms-auto text-muted text-end">{{cnote.createdOn | date: 'yyyy-MM-dd HH:mm:ss'}}</small>
                <span class="list-note-item">{{ cnote.noteStr }}</span>
              </div>
          </mat-list-item>
        </mat-list> -->
      </div>
    </div>
      <!-- <div class="form-group">
        <button mat-raised-button type="button" class="me-2" color="primary" (click)="closeDialog()"> Close </button>
      </div> -->
  </div>
</form>
