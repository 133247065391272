import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgFor } from '@angular/common';
import { PageCardheadComponent } from '../shared/ui/pageheader-card/pageheader-card.component';

@Component({
    selector: 'app-offer-audit',
    templateUrl: './offer-audit.component.html',
    styleUrls: ['./offer-audit.component.css'],
    standalone: true,
    imports: [PageCardheadComponent, NgFor, MatButtonModule]
})
export class OfferAuditComponent implements OnInit {
  offersList: any;
  delfinOffer: any;
  clmsOffer: any;

  lstAudit: Array<any> = [];
  readOnly = false;
  constructor(
    public dialogRef: MatDialogRef<OfferAuditComponent>,
    @Inject(MAT_DIALOG_DATA) data:any
    //private clientSearch: ClientDetailsService
  ) {
      this.lstAudit = data.offersList;
      this.readOnly = data?.isReadOnly || false;
      // this.delfinOffer = data.offersList.newData;
      // this.clmsOffer = data.offersList.previousData;
    }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
   }
}
