/* eslint-disable no-empty-pattern */
/* eslint-disable import/exports-last */
import { Injectable, InjectionToken } from "@angular/core";

//import { Observable } from "rxjs";
//import { of } from "rxjs/internal/observable/of";
export declare type LMSCONSTANTS = [
  'PAYDATES',

];
export declare type LMSPairs = {
  value: string;
  description: string;
};

export declare const LMS_OPTGROUP: InjectionToken<LMSPairs[]>;
export declare const LMS_CONSTPAIRS: LMSPairs[];

@Injectable()
export class LMSConstantsService {
  //private AppSettings: { [key: string]: any; } = { };

// eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(){ }

  getLMSConstant(constant:string): LMSPairs[] | undefined  {
    if(constant){
      const lmspair = this.LMSConstants[constant]
      return lmspair;
    }

    return [];
  }

  LMSConstants:{ [key: string]: LMSPairs[] } = {

  PAYDATES: [] = [
      { value: "1", description: "1" }, { value: "2", description: "2" }, { value: "3", description: "3" },
      { value: "4", description: "4" }, { value: "5", description: "5" }, { value: "6", description: "6" },
      { value: "7", description: "7" }, { value: "8", description: "8" }, { value: "9", description: "9" },
      { value: "10", description: "10" }, { value: "11", description: "11" }, { value: "12", description: "12" },
      { value: "13", description: "13" }, { value: "14", description: "14" }, { value: "15", description: "15" },
      { value: "16", description: "16" }, { value: "17", description: "17" }, { value: "18", description: "18" },
      { value: "19", description: "19" }, { value: "20", description: "20" }, { value: "21", description: "21" },
      { value: "22", description: "22" }, { value: "23", description: "23" }, { value: "24", description: "24" },
      { value: "25", description: "25" }, { value: "26", description: "26" }, { value: "27", description: "27" },
      { value: "28", description: "28" }, { value: "29", description: "29" }, { value: "30", description: "30" }
    ],

    YESNO:[] = [
      { value: "Yes", description: "Yes" },
      { value: "No", description: "No" }
    ],

    MYBENEFIT_TIER:[] = [
      { value: "1", description: "Document Tier 1" },
      { value: "2", description: "Document Tier 2" },
      { value: "3", description: "Document Tier 3" },
    ],

    PAYDAYOFWEEK:[] = [
      { value: "1", description: "Monday" },
      { value: "2", description: "Tuesday" },
      { value: "3", description: "Wednesday" },
      { value: "4", description: "Thursday" },
      { value: "5", description: "Friday" },
      { value: "6", description: "Saturday" },
      { value: "7", description: "Sunday" }
    ],

    CLIENTTYPES:[] = [
      { value: 'Salaried', description: 'Salaried' },
      { value: 'SASSA', description: 'SASSA' },
      { value: 'Pensioner', description: 'Pensioner' }
    ],

    PAYMETHODS:[] = [
      { value: "Cash", description: "Cash" },
      { value: "Bank Transfer", description: "Bank Transfer" },
      { value: "Cheque", description: "Cheque" }
    ],

    WAGEFREQUENCY: []=
      [
        { value: "Monthly", description: "Monthly" },
        { value: "Weekly", description: "Weekly" },
        { value: "Fortnightly", description: "Fortnightly" }
      ],

    SHIFTINGRULES:[] = [
      { value: "No Shifting", description: "No Shifting" },
      { value: "Before Weekend", description: "Before weekend" },
      { value: "After Weekend", description: "After weekend" }
    ],

    INTERNALEXCLUDE_ACTIONS:[] = [
      { value: "PAID_OFF", description: "Paid Off" },
      { value: "CONSOLIDATE", description: "To be consolidated" },
      { value: "ALREADY_ON_COMPUSCAN", description: "Already on Compuscan" }
    ],

    SOURCEOFWEALTH:[] = [
      { value: "None", description: "None" },
      { value: "Winnings/lottery", description: "Winnings/lottery" },
      { value: "Savings/Investments", description: "Savings/Investments" },
      { value: "Inheritance", description: "Inheritance" },
      { value: "Sale of property", description: "Sale of property" },
      { value: "Business ownership", description: "Business ownership" },
      { value: "Related Royal Family", description: "Related Royal Family" },
      { value: "Other", description: "Other" },
    ],

    ADDITIONALINCOME:[] = [
      { value: "None", description: "None" },
      { value: "Rentals", description: "Rentals" },
      { value: "Maintanence", description: "Maintanence" },
      { value: "Bonus", description: "Bonus" },
      { value: "Dividends", description: "Dividends" },
      { value: "Royalties", description: "Royalties" },
      { value: "Other", description: "Other" }

    ],

    INDUSTRYTYPE:[] = [
      { value: "Education", description: "Education" },
      { value: "Mining", description: "Mining" },
      { value: "Engineering and Manufacturing", description: "Engineering and Manufacturing" },
      { value: "Finance and Accounting", description: "Finance and Accounting" },
      { value: "Sales and Marketing", description: "Sales and Marketing" },
      { value: "Technology", description: "Technology" },
      { value: "Agriculture", description: "Agriculture" },
      { value: "Retail", description: "Retail" },
      { value: "Procurement and supply chain", description: "Procurement and supply chain" },
      { value: "Other", description: "Other" },
    ],

    EMPLOYMENTTYPE:[] = [
      { value: "Permanent", description: "Permanent" },
      { value: "Contract", description: "Contract" },
      { value: "Temporary", description: "Temporary" },
      { value: "Unemployed", description: "Unemployed" },
      { value: "Retired", description: "Retired" }
    ],

    GRANTTYPE:[] = [
      { value: "Disability", description: "Disability" },
      { value: "Old age pension", description: "Old age pension" }
      // REMOVED - JIRA-435
      // { value: "Child support", description: "Child support" },
      // { value: "Foster care", description: "Foster care" }
    ],

    GRANTCATEGORY:[] = [
      { value: "Temporary", description: "Temporary" },
      { value: "Permanent", description: "Permanent" }
      // REMOVED - JIRA-435
      // { value: "Child support", description: "Child support" },
      // { value: "Foster care", description: "Foster care" }
    ],

    MARITALSTATUS:[] = [
      { value: "Single", description: "Single" },
      { value: "Divorced", description: "Divorced" },
      { value: "Separated", description: "Separated" },
      { value: "Married in community", description: "Married in community" },
      { value: "Married out of community", description: "Married out of community" },
      { value: "Widowed", description: "Widowed" }
    ],

    NATIONALITY:[] = [
      { value: "South Africa", description: "South Africa" },
      { value: "OTHER", description: "Other country" }
    ],

    PROVINCES:[] = [
      { value: "Gauteng", description: "Gauteng" },
      { value: "KwaZulu-Natal", description: "KwaZulu-Natal" },
      { value: "Free-State", description: "Free State" },
      { value: "Eastern-cape", description: "Eastern Cape" },
      { value: "Limpopo", description: "Limpopo" },
      { value: "Mpumalanga", description: "Mpumalanga" },
      { value: "North West", description: "North West" },
      { value: "Northern-Cape", description: "Northern Cape" },
      { value: "Western-Cape", description: "Western Cape" }
    ],

    PARAMETER_TYPES:[] = [
      { value: "COUNTRY", description: "Country" },
      { value: "PRODUCT", description: "Product" },
      { value: "CUSTOMER_TYPE", description: "Customer Type" },
      { value: "EMPLOYMENT_STATUS", description: "Employment Status" },
      { value: "RANGE", description: "Range" },
      { value: "WEIGHTING", description: "Weighting" },
    ],

    TITLES:[] = [
      { value: "Mr", description: "Mr" },
      { value: "Mrs", description: "Mrs" },
      { value: "Ms", description: "Ms" },
      { value: "Me", description: "Me" },
      { value: "Dr", description: "Dr" },
      { value: "Prof", description: "Prof" },
      { value: "Rev", description: "Rev" },
      { value: "Justice", description: "Justice" }
    ],

    CONTACTPREFERENCES:[] = [
      { value: "None", description: "None" },
      { value: "SMS", description: "SMS" },
      { value: "Email", description: "Email" },
      { value: "Telephone", description: "Telephone" },
      { value: "Post", description: "Post" }
    ],


    RELATIONSHIP:[] = [
      { value: "Son", description: "Son" },
      { value: "Daughter", description: "Daughter" },
      { value: "Brother", description: "Brother" },
      { value: "Sister", description: "Sister" },
      { value: "Cousin", description: "Cousin" },
      { value: "Niece", description: "Niece" },
      { value: "Father_in_law", description: "Father-in-law" },
      { value: "Mother_in_law", description: "Mother-in-law" },
      { value: "Nephew", description: "Nephew" },
      { value: "Grandmother", description: "Grandmother" },
      { value: "Grandfather", description: "Grandfather" },
      { value: "Father", description: "Father" },
      { value: "Mother", description: "Mother" },
      { value: "Spouse", description: "Spouse" },
      { value: "Friend", description: "Friend" },
      { value: "Partner", description: "Partner" }
    ],

    AREAS:[] = [
      { value: "Gauteng", description: "Gauteng" },
      { value: "KwaZulu-Natal", description: "KwaZulu-Natal" },
      { value: "Free-State", description: "Free State" },
      { value: "Eastern-cape", description: "Eastern Cape" },
      { value: "Limpopo", description: "Limpopo" },
      { value: "Mpumalanga", description: "Mpumalanga" },
      { value: "North West", description: "North West" },
      { value: "Northern-Cape", description: "Northern Cape" },
      { value: "Western-Cape", description: "Western Cape" }
    ],

    COMPLIANCENATIONALITY:[] = [
      { value: "ZA", description: "South Africa" },
      { value: "BW", description: "Botswana" },
      { value: "LS", description: "Lesotho" },
      { value: "MZ", description: "Mozambique" },
      { value: "NA", description: "Namibia" },
      { value: "SZ", description: "Swaziland" },
      { value: "SW", description: "Zimbabwe" },
      { value: "OTHER", description: "Other country" }
    ],

    COMPLIANCEPROVINCES:[] = [
      { value: "Gauteng", description: "Gauteng" },
      { value: "KwaZulu-Natal", description: "KwaZulu-Natal" },
      { value: "Free-State", description: "Free State" },
      { value: "Eastern-cape", description: "Eastern Cape" },
      { value: "Limpopo", description: "Limpopo" },
      { value: "Mpumalanga", description: "Mpumalanga" },
      { value: "North West", description: "North West" },
      { value: "Northern-Cape", description: "Northern Cape" },
      { value: "Western-Cape", description: "Western Cape" }
    ],


    COMPLIANCEMARITALSTATUS: [] = [
      { value: "Single", description: "Single" },
      { value: "Divorced", description: "Divorced" },
      { value: "Separated", description: "Separated" },
      { value: "Married in community", description: "Married in community" },
      { value: "Married out of community", description: "Married out of community" },
      { value: "Widowed", description: "Widowed" }
    ],

    ALLFINGERS: [] = [
      { value: "NO_FINGER", description: "NO FINGER" },
      { value: "LEFT_THUMB", description: "LEFT THUMB" },
      { value: "LEFT_INDEX", description: "LEFT INDEX" },
      { value: "LEFT_MIDDLE", description: "LEFT MIDDLE" },
      { value: "LEFT_RING", description: "LEFT RING" },
      { value: "LEFT_PINKY", description: "LEFT PINKY" },
      { value: "RIGHT_THUMB", description: "RIGHT THUMB" },
      { value: "RIGHT_INDEX", description: "RIGHT INDEX" },
      { value: "RIGHT_MIDDLE", description: "RIGHT MIDDLE" },
      { value: "RIGHT_RING", description: "RIGHT RING" },
      { value: "RIGHT_PINKY", description: "RIGHT PINKY" },
    ],

    SKIPBIOMETRICSREASONS:[] = [
      { value: " Client Disabled", description: " Client Disabled" },
      { value: "Fingers Unreadable", description: "Fingers Unreadable" },
    ],

    DISABLECLIENTFINGERS:[] = [
      { value: "Client lost his finger", description: "Client lost his finger" },
      { value: "Client damaged their finger", description: "Client damaged their finger" },
      { value: "Biometrics have faded", description: "Biometrics have faded" },
    ],


    LOANSTATUS:[] = [
      { value: "New", description: "New loans" },
      { value: "Pending", description: "Pending loans" },
      { value: "Active", description: "Active loans" },
      { value: "Submitted", description: "Submitted loans" },
      { value: "Disbursed", description: "Disbursed loans" },
      { value: "Rejected", description: "Rejected loans" },
      { value: "Cancelled", description: "Cancelled loans" },
      { value: "Compliance", description: "Loans at Compliance" }
    ],

    COMPLIANCEREJECTREASONS:[] = [
      { value: "Positive Sanction match", description: "Positive Sanction match" },
      { value: "Positive PIP/PEP match - Too High Risk (Management Decision)", description: "Positive PIP/PEP match - Too High Risk (Management Decision)" }
    ],

    COMPLIANCEAPPROVEREASONS:[] = [
      { value: "False positive", description: "False positive" },
      { value: "Postive PIP/PEP match - proceed subject to High-Risk Procedure", description: "Postive PIP/PEP match - proceed subject to High-Risk Procedure" }
    ],

    GENDERS:[] = [
      {value: "Male", description: "Male"},
      {value: "Female", description: "Female"}
    ]
  }
}
