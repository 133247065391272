export class LoanDocModel {
  file: any;
  idNumber: string;
  branchNumber: string;
 // action: string = 'upload';
 // selectedLoan: string;
 // checklist: any = {};
  quoteId: string;
  loanReference?: string;
  contentType: string;
  originalFileName: string;
  userName: any;
  documentDate: string;
  documentType: string;
  customerId: string;
  loanId: string;
  documentContents: any;
  journalComment?: string;
 //   private boolean delete;
  firstName?: string;
  lastName?: string;
  docId: any;
  documentName: string;
  applicationId: number;
  docCheckListId?: number;

  isConfirmedDocScanningDetails: boolean = false;
}

