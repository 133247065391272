import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'upperCaseSearchList',
    standalone: true
})
export class UpperCaseSearchListPipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

  /**
   * Pipe filters the list of elements based on the search text provided
   *
   * @param items list of elements to search in
   * @param itemkey column in list of elements to search
   * @param searchText search string
   * @returns list of elements filtered by search text or []
   */
  transform(items: any[], searchText: string, itemkey:string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }

    searchText = searchText.toLocaleUpperCase();

    if(!itemkey){
      return items.filter(it => {
        return it.toLocaleUpperCase().includes(searchText);
      });
    }

    return items.filter(it => {return it[itemkey].toLocaleUpperCase().includes(searchText);
    });
  }
}
