import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { PostCodeModel } from '../models/postcodes.model';

@Injectable({
  providedIn: 'root'
})
export class PostcodesService {
  postcodes_apiURL = `http://localhost:3000/postcodes?STR-CODE=`;
  postcodesSuburbs_apiURL = `http://localhost:3000/postcodes?SUBURB=`;
  Allpostcodes_apiURL = `http://localhost:3000/postcodes`;
  constructor(private httpClient: HttpClient) {

  }

  data: PostCodeModel[] = [];

  private _prevSearchCode = '';
  private _prevSuburb = '';

  getAll() {
    return this.data.length ? of(this.data) :
     this.httpClient.get<any>(this.Allpostcodes_apiURL).pipe(
      map(data => {
        this.data = data as PostCodeModel[];
        return this.data;
      })
    );
  }

  getName(): Observable<PostCodeModel[]> {
    return this.httpClient.get<any>(this.Allpostcodes_apiURL).pipe(
      map(data => data._APPL as PostCodeModel[])
    );
  }

  getPostcode(strcode: string) {
    return (this._prevSearchCode === strcode && this.data.length) ? of(this.data) :
      this.httpClient.get<any>(`${this.postcodes_apiURL}${strcode}`).pipe(
        map(data => {
          this.data = data._APPL as PostCodeModel[];
          return this.data;
        })
      );
    /*
    http://localhost:3000/postcodes?STR-CODE=1724
    */
  }

  getPostcodeSuburb(suburb: string) {
    return (this._prevSuburb === suburb && this.data.length) ? of(this.data) :
      this.httpClient.get<any>(`${this.postcodesSuburbs_apiURL}${suburb}`).pipe(
        map(data => {
          this.data = data._APPL as PostCodeModel[];
          return this.data;
        })
      );
    /*
    http://localhost:3000/postcodes?SUBURB=CLUBVIEW
    */
  }

}
