import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatSliderModule } from "@angular/material/slider";
import { MatTableModule } from "@angular/material/table";
import { LMSSharedUiModule } from "@lmsSharedUI";
import { NgxPaginationModule } from "ngx-pagination";

import { BasicEmployersSearchComponent } from "./basic-employer-form/basic-employer-form.component";
import { UpdateEmployerComponent } from "./update-employer/update-employer.component";

// export const routes:Routes = [
//   { path: '',pathMatch:'prefix', redirectTo:'updateEmployers' },
//   //{ path: 'add-branch-employer', component: AddContactComponent },
//   { path: 'updateEmployers', component: UpdateEmployerComponent }
//   //{ path: 'edit-branch-employer', component: EditContactComponent },
//   //{ path: 'view-branch-employers', component: ViewContactsComponent },
//   //{ path: 'view-branch-employer', component: ViewContactComponent }
// ];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        MatButtonModule,
        MatTableModule,
        MatInputModule,
        MatCardModule,
        MatSliderModule,
        MatRadioModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatAutocompleteModule,
        BasicEmployersSearchComponent,
        // RouterModule.forChild(routes)
        LMSSharedUiModule,
        UpdateEmployerComponent
    ],
    // exports: [
    //     UpdateEmployerComponent
    // ]
})
export class BranchEmployersModule { }
