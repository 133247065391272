import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AreaModel, RegionModel } from 'src/app/core/models/region.model';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { DisableControlDirective } from '../../../shared/directives/disableControl.directive';
import { PageCardheadComponent } from '../../../shared/ui/pageheader-card/pageheader-card.component';

@Component({
    selector: 'app-area-add',
    templateUrl: './area-add.component.html',
    styleUrls: ['./area-add.component.scss'],
    standalone: true,
    imports: [PageCardheadComponent, FormsModule, ReactiveFormsModule,  NgIf, MatButtonModule, MdbRippleModule]
})
export class AreaAddComponent {
  areaForm: FormGroup;
  submitted: boolean = false;
  area: any;
  areaModel: AreaModel;
  lstRegions: RegionModel[];
  selectedRegion: RegionModel;

  constructor(
    private dialogRef: MatDialogRef<AreaAddComponent>,
    private fb: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data: any) {
      this.selectedRegion = data?.region;
      this.areaModel = data?.area ? data?.area : {name: ''};
    }

    ngOnInit(): void {
      this.submitted = false;

      this.areaForm = this.fb.group({
        region: [this.areaModel.region],
        area: [this.areaModel.name]
      });
    }

    onRegionSelect(event: any) {
      console.log(event.target?.value -1)
      this.selectedRegion = this.lstRegions[event.target?.value -1 ];
      //this.areaModel.region = ;
    }

    saveArea(){
      this.submitted = true;

      if (!this.areaForm.valid) {
        return;
      }

      this.areaModel.region = this.selectedRegion;
      this.dialogRef.close(this.areaModel);
     }

    close(){
      this.dialogRef.close();
    }
}
