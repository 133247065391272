
<app-page-cardhead [PageName]="'Remove Laundering Scorecard'" [PageHeadWidth]="400" ></app-page-cardhead>

<div class="center-screen">
    <form novalidate>
        <div class="center-screen">
            <br>
            <p align="center" style="color: red; font-size: 16px;">
                <b>Are you sure you want to remove the risk score? This is permanent.</b></p>

            <table id="clientInfo" class="table table-striped table-light table-hover table-sm mb-3 mx-3 w-auto">
                <caption>Laundering Score Details</caption>
                <tbody>
                    <tr align="left">
                        <td style="width: 25%;">Parameter Type</td>
                        <td style="width: 25%;">{{riskScorecardInfo.parameterType}}</td>
                    </tr>
                    <tr align="left">
                        <td style="width: 25%;">Description</td>
                        <td style="width: 25%;">{{riskScorecardInfo.description}}</td>
                    </tr>

                    <tr align="left">
                        <td>Score</td>
                        <td>{{riskScorecardInfo.score}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="my-3 screen-center">
            <button class="btn btn-primary me-2" mdbRipple  (click)="close()"> No </button>            
            <button class="btn btn-danger" (click)="removeScoreCardConfirmation()"
                >
                Yes
            </button>
          </div>
        </div>
    </form>
</div>
