
  <div class="container-xs ms-auto me-auto pt-3 mb-2 px-5">
    <div class="card-holder ms-auto me-auto card" >
      <div class="card-body page-card rounded-2 shadow-3-strong my-1 mx-2 p-1 text-center" #pageCardHeader [style.width.px]="PageHeadLess">

        {{PageName}}
        <!-- <app-swing-loader></app-swing-loader> -->
      </div>
    </div>
  </div>
