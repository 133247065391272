import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegionModel } from 'src/app/core/models/region.model';
import { RegionAdminDialogComponent } from '../region-admin-dialog/region-admin-dialog.component';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { PageCardheadComponent } from '../../../shared/ui/pageheader-card/pageheader-card.component';

@Component({
    selector: 'app-regions-add',
    templateUrl: './regions-add.component.html',
    styleUrls: ['./regions-add.component.scss'],
    standalone: true,
    imports: [PageCardheadComponent, FormsModule, ReactiveFormsModule, NgIf, MatButtonModule, MdbRippleModule]
})
export class RegionsAddComponent {
  regionForm: FormGroup;
  submitted: boolean = false;
  regionModel: RegionModel;
  regionNumber: any;

  constructor(
    private dialogRef: MatDialogRef<RegionsAddComponent>,
    private formbuilder: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data: any) {
    this.regionModel = data?.area ? data?.area : {name: ''};
    this.regionNumber = data.regionNo;
  }

  ngOnInit(): void {
    this.submitted = false;

    this.regionForm = this.formbuilder.group({
      region: [this.regionModel.name]
    });
  }

  saveRegion(){
    this.submitted = true;
    if (!this.regionForm.valid) {
      return;
    }
    this.regionModel.regionNumber = this.regionNumber + 1;
    this.dialogRef.close(this.regionModel);
  }

  close(){
    this.dialogRef.close();
  }
}
