import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cbs-guide',
  templateUrl: './cbs-guide.component.html',
  styleUrls: ['./cbs-guide.component.css']
})
export class CbsGuideComponent {

  constructor(
    public dialogRef: MatDialogRef<CbsGuideComponent>,
  ){}

  close(){
    const result = true;
    this.dialogRef.close(result);
  }

}
