<app-page-cardhead [PageName]="'Comparing Offers'" [PageHeadWidth]=1000 ></app-page-cardhead>

  <div class="form-container center-screen">
    <table class="table table-striped table-light table-hover table-sm mb-3 caption-top" style="width: 950px;">

    <tbody> <!--  *ngIf="clientResults.loans"> -->
      <div *ngFor="let audit of lstAudit" style="width:1000px;">
        <tr>
          <td align="left">CLMS {{audit.action}}:  {{audit.previousData}}</td>
        </tr>
        <tr >
          <td align="left">
            <p style="max-width: 700;">Delfin {{audit.action}}:  {{audit.newData}}</p>
          </td>
        </tr>

      </div>
    </tbody>
  </table>
  <br/>
  <div>
    <button mat-raised-button (click)="close()" color="primary" > Close </button>
  </div>
</div>
