import {
  Directive,
  Input,
  Renderer2,
  ElementRef,
  Optional,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { SelectControlValueAccessor } from '@angular/forms';
import { LMSCONSTANTS, LMSConstantsService } from '@lmsConstants';

@Directive({
  selector: '[lmsSelectOption]',
  standalone: true,
})
export class LMSSelectOptionDirective implements OnInit, AfterViewInit, OnChanges {
  @Input() constantName: LMSCONSTANTS;
  @Input() addDefault = false;
  @Input() setFirstAsValue = false;
  private _select: SelectControlValueAccessor | null;

  constructor (
    private lmsConstants: LMSConstantsService,
    private element: ElementRef<HTMLSelectElement>,
    private renderer: Renderer2,
    @Optional() select: SelectControlValueAccessor
  ) {
    this._select = select || null;
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.constantName && changes.constantName.firstChange) {
      return;
    }

    this.clearOptions();
    this.loadLMSConstantOptions();
  }

  ngOnInit (): void {
    //this.clearOptions();
    //this.loadLMSConstantOptions();
  }

  ngAfterViewInit (): void {
    this.clearOptions();
    this.loadLMSConstantOptions();
  }

  private clearOptions (): void {
    const selectElement = this.element.nativeElement;
    while (selectElement.options.length > 0) {
      selectElement.options.remove(0);
    }

    if (!this.setFirstAsValue) {
      if (this._select) {
        this._select.writeValue(null);
      } else {
        // Explicitly set the value to an empty string to ensure no option is selected
        this.renderer.setProperty(selectElement, 'value', '');
      }
    }
  }

  private loadLMSConstantOptions (): void {
    if (!this.constantName) {
      return;
    }

    if (this.addDefault) {
      this.addOption('-- Select --', null);
      if (this.setFirstAsValue) {
        this.setSelectValue(null);
      }
    }

    const constants = this.lmsConstants.LMSConstants[this.constantName];
    constants.forEach((co, idx) => {
      this.addOption(co.description, co.value);
      if (!this.addDefault && this.setFirstAsValue && idx === 0) {
        this.setSelectValue(co.value);
      }
    });
  }

  private addOption (text: string, value: any): void {
    const option = this.renderer.createElement('option');
    const optionText = this.renderer.createText(text);
    this.renderer.appendChild(option, optionText);
    this.renderer.setProperty(option, 'value', value);
    this.renderer.appendChild(this.element.nativeElement, option);
  }

  private setSelectValue (value: any): void {
    const selectElement = this.element.nativeElement;
    if (this._select) {
      this._select.writeValue(value);
    } else {
      this.renderer.setProperty(selectElement, 'value', value);
      const event = new Event('change');
      selectElement.dispatchEvent(event);
    }
  }
}
