
<app-page-cardhead [PageName]="'OTP Override'" [PageHeadWidth]=700></app-page-cardhead>
<div>
  <div class="Scores">
    <h5>Scan Quality : <input type="text" id="qualityInputBox" size="15" readonly [(ngModel)]="readQuality"
        style="background-color:#DCDCDC;text-align:center; margin-top: 10px;"></h5>
  </div>
  <br>
  <div class="content-capture mx-2">
    <div *ngFor="let data of sampleData.slice(0,1); let sampleDataIndex = index;" style="margin-left:2%;">
        <label style="margin-top:5px;" [for]="'sampleData' + sampleDataIndex">Finger Data {{ sampleDataIndex + 1 }}
        </label>
        <label sampleDataIndex>: Captured success</label>
      </div>
    <div>
      <div>
        <table class="table table-striped table-light table-hover table-sm mb-3 caption-top" style="width: 98%;">
          <tbody class="field-4column-label">
            <tr>
              <td>Branch Manager Username:
              </td>
              <td style="margin-left: 20%;">
                <input placeholder="BM Username" [(ngModel)]="biometricsModel.bmUserName" required>
                <button mat-raised-button type="submit" color="primary" (click)="verifyBranchManager()"> Verify
                  Biometrics
                </button>                
              </td>
            </tr>
            <tr>
              <td>
                Verify Finger Response:
              </td>
              <td>
                {{enrollmentMessage}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    &nbsp;
    <div class="readerDivButtons">
      <button mat-raised-button type="button" color="primary" [disabled]="startAcquisitionDisabled"
        (click)="startCapture()">Start</button>

      <button mat-raised-button type="button" color="primary" style="margin-left: 10px;" (click)="closeScanFingers()">
       Close
      </button>
    </div>
  </div>
</div>
