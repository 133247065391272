<app-page-cardhead [PageName]="'Money Laundering Scorecard'" [PageHeadWidth]="400"></app-page-cardhead>
<div class="center-screen">
    <form [formGroup]="addRiskScoreForm" novalidate>
        <div class="center-screen">
            <table class="table table-striped table-light table-hover table-sm mb-3 mx-3 w-auto">
                <caption>Add Risk Score</caption>
                <tbody class="field-4column-label">
                    <tr>
                        <td>Parameter Type</td>
                        <td>
                            <select formControlName="parameterType" required>
                                <option *ngFor="let pType of lstParameterTypes;" [value]="pType.value">
                                    {{pType.parameterType}}
                                </option>
                            </select>
                            <!-- <div *ngIf="validateRiskScoreValues.parameterType.errors?.required && submitted"
                                 class="error-label">
                                Parameter Type is required
                            </div> -->
                        </td>
                    </tr>
                    <tr>
                        <td>Description</td>
                        <td>
                            <input formControlName="description" placeholder="Description" required>
                            <!-- <div *ngIf="validateRiskScoreValues.description.errors?.required && submitted"
                                 class="error-label">
                                Description is required
                            </div> -->
                        </td>
                    </tr>
                    <tr>
                        <td>Score</td>
                        <td>
                            <input formControlName="score" placeholder="Score"
                                (keypress)="($event.charCode >= 48 && $event.charCode < 58) || ($event.charCode >= 44 && $event.charCode < 45) || ($event.charCode >= 46 && $event.charCode < 47)"
                                required>
                            <!-- <div *ngIf="validateRiskScoreValues.score.errors?.required && submitted"
                                 class="error-label">
                                Score is required
                            </div> -->
                    </tr>
                </tbody>
            </table>
            <div class="my-3 screen-center">
            <button  class="btn btn-primary  me-2" mdbRipple  (click)="close()">Close</button>
            <button  class="btn btn-primary " mdbRipple  (click)="saveRiskScore()">Save</button>
            </div>
        </div>
    </form>
</div>
