export class RatesAndFeesModel {

  //PRODUCT DETAILS
  id: number;
  code: string;
  description: string;
  type: string;
  regulatoryType: string;
  minimumLoan: number;
  maximumLoan: number;
  interestRate1st: number;
  interestRateOther: number;
  initFeeBelow1000: number;
  initFeeFirst1000: number;
  initiationFee: number;
  initiationCap: number;
  myBenefits: number;
  factor1stLoan: number;
  factorOtherLoan: number;
  factorUpTo850: number;
  factorUpTo1000: number;
  initServiceFeeCap: number;
  term: number;
  monthlyServiceFee: number;

  // INTEREST FEES
  calculationType: string;
  includeVatInCalculation: boolean;
  maxDays: number;
  includeVatInCalc: boolean;
  includeInInterestCalc: boolean;

  mybenefitsTier!: number;

  // //SERVICE FEES
  // monthCalculation: string;
  // includeInInterestCalculation: boolean
  // calcualteForAnyLoanInMonth: boolean
  // monthlyAmount: number;

  // //CREDIT LIMIT
  // percent: number;

  //GENERAL
  // maxDaysInFutureForPayDate: number;

  updatedBy: any;
  updatedOn: any;
}
