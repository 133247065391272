<ng-container [formGroupName]="controlKey">
  <div class="card">
    <h5 class="card-header text-center">SASSA information</h5>
    <div class="card-body">
      <table id="tblsalariedinfo" class="table table-striped table-sm mb-1">
        <tbody class="field-4column-label">
          <tr>
            <td>
              <label for="grantType">Type of grant</label>
            </td>
            <td>
              <select formControlName="grantType" (change)="toggleGrantType()"
              class="custom-select" placeholder="Grant Type *"
              lmsSelectOption [constantName]="'GRANTTYPE'" [setFisrtasValue]="true"
              required>
                <!-- <option *ngFor="let grant of lstGrantType" [value]="grant.value">
                  {{grant.description}}
                </option> -->
              </select>
              <div *ngIf="grantType?.invalid && Submitted">
                <div *ngIf="grantType?.errors?.required">
                  <small class="text-danger ms-2">Grant type is required.</small>
                </div>
              </div>
            </td>
            <div *ngIf="grantType.value === 'Disability';  then showCat else hideCate"></div>
            <ng-template #showCat>
            <td *ngIf="grantType.value === 'Disability'">
              <label for="grantCategory">Grant category</label>
              <!-- EMPTY LABEL COLUMN SPACE -->
            </td>
            <td *ngIf="grantType.value === 'Disability'">
              <select formControlName="grantCategory" (change)="toggleGrantDates()"
              class="custom-select"
              lmsSelectOption [constantName]="'GRANTCATEGORY'"
              required>
                <!-- <option *ngFor="let grant of lstGrantType" [value]="grant.value">
                  {{grant.description}}
                </option> -->
              </select>
            </td>
          </ng-template>
          <ng-template #hideCate>
          <!-- EMPTY COLUMN SPACE -->
            <td *ngIf="grantType.value !== 'Disability'"></td>
            <td *ngIf="grantType.value !== 'Disability'"></td>
          </ng-template>
          </tr>
          <!-- ALIASED as
                Temporary == Disability
                Permanent == Old age pension -->
            <div *ngIf="grantType.value !== 'Disability' || grantCategory.value !== 'Temporary';  then showPermanent else showTemp"></div>
            <ng-template #showPermanent>
            <tr>
              <td>
                <label for="grantStartDate">Grant Start Date</label>
              </td>
              <td>
                <input [matDatepicker]="picker" [max]="maxDate" (focus)="picker.open()"
                  placeholder="YYYY-MM-DD *"  formControlName="employmentStartDate" [required]="true"
                  (dateChange)="GrantDateSelected($event)" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <div *ngIf="employmentStartDate?.invalid  || invalidDate">
                  <div *ngIf="employmentStartDate?.errors?.required && Submitted">
                    <small class="text-danger ms-2">Grant start date is required.</small>
                  </div>
                  <div *ngIf="invalidDate">
                    <small class="text-danger">{{payDateError}}</small>
                  </div>
                </div>
              </td>
            </tr>
            </ng-template>
          <!-- GRANT START DATE and END DATE -->
           <!-- ALIASED as
                Temporary == Disability
                Permanent == Old age pension -->
            <ng-template #showTemp>
              <tr>
              <td *ngIf="grantCategory.value === 'Temporary'">
                <label>Temporary Start Date</label>
              </td>
              <td *ngIf="grantCategory.value === 'Temporary'">
                <input [matDatepicker]="picker1" [max]="maxDate" (focus)="picker1.open()"
                  placeholder="YYYY-MM-DD" style="min-width: 130px; width: 145px;" formControlName="contractStartDate"
                  [required]="grantCategory.value === 'Temporary'"
                  (dateChange)="GrantDateSelected($event)" />
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1 startView="year" [startAt]="maxDate"></mat-datepicker>
                <div *ngIf="contractStartDate?.invalid  || invalidDate">
                  <div *ngIf="contractStartDate?.errors?.required && Submitted">
                    <small class="text-danger">Grant temporary start date is required</small>
                  </div>

                  <div *ngIf="invalidDate">
                    <small class="text-danger">{{payDateError}}</small>
                  </div>
                </div>
              </td>
              <td *ngIf="grantCategory.value === 'Temporary'">
                <label>Temporary End Date</label>
              </td>
              <!-- ALIASED as
                  Temporary == Disability
                  Permanent == Old age pension -->
              <td *ngIf="grantCategory.value === 'Temporary'">
                <input [matDatepicker]="picker2" placeholder="YYYY-MM-DD" (focus)="picker2.open()"
                  style="min-width: 130px; width: 145px;" formControlName="contractEndDate"
                  [required]="grantCategory.value === 'Temporary'"
                  (dateChange)="GrantEndDateSelected($event)" />
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2 startView="year"></mat-datepicker>
                <div *ngIf="contractEndDate?.invalid">
                  <div *ngIf="contractEndDate?.errors?.required && Submitted">
                    <small class="text-danger">Grant temporary end date is required</small>
                  </div>
                </div>
                <div *ngIf="invalidEndDate">
                  <small class="text-danger">{{payDateError}}</small>
                </div>
              </td>
            </tr>
            </ng-template>
            <!-- <tr>
              <td>
                <label>Latest grant amounts</label>
              </td>
              <td>
                <input placeholder="Month 1" formControlName="month1" required class="mx-2" max="99999" numbersOnly
                  min="1" (keypress)="numberOnly($event)"
                  [ngClass]="{ 'is-invalid': Submitted && f.controls.month1.errors }">
                <input placeholder="Month 2" formControlName="month2" required class="mx-2" max="99999" numbersOnly
                  min="1" (keypress)="numberOnly($event)"
                  [ngClass]="{ 'is-invalid': Submitted && f.controls.month2.errors }">
                <input placeholder="Month 3" formControlName="month3" required min="0" max="99999" numbersOnly
                  (keypress)="numberOnly($event)" [ngClass]="{ 'is-invalid': Submitted && f.controls.month3.errors }">
              </td>
            </tr>
            <tr *ngIf="f.controls.month1.errors || f.controls.month2.errors || f.controls.month3.errors">
              <td colspan="4">
                <div *ngIf="Submitted && f.controls.month1?.errors" class="invalid-feedback">
                  <small class="text-danger">1st month`s grant requires an amount.</small>
                </div>
                <div *ngIf="Submitted && f.controls.month2?.errors" class="invalid-feedback">
                  <small class="text-danger">2nd month`s grant requires an amount.</small>
                </div>
                <div *ngIf="Submitted && f.controls.month3?.errors" class="invalid-feedback">
                  <small class="text-danger">3rd month`s grant requires an amount.</small>
                </div>
              </td>
            </tr> -->

          <tr>
            <td>Latest grant amounts</td>
            <td colspan="4" class="py-1">
              <ng-container formGroupName="payslips">
                <span class="input-group d-flex align-items-center ms-auto">
                  <button class="btn btn-secondary me-5" (click)="openPaySlip(1)" mdbRipple>
                  {{(income1 === 0) ? 'Income 1' : income1 | zaRcurrency}}
                  </button>
                  <input hidden="true" [class.ng-invalid]="payslips!.hasError('duplicatePayslip')" formControlName="payslip1" />

                  <button class="btn btn-secondary me-5" (click)="openPaySlip(2)" mdbRipple>
                  {{(income2 === 0) ? 'Income 2' : income2 | zaRcurrency}}
                  </button>
                  <input hidden="true" [class.ng-invalid]="payslips!.hasError('duplicatePayslip')" formControlName="payslip2" />

                  <input hidden="true" [class.ng-invalid]="payslips!.hasError('duplicatePayslip')" formControlName="payslip3" />
                  <button class="btn btn-secondary me-5" (click)="openPaySlip(3)" mdbRipple>
                  {{(income3 === 0) ? 'Income 3' : income3 | zaRcurrency}}
                  </button>
                </span>

                <div *ngIf="payslips?.invalid">
                  <div *ngIf="payslips!.hasError('duplicatePayslip')">
                  <small class="text-danger">
                    Duplicate payslip detected, this is not permitted!
                  </small>
                  </div>
                </div>
			        </ng-container>
			      </td>
          </tr>
          <tr *ngIf="Submitted && (income1 === 0 || income2 === 0 || income3 === 0)">
            <td></td>
            <td colspan="3">
              <small class="text-danger">
                At least 3 incomes needs to be captured.
              </small>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
