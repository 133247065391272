import { CommonModule } from "@angular/common";
import { Component, ViewEncapsulation } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";

import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { ClientHeaderComponent } from "../modules/client-header/client-header.component";
import { PageCardheadComponent } from "@lmsSharedUI";


@Component({
  selector: "app-risk-matrix-score",
  standalone: true,
  template: `
  <div class="modal-content">
  <div class="modal-header">
    <div class="modal-title center-fields">
    <app-page-cardhead [PageName]="'Risk Analysis'" [PageHeadWidth]="500"></app-page-cardhead>
    </div>
  </div>
  <div class="modal-body" >
    <div class="card">
      <div class="card-body p-1">
        <div class="container-fluid bd-example-row">
          <ng-container>
            <table class="mt-3 table table-bordered table-striped table-light table-sm shadow-1-strong rounded-1 p-1" [style.width.px]="470">
            <thead>
              <tr  class="table-center-header">
                <th>Risk Type</th>
                <th>Risk Score</th>
              </tr>
            </thead>
            <tbody> <!--  *ngIf="clientResults.loans"> -->
              <tr *ngFor="let risk of riskAnalysis">
                <td style="width: 75%;">{{risk.riskType}}</td>
                <td class="text-center muted-text" style="width: 25%;">{{risk.riskValue}}</td>
              </tr>
            </tbody>
            </table>
          </ng-container>
        </div>
        <div class="card-footer center-screen">
          <button
            mat-raised-button
            class="btn btn-primary btn-rounded"
            mdbRipple
            color="primary"
            (click)="close()">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
`,
  styleUrls: ["./risk-matrix-score.component.css"],
  imports: [CommonModule, MatButtonModule, ClientHeaderComponent, PageCardheadComponent],
  encapsulation: ViewEncapsulation.Emulated
})
export class RiskMatrixScoreComponent  {
  riskAnalysis: any;

  constructor(public modalRef: MdbModalRef<RiskMatrixScoreComponent>) { }


  close() {
    this.modalRef.close();
  }
}
