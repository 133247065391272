import { Component, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegionModel,AreaModel } from 'src/app/core/models/region.model';
import { AreaAdminDialogComponent } from '../area-admin-dialog/area-admin-dialog.component';
import { BranchService } from '@lmsrestServices';
import { ToastrService } from 'ngx-toastr';
import { AreaAddComponent } from '../area-add/area-add.component';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MatButtonModule } from '@angular/material/button';
import { NgFor, NgIf } from '@angular/common';
import { PageCardheadComponent } from '../../../shared/ui/pageheader-card/pageheader-card.component';

@Component({
    selector: 'app-region-admin-dialog',
    templateUrl: './region-admin-dialog.component.html',
    styleUrls: ['./region-admin-dialog.component.scss'],
    standalone: true,
    imports: [PageCardheadComponent, FormsModule, ReactiveFormsModule, NgFor, MatButtonModule, MdbRippleModule, NgIf]
})
export class RegionAdminDialogComponent {
  regionForm: FormGroup;
  submitted: boolean = false;
  regionModel: RegionModel;
  regionNumber: any;
  region: any;

  isEditing = false;
  enableEditIndex: number;
  editAreaIndex: number;
  lstAreas: Array<any>;
  areaSelected: AreaModel = {} as AreaModel;
  isAreaEditing = false;
  regionSelected: RegionModel = {} as RegionModel;


  constructor(
    private dialogRef: MatDialogRef<RegionAdminDialogComponent>,
    private formbuilder: FormBuilder,
    private branchService: BranchService,
    private toastrService: ToastrService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data: any) {
      this.regionSelected = data?.region;
     // this.lstAreas = this.regionSelected.areas;
      this.regionModel = data?.region;  //area ? data?.area : {name: ''};
    //this.regionNumber = data.regionNo;
  }

  ngOnInit(): void {
    this.submitted = true;

    this.regionForm = this.formbuilder.group({
      region: [this.regionModel.name],
      id: [this.regionModel.id],
      regionNumber: [this.regionModel.regionNumber],
     // name: ['', [Validators.required]],
      areas: [this.regionModel.areas] ,  //new FormArray([])
      selArea: [this.areaSelected]
    });
  }

 
  saveRegion(){
    if (!this.regionForm.valid) {
      return;
    }

    this.dialogRef.close(this.regionModel);
  }

 selectArea(area?: AreaModel | null){
  //if(this.isEditing && !this.isAreaEditing){
    //Select an existing area or add a new area with new region
  //  let index = this.lstAreas.map(r => r.id).indexOf(area?.id || 0);
   // this.areaSelected = (index >= 0 && area) ? area : {id: 0, name: '' };

   // this.editAreaIndex = index || 0;
    this.isAreaEditing = true;

    this.dialog.open(AreaAdminDialogComponent, {
      disableClose: true,
      autoFocus: true,
      height: 'auto',
      minWidth: '600px',
      data: {
        area: area,
        region: this.regionModel.regionNumber
      },
    }).afterClosed().subscribe((new_areaval) => {
      if(new_areaval){
      //Save to DB
        this.branchService.saveRegion(this.regionModel).subscribe((res) => {
          if(!res) {
            this.toastrService.error('Update region failed.','Add branch failed!')
          }
          this.regionSelected.areas = res.areas;
          // this.loadingService.stopLoading();
        });
      }
     
       this.isAreaEditing = false;
    });

}

findAllAreas(){
  console.log('add');
   this.branchService.getAllAreas().subscribe((res) => {
        if(!res) {
          this.toastrService.error('Add new branch failed.','Add branch failed!')
        }
        this.lstAreas = res;
        // this.loadingService.stopLoading();
      });
}

createNewArea() {
  console.log("addNewArea");
  this.dialog.open(AreaAddComponent, {
    disableClose: true,
    autoFocus: true,
    height: 'auto',
    minWidth: '600px',
    data: {
     region: this.regionSelected,
     // regionNo : lstSize
    },
  }).afterClosed().subscribe((new_area) => {
    if(new_area){

    //  this.region.areas.push(new_area.name);

      // for (let area of this.lstAreas) {
      //   console.log(area);
      //   if (area.id == event.target.value) {
      //     this.regionModel.areas.push(area);
      //     this.regionSelected.areas.push(area);
      //   }
      // }

    //SAve to DB
      this.branchService.saveArea(new_area).subscribe((res) => {
        if(!res) {
          this.toastrService.error('Add new branch failed.','Add branch failed!')
        }
        this.regionSelected.areas = res;
        // this.loadingService.stopLoading();
      });
    } else {
      console.log("why");
     }

  //  }
    // this.regionSelected = {} as RegionModel;
    // this.areaSelected = {} as AreaModel;
    // this.isEditing = false;
    // this.isAreaEditing = false;
  });
}



// onAreaSelect(event :any) {
//   console.log(event.target.value);
//   //this.selectedArea = 
// }

get areas() {
  return (this.regionForm?.get('areas') as FormArray).controls;
}

selectNewArea (event: any) {
  console.log(event.target.value);
  //this.regionModel.areas.push(event.target.value);
  this.regionModel = this.regionSelected;

  for (let area of this.lstAreas) {
    console.log(area);
    if (area.id == event.target.value) {
      this.regionModel.areas.push(area);
     // this.regionSelected.areas.push(area);
    } 
  }


  // this.branchService.saveRegion(this.regionModel).subscribe((res) => {
  //   if(!res) {
  //     this.toastrService.error('Add new area failed.','Add area failed!')
  //   }
  //   this.regionSelected = res;
  //   // this.loadingService.stopLoading();
  // });
// } else {
//   console.log("why");
//  }

//  }
// this.regionSelected = {} as RegionModel;
// this.areaSelected = {} as AreaModel;
// this.isEditing = false;
// this.isAreaEditing = false;
//});

  // this.branchService.saveRegion(this.regionModel).subscribe((res) => {
  //   if(!res) {
  //     this.toastrService.error('Update region has failed.','Update region failed!')
  //   }
  //   this.regionModel = res;
  //   // this.loadingService.stopLoading();
  // });

 // console.log(area);
}

  close(){
    this.dialogRef.close();
  }
}
