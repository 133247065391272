import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { map } from "rxjs/operators";
import { LoadingSpinService } from 'src/app/shared/loaders/spinner/loadspinner.service';
import { environment } from 'src/environments/environment';
import { BranchEmployer } from './models/branch-employers';
import { EmployerDetailsModel } from './models/employer-details-model';


@Injectable({
  providedIn: 'root'
})
export class UpdateEmployerService {
  http: HttpClient;
  UpdateEmployer_apiURL = `${environment.apiUrl}/loan/api/admin`;
  quote_apiURL = `${environment.apiUrl}/loan/api/quote`;
  employerDetailsModel: EmployerDetailsModel;
  rejectReasonsData: any;
  data: any;

  HttpParamsOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      })
  };

  constructor(http: HttpClient,public loaderService: LoadingSpinService, public toastrService: ToastrService) {
    this.http = http;
  }

  findEmployersByName(data: any) {
    return this.http
    .post<BranchEmployer[]>(this.UpdateEmployer_apiURL + '/findEmployersByName',data)
      .pipe(
        //catchError(this.handleError),
        map((responseData) => {
        return responseData;
      }));
  }

  saveEmployer(employerDetails: EmployerDetailsModel) {
    return this.http
    .post<any>(this.UpdateEmployer_apiURL + '/saveEmployer',employerDetails)
      .pipe(
        //catchError(this.handleError),
        map((responseData) => {
        return responseData;
      }));
  }

  deleteEmployer(employerId: any): Observable<any> {
    return this.http
      .delete(this.UpdateEmployer_apiURL + '/deleteEmployer/' + employerId)
      .pipe(
        //catchError(this.handleError),
        map((responseData) => {
          return responseData;
        })
      );
  }


  create(employer: EmployerDetailsModel): Observable<EmployerDetailsModel> {
    let url = `${this.UpdateEmployer_apiURL}/saveEmployer`;
    //console.log("Create contact URL is " + url);

    return this.http.post<EmployerDetailsModel>(url, employer);
  }

  update(employer: EmployerDetailsModel): Observable<EmployerDetailsModel> {
    //let url = `${this.UpdateEmployer_apiURL}/saveEmployer/${contact.employer.id}`;
    let url = `${this.UpdateEmployer_apiURL}/saveEmployer`;
    //console.log("Update contact URL is " + url);

    return this.http.put<EmployerDetailsModel>(url, employer);
  }

  fetchById(id: string): Observable<EmployerDetailsModel> {
    let url = `${this.UpdateEmployer_apiURL}/fetchBranchEmployers`;
    //console.log("Fetch contact URL is " + url);

    return this.http.post<EmployerDetailsModel>(url,id);
  }

  private handleError(error: HttpErrorResponse) {
    let showdate = new Date().toLocaleString();

    if (error.type?.valueOf() === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error?.error);
      this.toastrService.error(`ERROR: ${error.error?.message||error.error}`, `${showdate}: Communication error`, { positionClass: 'toast-top-center', tapToDismiss: true});
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      this.toastrService.error(`Server Error: ${error.error?.message}`, `${showdate}: ${error?.status}-${error?.name}`, { positionClass: 'toast-top-center', tapToDismiss: true});
      //console.error(`Backend returned code ${error.status}, body was: `, error.error);
    }
    // this.setNoMenus(true);
    // this.setisUserLoggedin(false);
    // this.setLMSProfileObs(null);
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
