import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { SwitchdataComponent } from './switchdata/switchdata.component';

import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ClientDetailsService, QuoteStoreService } from '@lmsrestServices';
import { ErrorhanderService } from 'src/app/core/services/error-handler.service';
import { CanComponentDeactivate } from 'src/app/core/guard/unsaved-changes.guard';
import { AppUtilityService } from '../app-utility.service';
import { LMSDialogHandlerService } from 'src/app/shared/lmsdialog-handler.service';
import { SalariedEditorComponent } from './salaried-editor/salaried-editor.component';
import { SassaEditorComponent } from './sassa-editor/sassa-editor.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { LMSSelectOptionDirective, NumbersOnlyDirective } from '@lmsSharedUI';
import { ClientSearchModel, ClientShowModel, ClientType, LMSReasonCodesModel, PreAuthorisationModel } from '@lmsModels';
import { LMSPageHeadComponent } from '../core/guard/LMSPageHeader.guard';
import { LoanReasonCodesService } from '../modules/loan-reason-codes/loan-reason-codes.service';
import { QuoteChangedService } from '../core/services/changed-service.service';
import { MarketingOptionsParametersService } from '../modules/marketing-options-parameters/marketing-options-parameters.service';

const SALARIEDTYPEDESC = 'Salaried';
const SASSATYPEDESC = 'SASSA';
const PENSIONERTYPEDESC = 'Pensioner';

@Component({
  selector: 'app-client-search',
  templateUrl: './client-search.component.html',
  styleUrls: [ './client-search.component.css' ],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatOptionModule,
    SassaEditorComponent,
    SalariedEditorComponent,
    MatRadioModule,
    LMSSelectOptionDirective,
    NumbersOnlyDirective,
  ],
})
export class ClientSearchComponent implements OnInit, OnDestroy, CanComponentDeactivate, LMSPageHeadComponent {
  public lmsPageHead: () => 'Client Information';

  clientSearchModel = {} as ClientSearchModel;
  preAuthorisationModel = {} as PreAuthorisationModel;
  private _originalPreAuthVal: any;

  compuscanOffers: any;
  submitted = false;
  clientShowModel = {} as ClientShowModel;
  lstAllLoanReasonCodes: LMSReasonCodesModel[] = [];

  //lstNationality = NATIONALITY;
  //lstMaritalStatus = MARITALSTATUS;
  //lstSourceOfWealth = SOURCEOFWEALTH;
  //lstContactPreferences = CONTACTPREFERENCES;
  lstMarketingOptions: any[] = [];
  isContractType: boolean;
  isPermEmployedType: boolean;
  isRejectOfferRejected: boolean;
  isMONoneSelected = false;
  isMAOtherOptionSelected = false;
  ClientTypeSASSA = false;
  originalClientType: string;
  originalEmployer: string;
  compuscanOffersEvery = false;
  quoteFormLoading = false;
  searchForm = this.fb.group({
    clientType: [ '' ],
    nationality: [ '', [] ],
    loanReason: [ '', [ Validators.required ] ],
    loanAmount: [ '' || 0, [ Validators.required ] ],
    maritalStatus: [ '', [ Validators.required ] ],
    contactPreference: new FormControl({}, Validators.required),
    underDebtReview: [ '', [ Validators.required ] ],
    placedUnderDebtReview: [ 'no', [] ],
    underSequestration: [ '', [ Validators.required ] ],
    underAdministration: [ '', [ Validators.required ] ],
    placedUnderAdministration: [ 'no', [] ],

    sourceOfWealth: [ '', [ Validators.required ] ],
    sourceOfWealthOther: [ '', [] ],
    marketingOptions: new FormControl([], Validators.required),
  });

  constructor (
    private route: ActivatedRoute,
    public quoteStoreService: QuoteStoreService,
    private fb: FormBuilder,
    public clientSearch: ClientDetailsService,
    private loanReasonCodesService: LoanReasonCodesService,
    private dialog: MatDialog,
    private errorhanderService: ErrorhanderService,
    private changesService: QuoteChangedService,
    private marketingOptionsParametersService: MarketingOptionsParametersService,
    public global_utility: AppUtilityService,
    public lmsDialogHandler: LMSDialogHandlerService
  ) {
    this.loanReasonCodesService.getAllLoanReasonCodes().subscribe({
      next: (obj: any) => {
        this.lstAllLoanReasonCodes = obj;
      },
    });

    this.marketingOptionsParametersService.findAll().subscribe((result: any) => {
      this.lstMarketingOptions = result;
      console.log('marketing list ----', this.lstMarketingOptions);
    });
  }
  ngOnDestroy (): void {
    this._originalPreAuthVal = undefined;
  }

  get loanReason () {
    return this.searchForm.get('loanReason');
  }
  get loanAmount () {
    return this.searchForm.get('loanAmount');
  }
  get clientType () {
    return this.searchForm.get('clientType');
  }

  get maritalStatus () {
    return this.searchForm.get('maritalStatus');
  }
  get contactPreference () {
    return this.searchForm.get('contactPreference') as FormControl;
  }

  get marketingOptions () {
    return this.searchForm.get('marketingOptions') as FormControl;
  }
  get nationality () {
    return this.searchForm.get('nationality');
  }

  get underAdministration () {
    return this.searchForm.get('underAdministration');
  }

  get underSequestration () {
    return this.searchForm.get('underSequestration');
  }

  get underDebtReview () {
    return this.searchForm.get('underDebtReview');
  }
  get sourceOfWealth () {
    return this.searchForm.get('sourceOfWealth');
  }

  get isOtherNationality () {
    return this.preAuthorisationModel.nationality == 'OTHER';
  }

  toggleClientTypeEditor (type: ClientType) {
    this.preAuthorisationModel.clientType = type;

    // If employment type was previously sassa and is changed to salaried then this will not work
    // so we change the value if it was previously sassa
    // and vica versa
    if (type.toLowerCase() === 'sassa' && this.preAuthorisationModel.employmentType !== SASSATYPEDESC) {
      this.preAuthorisationModel.employmentType = SASSATYPEDESC;
    }
    if (type.toLowerCase() !== 'sassa' && this.preAuthorisationModel.employmentType == SASSATYPEDESC) {
      this.preAuthorisationModel.employmentType = 'Permanent';
    }
  }

  ngOnInit () {
    this.quoteFormLoading = true;

    this.getNewLMS()
      .then((isLoaded) => {
        if (!isLoaded) {
          this.global_utility.stopLoading();
          this.goBack();
          return;
        }

        this.DTOtoPreAuthorisation();
        this.PreAuthtoSearchDataForm();
        this.global_utility.stopLoading();

        //let isMismatch = ((this.quoteStoreService.quoteWorkbook && this.quoteStoreService.quoteWorkbook.id) &&
        //  (this.quoteStoreService.PreAuthorisationModel && this.quoteStoreService.PreAuthorisationModel.id) &&
        //(this.quoteStoreService.quoteWorkbook.id !== this.quoteStoreService.PreAuthorisationModel.id &&
        //  this.quoteStoreService.quoteWorkbook!.idNumber !== this.quoteStoreService.PreAuthorisationModel!.idNumber) || false;
        // if (isMismatch) {
        //   // || this.quoteStoreService.quoteWorkbook.quoteId !== this.quoteStoreService.PreAuthorisationModel.quoteId) {
        //   this.quoteFormLoading = false;
        //   this.toastrService.error('Quote Workbook[ID:' + this.quoteStoreService.quoteWorkbook?.id + '] and PreAuth[ID:' + this.quoteStoreService.PreAuthorisationModel?.id + '] mismatch. Please revert to CLient list and try again!!', 'DATA MISMATCH');
        //   this.openModelSwitchDialog();
        // }

        this.changesService.setTrackEnter(false);
        this.quoteFormLoading = false;
      })
      .catch((reason) => {
        this.global_utility.error(reason, 'Quote loading error');
        this.goBack();
      });
  }

  getNewLMS () {
    const qid = Number(this.route.snapshot.paramMap.get('idNumber'));
    this.global_utility.startLoading('Resuming application..');
    return new Promise<boolean>((resolve, reject) => {
      // If an Id is not provided then this application is from PreAuth screen
      if (!qid || qid == 0) {
        this.global_utility.stopLoading();

        if (
          !this.quoteStoreService.isQuoteFound &&
          Object.keys(this.quoteStoreService.PreAuthorisationModel).length == 0
        ) {
          reject(new Error('Empty Workbook and no id to load, please confirm request'));
          resolve(false);
          return;
        }

        this._originalPreAuthVal = this.quoteStoreService.PreAuthorisationModel;
        // this._originalPreAuthVal =
        //   (this.quoteStoreService.isQuoteFound && !(this.quoteStoreService.PreAuthorisationModel && this.quoteStoreService.PreAuthorisationModel.id))
        //     ? this.quoteStoreService.quoteWorkbook
        //     : this.quoteStoreService.PreAuthorisationModel;

        resolve(true);
        return;
      }

      this.quoteStoreService.getQuoteDetailsById(qid).subscribe({
        next: (qofferResult: any) => {
          if (qofferResult) {
            this._originalPreAuthVal = qofferResult;
            //this.quoteStoreService.setQuoteWorkbook(qofferResult);
            this.global_utility.stopLoading();
            resolve(true);
          } else {
            resolve(false);
          }
        },
        error: (err) => {
          this.global_utility.stopLoading();
          reject(new Error(err));
        },
      });
    });
  }

  private DTOtoPreAuthorisation () {
    this.clientShowModel = {
      fullNames: this._originalPreAuthVal.fullNames || this._originalPreAuthVal.firstName,
      surname: this._originalPreAuthVal.surname || this._originalPreAuthVal.lastName,
      idNumber: this._originalPreAuthVal.idNumber,
      cellNumber: this._originalPreAuthVal.cellNumber,
      clientType: this._originalPreAuthVal.clientType,
      clientStatus: this._originalPreAuthVal.clientStatus,
      loanStatus: this._originalPreAuthVal.loanStatus,
      clientNumber: '',
      id: this._originalPreAuthVal.id,
    };

    this.preAuthorisationModel = this._originalPreAuthVal;
    this.preAuthorisationModel.quoteId = this._originalPreAuthVal.id;
    this.preAuthorisationModel.clientType = this._originalPreAuthVal.clientType || SALARIEDTYPEDESC;
    this.ClientTypeSASSA = this.preAuthorisationModel.clientType == SASSATYPEDESC || false;
    this.originalClientType = this.preAuthorisationModel.clientType;
    this.originalEmployer = this.preAuthorisationModel.employer;

    this._originalPreAuthVal.additionalIncome = !this._originalPreAuthVal.additionalIncome
      ? null
      : this._originalPreAuthVal.additionalIncome.length == 0
      ? null
      : this._originalPreAuthVal.additionalIncome;
    this._originalPreAuthVal.marketingOptions = !this._originalPreAuthVal.marketingOptions
      ? null
      : this._originalPreAuthVal.marketingOptions.length == 0
      ? null
      : this._originalPreAuthVal.marketingOptions;
    const addinfo = this.fromAttribute((this._originalPreAuthVal.additionalIncome as string[]) || '');
    const marketopt = this.fromAttribute((this._originalPreAuthVal.marketingOptions as string[]) || '');

    this.preAuthorisationModel.selectedadditionalIncome = addinfo?.join() || '';
    this.preAuthorisationModel.selectedMarketingOptions = marketopt?.join() || '';

    if (this._originalPreAuthVal && this._originalPreAuthVal.compuscanOffers) {
      this.compuscanOffersEvery =
        Array.isArray(this._originalPreAuthVal.compuscanOffers) &&
        this._originalPreAuthVal.compuscanOffers.length > 0 &&
        (Array.from(this._originalPreAuthVal.compuscanOffers).every((compOffer: any) => compOffer.outcome == 'N') ||
          false);
    }
  }

  private PreAuthtoSearchDataForm () {
    this.searchForm.patchValue(
      {
        clientType: this.preAuthorisationModel.clientType,
        nationality: this.preAuthorisationModel.nationality,
        loanReason: this.preAuthorisationModel.loanReason,
        loanAmount: this.preAuthorisationModel.loanAmount,
        maritalStatus: this.preAuthorisationModel.maritalStatus,
        underDebtReview: this.preAuthorisationModel.underDebtReview,
        //placedUnderDebtReview: this.preAuthorisationModel!.placedUnderDebtReview ?? 'no',
        underSequestration: this.preAuthorisationModel.underSequestration,
        underAdministration: this.preAuthorisationModel.underAdministration,
        //placedUnderAdministration: this.preAuthorisationModel!.placedUnderAdministration,
        // remove this when moved to pre auth
        sourceOfWealth: this.preAuthorisationModel.sourceOfWealth,
        sourceOfWealthOther: this.preAuthorisationModel.sourceOfWealthOther,
        // marketingOptions: this._originalPreAuthVal.marketingOptions
      },
      { onlySelf: false, emitEvent: true }
    );

    this.contactPreference.setValue(this._originalPreAuthVal.contactPreference, { onlySelf: true, emitEvent: false });

    this._originalPreAuthVal.marketingOptions = !this._originalPreAuthVal.marketingOptions
      ? []
      : this._originalPreAuthVal.marketingOptions.length == 0
      ? []
      : this._originalPreAuthVal.marketingOptions;
    if (this._originalPreAuthVal.marketingOptions && this._originalPreAuthVal.marketingOptions.length > 0) {
      const maopts = this.fromAttribute(this._originalPreAuthVal.marketingOptions || '');
      this.marketingOptions.setValue(maopts, { onlySelf: true, emitEvent: false });
    }

    if (this.preAuthorisationModel && this.preAuthorisationModel.quoteId! > 0) {
      this.setFormCtrlChanges();
      this.searchForm.controls.clientType.patchValue(this.preAuthorisationModel.clientType, { emitEvent: true });
    }

    //(this.searchForm.controls['salariedTypeControls'] as FormGroup).controls['employer'].patchValue(this._originalPreAuthVal.employment?.name || this._originalPreAuthVal.employerName);

    //Only change the flag after the formdata is laoded and user has not yet made changes
    this.preAuthorisationModel.doUpdateEmployment = false;
    console.info('path ancestorOrigins >> ', location.ancestorOrigins);
    this.valuesDialog();
  }

  valuesDialog () {
    this.lmsDialogHandler.openCompuscanOffersDialog(this._originalPreAuthVal.compuscanOffers);
  }

  public canDeactivate (): Promise<boolean> {
    return this.changesService.NavigationAllowed();
  }

  fromAttribute (newVal) {
    return !newVal ? null : (newVal as string).length == 0 ? null : (newVal as string).split(',').map((x) => x.trim());
  }

  private setFormCtrlChanges () {
    this.clientType?.valueChanges.subscribe((clientType) => {
      this.submitted = false;

      if (this.preAuthorisationModel.clientType != clientType) {
        this.preAuthorisationModel.doUpdateEmployment = true;
      }

      if (this.originalClientType == clientType) {
        this.preAuthorisationModel.doUpdateEmployment = false;
      }

      if (clientType === SASSATYPEDESC) {
        this.ClientTypeSASSA = true;
        //this.preAuthorisationModel = { ...this.preAuthorisationModel, ...this.searchForm.controls['salariedTypeControls']?.value };
        this.preAuthorisationModel.clientType = SASSATYPEDESC;
        this.preAuthorisationModel.employer = SASSATYPEDESC;
        //this.searchForm.controls['sassaTypeControls'].value
        this.preAuthorisationModel.employmentType = 'SASSA';
        this.preAuthorisationModel.payDate = '4';
        this.preAuthorisationModel.wageCalendar = 'Monthly';

        this.preAuthorisationModel.industryType = '';
        this.preAuthorisationModel.industryTypeOther = '';
      } else {
        this.preAuthorisationModel.clientType =
          clientType?.toLowerCase() == SALARIEDTYPEDESC.toLowerCase() ? SALARIEDTYPEDESC : PENSIONERTYPEDESC;
        this.ClientTypeSASSA = false;

        if (this._originalPreAuthVal.employerName !== SASSATYPEDESC) {
          this.preAuthorisationModel.employer =
            this._originalPreAuthVal.employer?.name || this._originalPreAuthVal.employerName;
        } else {
          this.preAuthorisationModel.employer = '';
        }
        this.preAuthorisationModel.payDate =
          this._originalPreAuthVal.employment && this._originalPreAuthVal.employment.payDate > 0
            ? this._originalPreAuthVal.employment?.payDate
            : '4';
      }
      this.toggleClientTypeEditor(clientType as ClientType);
    });

    this.sourceOfWealth?.valueChanges.subscribe((value) => {
      if (this.quoteFormLoading) {
        return;
      }
      if (value && value == 'Other') {
        this.searchForm.get('sourceOfWealthOther')?.enable();
        this.searchForm.get('sourceOfWealthOther')?.addValidators(Validators.required);
        this.searchForm.get('sourceOfWealthOther')?.updateValueAndValidity();
      } else {
        this.searchForm.get('sourceOfWealthOther')?.disable();
        if (this.searchForm.get('sourceOfWealthOther')?.hasValidator(Validators.required)) {
          this.searchForm.get('sourceOfWealthOther')?.removeValidators(Validators.required);
        }
      }
    });
  }

  submitClientSearch () {
    window.scrollTo(0, 0);
    this.submitted = true;
    const controlKey = this.ClientTypeSASSA ? 'sassaTypeControls' : 'salariedTypeControls';
    this.searchForm.controls[controlKey].updateValueAndValidity();

    if (this.searchForm.invalid) {
      this.global_utility.findInvalidControls(this.searchForm);
      if (controlKey == 'salariedTypeControls') {
        this.global_utility.findInvalidControls(this.searchForm.controls[controlKey] as FormGroup);
      }
      return;
    }

    console.log('Marketing Options -----', this.preAuthorisationModel.marketingOptions);

    const ControlKeyVals = { ...this.searchForm.controls[controlKey].value };

    // We dont need to add the [SASSA] or [SALARIED] FormGroup to preAuth model, so we remove it
    this.searchForm.get(controlKey)?.disable();

    // SASSA does not have additional income so we will default this until business rules are defined.
    if (this.ClientTypeSASSA) {
      ControlKeyVals.additionalIncome = [ 'None' ];
      this.preAuthorisationModel.selectedadditionalIncome = 'None';
      this.preAuthorisationModel.employmentType = 'SASSA';
      this.preAuthorisationModel.occupation = ControlKeyVals.grantType;
    } else {
      if (!ControlKeyVals.employerTNR || ControlKeyVals.employerTNR == 0) {
        this.global_utility.warning(
          'The employer does not exist! Create the employer on `Branch Admin | Maintain Employers` menu above.',
          'Employer requirement missing'
        );
        this.submitted = false;
        this.searchForm.get(controlKey)?.enable();
        return;
      }
      // This is duplicate code because of the undefined rules of switching between salaried and sassa client types
      // this is required to fix the .join exception when SASSA
      this.preAuthorisationModel.selectedadditionalIncome =
        ControlKeyVals.additionalIncome.length > 0
          ? Array.from(ControlKeyVals.additionalIncome).join(',') ?? ControlKeyVals.additionalIncome
          : '';
    }

    this.preAuthorisationModel.selectedMarketingOptions =
      this.marketingOptions.value && Array.from(this.marketingOptions.value).length > 0
        ? Array.from(this.marketingOptions.value).join(',') ?? this.marketingOptions.value
        : '';

    this.preAuthorisationModel = { ...this.preAuthorisationModel, ...this.searchForm.value, ...ControlKeyVals };

    if (this.originalEmployer && this.originalEmployer != this.preAuthorisationModel.employer) {
      this.preAuthorisationModel.doUpdateEmployment = true;
    }

    if (this.originalClientType == this.preAuthorisationModel.clientType) {
      this.preAuthorisationModel.doUpdateEmployment = false;
    }

    if (
      (this.preAuthorisationModel.payslip1!.nettIncome! || 0) == 0 ||
      (this.preAuthorisationModel.payslip2!.nettIncome! || 0) == 0 ||
      (this.preAuthorisationModel.payslip3!.nettIncome! || 0) == 0 ||
      (!this.ClientTypeSASSA &&
        this.preAuthorisationModel.nationality &&
        this.preAuthorisationModel.nationality == 'OTHER')
    ) {
      this.global_utility.warning('Income values are NOT valid for the application to continue!');
      this.submitted = false;
      this.searchForm.get(controlKey)?.enable();
      return;
    }
    if (
      this.preAuthorisationModel.underAdministration == 'yes' ||
      this.preAuthorisationModel.underSequestration == 'yes' ||
      this.preAuthorisationModel.underDebtReview == 'yes'
    ) {
      this.global_utility.warning('Listing questions requirements are NOT valid for the application to continue!');
      this.submitted = false;
      this.searchForm.get(controlKey)?.enable();
      return;
    }

    if (ControlKeyVals.employerTNR > 0) {
      this.preAuthorisationModel.employerTNR = ControlKeyVals.employerTNR;
    }
    this.preAuthorisationModel.userBranchSerial = this.global_utility.getGlobalData('branch_serial');
    this.preAuthorisationModel.userName = this.global_utility.getGlobalData('userDisplay');

    //this.preAuthorisationModel.employer = this.quoteStoreService.PreAuthorisationModel.employer;

    this.global_utility.startLoading('Submitting client information.');
    this.clientSearch.submitClientInformation(this.preAuthorisationModel).subscribe({
      next: (res: any) => {
        if (res) {
          if (res.id === 0) {
            this.global_utility.error('Saving error!! Please contact admin!');
            this.global_utility.stopLoading();
            this.global_utility.navigateToLanding();
            return;
          }

          this.quoteStoreService.PreAuthorisationModel = res;
          this.quoteStoreService.PreAuthorisationModel.quoteId = res.id;
          this.global_utility.navigateToURL([ '/clientDetails', 0 ]);
        }
      },
      error: (err) => {
        this.global_utility.stopLoading();
        this.errorhanderService.handle(err);
      },
      complete: () => {
        this.global_utility.stopLoading();
      },
    });
  }

  isSassaValid (): boolean {
    const sassaFormGroup = this.searchForm.get('sassaTypeControls');
    return sassaFormGroup?.valid || false;
  }

  isSalariedValid (): boolean {
    const salariedFormGroup = this.searchForm.get('salariedTypeControls');
    return salariedFormGroup?.valid || false;
  }

  onNationalitySelect (event: any) {
    const control: HTMLSelectElement = event.target as HTMLSelectElement;
    this.preAuthorisationModel.nationality = this.nationality?.value || '';

    if (event.target.value == 'OTHER') {
      control.style.borderColor = 'rgba(220,53,69, 0.8)';
      control.style.borderLeftStyle = '3px solid #a94442b4'; /* red */

      this.searchForm.controls.nationality.setErrors({ invalid: true });
      this.searchForm.controls.nationality.updateValueAndValidity();

      //alert("Only South African nationality is accepted");
      this.global_utility.info('Only South African nationality is acccepted', 'Nationality not accepted');
    } else {
      control.style.borderColor = 'rgba(66, 169, 72, 0.7)';
      control.style.borderLeftStyle = '3px solid #42a948'; /* red */
    }
  }

  onLoanSelect (data: any) {
    console.log('onLoanSelect', data);
  }

  goBack () {
    this.global_utility.navigateToLanding();
  }

  openRejectOfferDialog () {
    const currQuote = this._originalPreAuthVal;
    currQuote.quoteId = this._originalPreAuthVal.id;

    this.lmsDialogHandler.openRejectOfferDialog(currQuote).onClose.subscribe((resultDlg) => {
      if (resultDlg) {
        currQuote.rejectOfferReason = resultDlg.rejectOfferReason;
        currQuote.userName = this.global_utility.getGlobalData('userDisplay');

        this.quoteStoreService.rejectOffer(currQuote).subscribe({
          next: (res) => {
            if (res.errorMessage) {
              this.global_utility.error(res.errorMessage, 'Reject Failed');
              return;
            }
            this.isRejectOfferRejected = true;
            this.global_utility.navigateToLanding();
          },
          error: (err) => {
            this.errorhanderService.handle(err);
          },
        });
      }
    });
  }

  openModelSwitchDialog () {
    this.dialog
      .open(SwitchdataComponent, {
        disableClose: true,
        autoFocus: true,
        width: 'auto',
        height: 'auto',
        data: {
          quoteWorkBook: this.quoteStoreService.quoteWorkbook,
          preAuthModel: this.quoteStoreService.PreAuthorisationModel,
        },
      })
      .afterClosed()
      .subscribe((selectedModel: any) => {
        if (!selectedModel || selectedModel == 0) {
          this.global_utility.navigateToLanding();
          this.global_utility.error(
            `Quote Workbook[ID:${
              this.quoteStoreService.quoteWorkbook.id
              }] and PreAuth[ID:${
              this.quoteStoreService.PreAuthorisationModel.id
              }] mismatch. Please revert to [Client list] page and try again!!`,
            'DATA MISMATCH'
          );
          return;
        }

        if (selectedModel == this.quoteStoreService.quoteWorkbook.id) {
          this._originalPreAuthVal = this.quoteStoreService.quoteWorkbook;
        } else {
          this._originalPreAuthVal = this.quoteStoreService.PreAuthorisationModel;
        }

        this.PreAuthtoSearchDataForm();
        this.changesService.setTrackEnter(false);
        this.quoteFormLoading = false;
      });
  }

  updateMarketingOptions (value) {
    if (value) {
      // this.preAuthorisationModel.marketingOptions = value.value;
      this.isMONoneSelected = value.some((option) => (option as string).toLowerCase().includes('none')) || false;
      console.log('isMONoneSelected:', this.isMONoneSelected);
      this.isMAOtherOptionSelected =
        value.some((option) => !(option as string).toLowerCase().includes('none')) || false;
      console.log('isMAOtherOptionSelected:', this.isMAOtherOptionSelected);
    }
  }
}
