/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { NgFor, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { BiometricsModel } from 'src/app/core/models/biometrics-model.model';
import { PreAuthorisationModel } from 'src/app/core/models/pre-authorisation.model';
import { BiometricsSerivce } from 'src/app/core/rest-services/biometrics.service';
import { UserService } from 'src/app/core/services/lmsuser.service';
import { DisableControlDirective } from 'src/app/shared/directives/disableControl.directive';
import { PageCardheadComponent } from "../../../shared/ui/pageheader-card/pageheader-card.component";


declare let Fingerprint: any;

export interface CustomerEnrollmentEntity {
  id?: number;
  idNumber?: string;
  username?: string;
  fingerName?: FingerNameEnum;
  fmd?: string;
  created?: string;
  enrolledBy?: string;
  disabledAt?: string;
  disabledBy?: string;
}

type FingerNameEnum = 'LEFT_PINKY' | 'LEFT_RING' | 'LEFT_MIDDLE' | 'LEFT_INDEX' | 'LEFT_THUMB' | 'RIGHT_PINKY' | 'RIGHT_RING' | 'RIGHT_MIDDLE' | 'RIGHT_INDEX' | 'RIGHT_THUMB';

// export namespace CustomerEnrollmentEntity {
//   const FingerNameEnum = {
//     LeftPinky: 'LEFT_PINKY' as FingerNameEnum,
//     LeftRing: 'LEFT_RING' as FingerNameEnum,
//     LeftMiddle: 'LEFT_MIDDLE' as FingerNameEnum,
//     LeftIndex: 'LEFT_INDEX' as FingerNameEnum,
//     LeftThumb: 'LEFT_THUMB' as FingerNameEnum,
//     RightPinky: 'RIGHT_PINKY' as FingerNameEnum,
//     RightRing: 'RIGHT_RING' as FingerNameEnum,
//     RightMiddle: 'RIGHT_MIDDLE' as FingerNameEnum,
//     RightIndex: 'RIGHT_INDEX' as FingerNameEnum,
//     RightThumb: 'RIGHT_THUMB' as FingerNameEnum
//   };
// }

const NO_FINGER = 'NO_FINGER';

export interface SelectItem<T = any> {
  label?: string;
  value: T;
  styleClass?: string;
  icon?: string;
  title?: string;
  disabled?: boolean;
}

@Component({
  selector: 'app-branch-manager-override',
  templateUrl: './branch-manager-override.component.html',
  styleUrls: ['./branch-manager-override.component.css'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, DisableControlDirective, NgIf, NgFor, MatButtonModule, PageCardheadComponent]
})
export class BranchManagerOverrideComponent implements OnInit {
  preauthorisationModel: PreAuthorisationModel = new PreAuthorisationModel();
  biometricsModel: BiometricsModel = new BiometricsModel();
  
  biometricsInfo: any;
  userBiometricsInfo: any;

  readerOpen = true;
  captureOpen = false;
  verificationOpen = false;
  userVerificationOpen = false;
  disableOpen = false;
  deviceInfo = false;
  signatureOpen = false;

  disabled = true;
  biometrics: any;

  readQuality: string;
  status: string;
  sdk: any;
  numberReadings: number;
  deviceSerial: string;
  currentFormat: any;
  devicesList: string[];
  deviceID: string;
  deviceUIDType: string;
  deviceModality: string;
  deviceTechnology: string;
  sampleData: string[];
  radioFormat: number;
  enrollDisabled: boolean;
  stopAcquisitionDisabled: boolean;
  startAcquisitionDisabled: boolean;

  enrollmentError?: string;
  enrollmentMessage?: string;
  clientEnrolled: CustomerEnrollmentEntity[] | [];
  userEnrolled: CustomerEnrollmentEntity[] | [];
  fingerNames: SelectItem[];
  selectedFingerName: any;
  selectedConsultantFingerName: any;
  verifyDisabled: boolean;

  biometricType: any;
  lmsUserProfile: any;

  clientBiometricsFound: boolean;
  userBiometricsFound: boolean;
  continueApplicationDisabled: boolean;

  enableSkip: boolean;
  lstVerifiedFingers: any;

  noBiometrics: boolean;
  lstAllFingersDisable: boolean;
  sampleDataVerify: string;
  lstSignedFingerDetails: any[];
  lstConsultantSignedFingerDetails: any[];

  preAgreement: any;
  isNokComplete: boolean;
  verifiedClient = false;
  verifiedUser = false;
  isCellVisible: boolean;
  bmVerified: boolean;
  submitted = false;
  skipBiometrics = false;

  pager: any = {};

  ngOnInit(): void {
   
    this.selectedFingerName = '';
    this.onUserProfileValue();

    this.sdk.onDeviceConnected = () => {
      // Detects if the deveice is connected for which acquisition started
      console.log("Device Connected");
      this.status = "Device Connected";
    };
    this.sdk.onDeviceDisconnected = () => {
      // Detects if device gets disconnected - provides deviceUid of disconnected device
      console.log("Device disconnected");
      this.status = "Device disconnected";
    };
    this.sdk.onCommunicationFailed = () => {
      // Detects if there is a failure in communicating with U.R.U web SDK
      console.log("Communinication Failed");
      this.status = "Communinication Failed";
    };
    this.sdk.onErrorOccurred = () => {
      console.log("Error Occurred during capture");
      this.status = "Error Occurred during capture";
    };

    this.sdk.onSamplesAcquired = (s: any) => {
      // Sample acquired event triggers this function
      console.log("Sample Acquired");
      this.status = "Sample Acquired";
      this.sampleAcquired(s);
    };
    this.sdk.onQualityReported = (e: { quality: any; }) => {
      console.log("onQualityReported");
      // Quality of sample aquired - Function triggered on every sample acquired
      this.readQuality = Fingerprint.QualityCode[(e.quality)];
      this.status = "Quality Reported";
      if (this.readQuality == 'Good') {
        this.numberReadings++;
      }
      else {
        this.toastrService.error(`${this.readQuality}`, 'Finger Quality Status', { positionClass: 'toast-top-center', closeButton: true });
        //this.stopClientCapture();
      }
    };
   
    this.getDevice();
  }

  onUserProfileValue = () => {
    this.userService.LMSProfile$.subscribe((lmsuser: any) => {
      if (lmsuser && lmsuser.id!) {
        this.lmsUserProfile = lmsuser;
        console.log("User Profile: ", this.lmsUserProfile);
      }
    });
  };

  constructor(
    public biometricsService: BiometricsSerivce,
    private userService: UserService,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<BranchManagerOverrideComponent>,
    @Inject(MAT_DIALOG_DATA) data: any){
      this.userBiometricsInfo = data.userBiometricsData,
      this.numberReadings = 0
      this.biometricType = data.biometricType,
      this.readQuality = "",
      this.status = "Select a device",
      this.sdk = new Fingerprint.WebApi,
      this.numberReadings = 0,
      this.deviceSerial = "No Device Selected",
      this.currentFormat = Fingerprint.SampleFormat.Intermediate,
      this.devicesList = [],
      this.deviceUIDType = "",
      this.deviceID = "",
      this.deviceModality = "",
      this.deviceTechnology = "",
      this.sampleData = [],
      this.radioFormat = 2,
      this.enrollDisabled = true,
      this.stopAcquisitionDisabled = true,
      this.startAcquisitionDisabled = false,
      this.continueApplicationDisabled = true,

      this.enrollmentMessage = "",
      this.enrollmentError = "",
      this.clientEnrolled = [],
      this.userEnrolled = [],
      this.selectedFingerName = "",
      this.verifyDisabled = true;
  }

  sampleAcquired(s: any) {
    console.log("sampleAcquired..." + s);
    var samples = JSON.parse(s.samples);
    var finger = samples[0];

    console.log("sampleAcquired currentFormat..." + this.currentFormat);
    if (this.currentFormat == Fingerprint.SampleFormat.PngImage) {
      localStorage.setItem("imageSrc", "");
      localStorage.setItem("imageSrc", "data:image/png;base64," + Fingerprint.b64UrlTo64(finger));
      var vDiv = document.getElementById('imagediv');
      // @ts-ignore
      vDiv.innerHTML = "";
      var image = document.createElement("img");
      image.id = "image";
      // @ts-ignore
      image.src = localStorage.getItem("imageSrc");
      // @ts-ignore
      vDiv.appendChild(image);
      this.sampleData[this.numberReadings - 1] = Fingerprint.b64UrlTo64(finger);
    } else if (this.currentFormat == Fingerprint.SampleFormat.Raw) {
      console.log("processing raw...");
      localStorage.setItem("raw", "");
      var samples = JSON.parse(s.samples);
      var sampleData = Fingerprint.b64UrlTo64(samples[0].Data);
      var decodedData = JSON.parse(Fingerprint.b64UrlToUtf8(sampleData));
      localStorage.setItem("raw", Fingerprint.b64UrlTo64(decodedData.Data));
      var vRaw = document.getElementById('imagediv');
      // @ts-ignore
      vRaw.innerHTML = '<div id="animateText" >RAW Sample Acquired <br>' + Date() + '</div>';
      this.sampleData[this.numberReadings] = Fingerprint.b64UrlTo64(decodedData.Data);          //JSON.stringify(decodedData);
    } else if (this.currentFormat == Fingerprint.SampleFormat.Compressed) {
      console.log("processing wsq...");
      localStorage.setItem("wsq", "");
      var samples = JSON.parse(s.samples);
      var sampleData = Fingerprint.b64UrlTo64(samples[0].Data);
      var decodedData = JSON.parse(Fingerprint.b64UrlToUtf8(sampleData));
      localStorage.setItem("wsq", "data:application/octet-stream;base64," + Fingerprint.b64UrlTo64(decodedData.Data));

      var vWsq = document.getElementById('imagediv');
      // @ts-ignore
      vWsq.innerHTML = '<div id="animateText" style="display:none">WSQ Sample Acquired <br>' + Date() + '</div>';
      this.sampleData[this.numberReadings - 1] = Fingerprint.b64UrlTo64(decodedData.Data);      //JSON.stringify(decodedData);
    } else { //intermediate
      //      this.sampleData[this.numberReadings - 1] = Fingerprint.b64UrlTo64(finger.Data);
      this.sampleData[this.numberReadings - 1] = finger.Data;
      // var vImage = document.getElementById('image');
      // // @ts-ignore
      // vImage.remove();
      if (this.numberReadings == 4) {
        this.enrollDisabled = false;
        console.log("Enabling enroll button");
      }
      if (this.numberReadings >= 1) {
        if(this.selectedFingerName === 'NO_FINGER'){
          this.verifyDisabled = true;
        }else{
          this.verifyDisabled = false;
        }
        console.log("Enabling verify button");
      }

    }

  }

  verifyBranchManager() {
    if(this.biometricsModel.bmUserName === '' || this.biometricsModel.bmUserName === null){
      this.toastrService.warning('Please Enter a valid Branch Manger Username.', '', { positionClass: 'toast-top-center' });
      this.sampleData = [];
      this.stopUserCapture();
      return;
    }

    if (this.biometricsModel.bmUserName === this.userService.userLoginName && this.userService.userJobTitle.jobTitle === 'Branch Consultant') {
      this.toastrService.warning(
          `Please enter a valid Branch Manager username.`,
          ''
      );
      this.sampleData = [];
      this.stopUserCapture();
      return;
  }


    if (!this.sampleData || this.sampleData.length == 0) {
      this.toastrService.warning('Finger could not be Verified or no finger provided.', '', { positionClass: 'toast-top-center' });
      return;
    }

    this.sampleDataVerify = this.sampleData.slice(0, 1).toString();
    this.biometricsModel.fingerImage = this.sampleDataVerify;
    this.biometricsModel.fingerName = this.selectedFingerName;
    this.biometricsModel.verifiedBy = this.biometricsModel.bmUserName;
    this.biometricsModel.userName = this.biometricsModel.bmUserName;
    this.biometricsModel.branchName = this.userService.userBranchName;
    this.biometricsModel.skipReason = 'No OTP Received, BM Override';

      this.biometricsService.verifyUserFinger(this.biometricsModel).subscribe(response => {
        console.log(response);
        this.enrollmentError = response?.errorCode;
        this.enrollmentMessage = response?.errorMessage;
        // this.enrollDisabled = true;
        this.numberReadings = 0;
        this.sampleData = [];

        const toastString = 'Verify Finger Response: ' + this.enrollmentMessage;
        if (this.enrollmentError == 'FAILURE') {
          this.toastrService.warning(toastString, '', { positionClass: 'toast-top-center' });
        }
        else {
          this.toastrService.info(toastString, '', { positionClass: 'toast-top-center' });
          
          this.dialogRef.close(true);
        }
        this.stopUserCapture();
      });
  }

  
  getDevice() {
    Promise.all([
      this.sdk.enumerateDevices()
    ]).then(devices => {
      // @ts-ignore
      this.deviceSerial = devices[0];
      console.log("deviceSerial =" + this.deviceSerial);
    })
      .catch((error) => {
        console.log(error);
      });
  }

  getDeviceInfo() {
    // @ts-ignore
    var deviceInfoPromise: Promise<DeviceInfo> = this.sdk.getDeviceInfo(this.deviceSerial);
    this.deviceInfo = true;
    deviceInfoPromise.then((successObj) => {
      console.log(successObj);
      this.deviceID = successObj.DeviceID;
      this.deviceTechnology = Fingerprint.DeviceTechnology[successObj.eDeviceTech];
      this.deviceUIDType = Fingerprint.DeviceUidType[successObj.eUidType];
      this.deviceModality = Fingerprint.DeviceModality[successObj.eDeviceModality];
    })
      .catch((error) => {
        console.log(error);
      });
  }


  startCapture() {
    this.startAcquisitionDisabled = true;
    this.stopAcquisitionDisabled = false;
    this.currentFormat = this.radioFormat;

    this.sdk.startAcquisition(this.currentFormat)
      .then(() => {
        console.log("Scan a finger");
        this.status = "Scan a finger";
      })
      .catch((error: any) => {
        console.error("Error starting capture:", error);
        this.status = error.message;
      });
  }


  stopUserCapture() {
    this.startAcquisitionDisabled = false;
    this.stopAcquisitionDisabled = true;

    this.sdk.stopAcquisition().then(() => {
      console.log("Capturing stopped..");
      this.status = "Capturing stopped";      
    })
      .catch((error: any) => {
        console.log(error.message);
        this.status = error.message;
      });

  }
  
  closeScanFingers() {
    try {
      this.sdk.stopAcquisition();
      this.dialogRef.close();
    } catch (error: any) {
      console.log(error.message);
      this.status = error.message;
    }
  }

  // close() {
  //   this.dialogRef.close();
  // }
}
