export class RiskScoreModel{
    id: number;
    manageEngineId: number;
    parameterType: string;
    description: string;
    score: number;
    createdOn: Date;
    createdBy?: string;
    updatedOn: Date;
    updatedBy?: string;
}
