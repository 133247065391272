import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatDialog, MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MenuModel } from 'src/app/core/models/menu.model';
import { ClientDetailsService } from 'src/app/core/rest-services/client-details.service';
import { MenuService } from 'src/app/core/rest-services/menu.service';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { LoadingSpinService } from 'src/app/shared/loaders/spinner/loadspinner.service';
import { SubMenuDialogComponent } from '../sub-menu-dialog/sub-menu-dialog.component';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf, NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault, NgClass } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { PageCardheadComponent } from '../../../shared/ui/pageheader-card/pageheader-card.component';

const COLUMNS_SCHEMA = [
  {
    key: 'submenu',
    type: 'text',
    label: 'Sub Menu',
  },
  {
    key: 'component',
    type: 'text',
    label: 'Component',
  },
  {
    key: 'roles',
    type: 'text',
    label: 'roles',
  },
  {
    key: 'isEdit',
    type: 'isEdit',
    label: '',
  },
];

@Component({
    selector: 'app-menus-dialog',
    templateUrl: './menus-dialog.component.html',
    styleUrls: ['./menus-dialog.component.css'],
    standalone: true,
    imports: [PageCardheadComponent, FormsModule, ReactiveFormsModule, MatTabsModule, NgIf, MatCheckboxModule, MatSelectModule, NgFor, MatOptionModule, MatButtonModule, MatTableModule, NgSwitch, NgSwitchCase, NgSwitchDefault, MatInputModule, NgClass]
})
export class MenusDialogComponent implements OnInit {
  menuModel: MenuModel = new MenuModel();
  menuForm: FormGroup;
  menu: any;
  jobTitles: any;
  color = 'primary';
  menuRole: any;
  submitted: boolean;
  isComponentRequired: boolean;
  isTriggerRequired: boolean;

  displayedColumns: string[] = COLUMNS_SCHEMA.map((col) => col.key);
  dataSource: any;
  columnsSchema: any = COLUMNS_SCHEMA;
  menuRoles: Array<any> = [];
  selectedMenu: any;

  constructor(
    private dialogRef: MatDialogRef<MenusDialogComponent>,
    private formBuilder: FormBuilder,
    private menuService: MenuService,
    private router: Router,
    private cs: ClientDetailsService,
    public toastrService: ToastrService,
    public dialog: MatDialog,
    private loadingService: LoadingSpinService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.menuModel = data.menu;
    this.menuRoles = data.menu.menuRoles;

    this.cs.getAllJobTitles().subscribe((res) => {
      this.jobTitles = res;
      this.selectedMenu = data.menu;

      const activeRoles: any[] = [];
      for (const b of data.menu.menuRoles) {
        const jobTitleId = b.jobTitle.id;
        const index = this.jobTitles.findIndex(
          (x: { id: any }) => x.id === jobTitleId
        );
        activeRoles.push(this.jobTitles[index]);
      }

      this.menuModel.selectedJobTitles = activeRoles;
    });
  }

  ngOnInit(): void {
    this.submitted = true;

    this.menuForm = this.formBuilder.group({
      menu: [this.menuModel.menu],
      menu_component: [this.menuModel.component],
      hasSubmenu: [this.menuModel.hasSubmenu],
      selectedRole: [],
      trigger: [this.menuModel.trigger],
      submenu: [this.menuModel.submenus.subMenu],
      component: [this.menuModel.component],
      selectedJobTitles: [this.menuModel.selectedJobTitles],
    });

    if (this.menuModel.submenus.length > 0) {
      this.isComponentRequired = false;
      this.menuForm.controls['menu_component'].disable();
    } else {
      this.isComponentRequired = true;
      this.menuForm.controls['trigger'].disable();
    }

    this.dataSource = this.menuModel.submenus;

    for (const b of this.menuModel.submenus) {
      const roles: any[] = [];
      for (const role of b.submenusRoles) {
        roles.push(role.jobTitle.jobTitle);
      }
      b.roles = roles.join();
    }
  }

  modifyMenu(element: any) {
    alert('Modify menu');
    element.submenu = element.submenu.trim();
    element.component = element.component.trim();
    element.isEdit = !element.isEdit;
  }

  async saveChanges() {
    if (this.menuModel.submenus == null) {
      this.menuModel.submenus = [];
    }

    if (
      this.menuModel.hasSubmenu &&
      this.menuModel.trigger &&
      this.jobTitles.length > 0 &&
      this.menuModel.menu &&
      this.menuModel.submenus.length > 0
    ) {
      this.onSaveMenus();
    } else if (
      this.menuModel.component &&
      this.jobTitles.length > 0 &&
      this.menuModel.menu
    ) {
      if (this.menuModel.component.startsWith('/')) {
        this.onSaveMenus();
      } else {
        this.showAlertMessage('Component should start with /', 'red-snackbar');
      }
    } else if (
      this.menuModel.hasSubmenu &&
      this.menuModel.trigger &&
      this.jobTitles.length > 0 &&
      this.menuModel.menu &&
      this.menuModel.submenus.length <= 0
    ) {
      this.showAlertMessage('Please add Submenus.', 'red-snackbar');
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showAlertMessage(message: any, cssClass: any) {
    this.toastrService.info(message,'');
  }

  async hasSubmenuOnChangeEvent(event: any): Promise<void> {
    if (event.checked) {
      this.isComponentRequired = false;
      this.isTriggerRequired = true;
      this.menuForm.controls['menu_component'].disable();
      this.menuForm.controls['trigger'].enable();
      this.menuModel.component = '';
    } else {
      this.menuForm.controls['menu_component'].enable();
      this.menuForm.controls['trigger'].disable();
      this.menuModel.trigger = '';
      this.isComponentRequired = true;
      this.isTriggerRequired = false;
    }
  }

  async onSaveMenus(): Promise<void> {
    const savingDlg =
      this.loadingService.startLoading('Saving Menus..');

    this.selectedMenu.menuRoles = [];

    const selectedRoles: any[] = [];
    for (const role of this.menuModel.selectedJobTitles) {
      selectedRoles.push({
        id: 0,
        menu: this.selectedMenu,
        jobTitle: role,
      });
    }

    if (this.menuModel.hasSubmenu) {

      for (const b in this.menuModel.submenus) {
        const isNew = this.menuModel.submenus[b].isNew;

        if (isNew) {
          const newSubmenu = {
            submenu: this.menuModel.submenus[b].submenu,
            component: this.menuModel.submenus[b].component,
            submenusRoles: this.menuModel.submenus[b].submenusRoles
          };
          this.menuModel.submenus[b] = newSubmenu;
        }
      }
    } else {
      this.menuModel.submenus = [];
    }

    const data = {
      menu: this.selectedMenu,
      menuRoles: selectedRoles,
    };

    this.menuService.saveSubmenu(data).subscribe(() => {
      this.loadingService.stopLoading(savingDlg);
      this.toastrService.success('','Saved successfully.');

      this.router.navigate(['menuAdmin']);
    });
  }

  deleteSubmenu(subMenu: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '650px',
      height: 'auto',
      autoFocus: true,
      data: {
        title: 'Submenu Delete',
        message: 'Are you sure you want to delete?',
      },
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      const confirmResult = dialogResult;

      if (confirmResult == true) {
        const SubmentDlg =
          this.loadingService.startLoading('Deleting Submenu..');

        this.menuService.deleteSubmenu(subMenu).subscribe(() => {
          this.loadingService.stopLoading(SubmentDlg);

          this.toastrService.success('','Deleted successfully.');
        });
      }
    });
  }

  close() {
    this.dialogRef.close();
    this.router.navigate(['menuAdmin']);
  }

  addRow() {
    const dialogRef = this.dialog.open(SubMenuDialogComponent, {
      disableClose: true,
      autoFocus: true,
      height: 'auto',
      width: '650px'
    });

    dialogRef.afterClosed().subscribe((menu) => {
      if (menu) {
        const submenusRoles: any[] = [];
        const roles: any[] = [];

        for (const b of menu.userRoles) {
          roles.push(b.jobTitle);
          submenusRoles.push({
            jobTitle: { id: b.id },
            submenu: menu
          });
        }

        const newMenu = {
          id: Date.now(),
          submenu: menu.submenu,
          component: menu.component,
          isEdit: false,
          isNew: true,
          submenusRoles: submenusRoles,
          roles: roles.join()
        }

        this.dataSource = [newMenu, ...this.dataSource];
        this.menuModel.submenus = this.dataSource;
      }
    });
  }

  editSubmenu(element: any) {
    const dialogRef = this.dialog.open(SubMenuDialogComponent, {
      disableClose: true,
      autoFocus: true,
      height: 'auto',
      width: '650px',
      data: element
    });

    dialogRef.afterClosed().subscribe((menu) => {
      if (menu) {
        const submenusRoles: any[] = [];
        const roles:any[] = [];

        for (const b of menu.userRoles) {
          roles.push(b.jobTitle);
          submenusRoles.push({
            jobTitle: { id: b.id },
            sub_menu_id: menu.id,
          });
        }

        menu.submenusRoles = submenusRoles;
        menu.roles = roles.join();
      }
    });
  }

  removeRow(element: any) {
    this.dataSource = this.dataSource.filter(
      (u: { id: number }) => u.id !== element.id
    );
    this.deleteSubmenu(element);
    this.menuModel.submenus = this.dataSource;
  }

  removeTrailingSpaces(element: any) {
    element.submenu = element.submenu.trim();
    element.component = element.component.trim();
  }
}
