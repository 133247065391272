import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MenusAdminComponent } from './menus-admin/menus-admin.component';
import { MenusDialogComponent } from './menus-dialog/menus-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { LMSSharedUiModule } from '@lmsSharedUI';
import { NgxPaginationModule } from 'ngx-pagination';
import { SubMenuDialogComponent } from './sub-menu-dialog/sub-menu-dialog.component';
import { MenuService } from "src/app/core/rest-services/menu.service";

/**
 * Node for to-do item
 */
export class TodoItemNode {
  children: TodoItemNode[];
  item: string;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: string;
  level: number;
  expandable: boolean;
}


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatTabsModule,
        MatCheckboxModule,
        MatTableModule,
        MatInputModule,
        MatButtonModule,
        NgxPaginationModule,
        LMSSharedUiModule,
        MenusAdminComponent,
        MenusDialogComponent,
        SubMenuDialogComponent
    ],
    exports: [
        MenusAdminComponent,
        MenusDialogComponent
    ],
    providers: [
        MenuService
    ]
})
export class MenusModule { }
