<app-page-cardhead [PageName]="'Add Area Details'" [PageHeadWidth]=500></app-page-cardhead>
<div class="container-sm center-container">
  <form [formGroup]="areaForm">
    <div class="card m-2">
      <div class="card-body p-1">
      <table class="table table-striped table-hover table-sm mb-3 caption-top" [style.width.px]=470>
        <tbody class="field-4column-label">
            <tr>
                <td>Region:</td>
                <td> {{selectedRegion.name}}
                    <!-- <select formControlName="region"
                        (change)="onRegionSelect($event)"
                        [(ngModel)]="areaModel.region"
                        required>
                        <option *ngFor="let reg of lstRegions"
                            [value]="reg.id">
                            {{ reg.name }}
                        </option>
                    </select>
                    <div *ngIf="areaForm.controls.region.errors?.required && submitted" class="error-label">
                      Select a region for this area
                    </div> -->
                </td>
            </tr>

          <tr>
            <td>Area name</td>
            <td>
              <input formControlName="area"
               [(ngModel)]="areaModel.name"
                type="text" required>
              <div *ngIf="areaForm.controls.area.errors?.required && submitted" class="error-label">
                Area name is required
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      </div>

    <div class="card-footer center-screen">
      <button mat-raised-button class="btn btn-primary btn-rounded me-2" mdbRipple color="primary" (click)="close()"> Close
      </button>
      <button mat-raised-button class="btn btn-primary btn-rounded" mdbRipple color="primary"
        (click)="saveArea()">Save</button>
    </div>
    </div>
  </form>
</div>

