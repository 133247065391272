export class MenuModel {
    id: number;
    menu: string;
    component: string;
    userRole: any;
    hasSubmenu: boolean;
    trigger: string;
    submenus: any;
    selectedJobTitles : any;
}
