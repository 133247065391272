  <form  class="form-container center-screen p-1 my-2"
    [formGroup]="regionAdminForm" >
    <table class="table table-striped table-hover table-sm caption-top" style="width:750px">
      <thead class="table-center-header">
        <tr >
          <td style="width: 50px;">Id</td>
          <td>Region</td>
          <!-- <td>Region Number</td> -->
          <td>Areas</td>
          <td >Edit</td>
          <!-- <td style="width: 30px;">Delete
          </td> -->
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let region of dbregions | paginate: { itemsPerPage: pageSize, currentPage: pager.currentPage };let i = index;">
          <tr>
            <td>{{region.id}}</td>
            <td>{{region.name}}</td>
            <!-- <td>{{region.regionNumber}}</td> -->
            <td>
              <mat-list class="d-flex d-flex-sm p-n1">
                <mat-list-item *ngFor="let item of region.areas">{{ item.name }}</mat-list-item>
              </mat-list>
            </td>
            <td>
              <button mat-icon-button
                class="btn btn-primary btn-rounded"
                mdbRipple color="primary"
                (click)="selectRegion(region)">
                <i class="fas fa-xs fa-pen"></i></button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>

    <div class="lms-pager my-1">
    <div *ngIf="pager.pages && pager.pages?.length && pager.pages?.length > 1" class="btn-toolbar" role="toolbar" aria-label="LMS Pager group">
      <div class="btn-group btn-group-sm" role="group" aria-label="Move Previous group">
          <button type="button" class="btn btn-primary rounded-start" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5; margin: 0;" (click)="setPage(1)" [disabled]="pager.currentPage === 1">First</button>
          <button type="button" class="btn btn-primary rounded-0" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5; margin: 0;"(click)="setPage(pager.currentPage - 1)" [disabled]="pager.currentPage === 1">Previous</button>
      </div>
      <div class="btn-group btn-group-sm" role="group" aria-label="PageNumbers">
        <button *ngFor="let page of pager.pages" type="button" data-mdb-ripple-color="#7a7676" style="background-color:#f5f9ff; margin: 0; color: #7a7676;"
        [ngClass]="{active:pager.currentPage === page}"
        (click)="setPage(page)"
        class="btn btn-outline-secondary page-item number-item rounded-0">{{page}}</button>
      </div>
      <div class="btn-group btn-group-sm" role="group" aria-label="Move Next group">
        <button type="button" class="btn btn-primary rounded-0" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5; margin: 0;" (click)="setPage(pager.currentPage + 1)" [disabled]="pager.currentPage === pager.totalPages">Next</button>
        <button type="button" class="btn btn-primary rounded-end" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5;margin: 0;" (click)="setPage(pager.totalPages)" [disabled]="pager.currentPage === pager.totalPages">Last</button>
      </div>
    </div>
    </div>

    <div class="center-screen" >
        <button mat-raised-button type="button" color="primary"
        (click)="createNewRegion()">Create a new region</button>
    </div>

  </form>
