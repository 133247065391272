<div class="modal-content">
  <div class="modal-header">
    <div class="modal-title center-fields">
    <app-page-cardhead [PageName]="rejectOfferInfo.loanStatus === 'Submitted' ? 'Cancel Offer Reason' : 'Reject Offer Reason'" [PageHeadWidth]=500></app-page-cardhead>
    </div>
  </div>
  <div class="modal-body" >
    <form [formGroup]="rejectOfferReasonDetailsForm" #formDirective="ngForm">
    <div class="card">
      <div class="card-body p-1">
        <div class="container-fluid bd-example-row">
          <ng-container>

            <table class="table table-bordered table-striped table-responsive table-light table-sm shadow-1-strong caption-top  rounded-1 p-1" [style.width.px]="450">
              <caption> {{ rejectOfferInfo.loanStatus === 'Submitted' ? 'Cancel Reason' : 'Reject Reason' }}</caption> 
              <thead class="text-center">
                <tr>
                  <th class="font-weight-bold">{{ rejectOfferInfo.loanStatus === 'Submitted' ? 'Please select cancelation reason ' : 'Please select rejection reason ' }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span class="d-flex flex-wrap flex-column align-content-center">
                    <select mdbInput class="form-control form-select w-25"  formControlName="rejectOfferReason"
                    required>
                      <option *ngFor="let pType of lstReasonCode" [ngValue]="pType.reasonCode">{{pType.description}}</option>
                    </select>
                    <div *ngIf="rejectOfferReasonDetailsForm.invalid && submitted">
                      <div *ngIf="rejectOfferReason?.hasError('required')">
                        <small class="text-danger">
                          A reason is required to reject this offer
                        </small>
                      </div>
                    </div>
                  </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>
      </div>
      <div class="card-footer center-screen">
        <button mat-raised-button type="button" class="me-2" color="primary" (click)="close()"> Close </button>
        <button mat-raised-button class="btn btn-primary btn-rounded" mdbRipple color="primary" (click)="saveRejectOfferReason()"> Save </button>
      </div>
    </div>
    </div>
  </form>
</div>
