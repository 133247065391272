import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn:'root'
})
export class ClientnoteService {
  clientNote_apiURL = environment.apiUrl + '/loan/api/client';

  ClientNotes$ = new BehaviorSubject<number>(0);
  HttpParamsOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private httpClient: HttpClient) {
  }

  addClientNote(note: any) {
    return this.httpClient
      .post<any>(`${this.clientNote_apiURL}/addClientNote`, note)
      .pipe(
        map((responseData) => {
          this.setClientNotes(responseData);
          return responseData;
        })
      );
  }

  getClientNotesCount(){
    const ss= this.ClientNotes$.getValue();
    return ss;
  }

  setClientNotes(data: any) {
    if (data) {
      this.ClientNotes$.next(data.length);
    }
  }

  getClientNotes(id: number) {
    return this.httpClient
      .get(this.clientNote_apiURL+'/getClientNotes/'+id).pipe(
        map((clientNotes: any) => {
          this.setClientNotes(clientNotes);
          return clientNotes;
        }
      ))
  }
}
