
import {BrowserModule} from '@angular/platform-browser';
import { NgModule,APP_INITIALIZER, LOCALE_ID } from "@angular/core";

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { initializeKeycloak } from 'src/app/core/init/keycloak-init.factory';
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from "./core/interceptors/timeout.interceptor";
import { LoggingInterceptor } from "src/app/core/interceptors/logging.interceptor";
import { HttpErrorInterceptor } from 'src/app/core/interceptors/http-error.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { KeycloakAngularModule } from 'keycloak-angular';
import { ApiloaderService } from 'src/app/shared/loaders/apiloader/apiloader.service';

import { AppComponent } from './app.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { LMSSharedUiModule } from './shared/shared-ui.module';
import { NavbarComponent } from './navbar/navbar.component';
import { ApiloaderComponent } from './shared/loaders/apiloader/apiloader.component';
import { LMSDatePickerModule } from './core/constants/datepicker-adapter';
import { RestServicesModule } from "./core/rest-services/rest-services.module";
import { PageCardheadComponent } from "@lmsSharedUI";

import { MaterialModule } from 'src/app/material.module';
import { ToastrModule } from 'ngx-toastr';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MenusModule } from 'src/app/modules/menus/menus.module';
import { RatesAndFeesModule } from 'src/app/modules/rates-and-fees/rates-and-fees.module';
import { ClientSearchModule } from 'src/app/client-search/client-search.module';
import { RegionsAdminModule } from 'src/app/modules/regions-admin/regions-admin.module';
import { BranchEmployersModule } from 'src/app/features/branch-employers/branch-employers.module';

import { NgxPaginationModule } from 'ngx-pagination';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbPopoverModule } from 'mdb-angular-ui-kit/popover';
import { MdbRadioModule } from 'mdb-angular-ui-kit/radio';
import { MdbRangeModule } from 'mdb-angular-ui-kit/range';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MdbScrollspyModule } from 'mdb-angular-ui-kit/scrollspy';

const MATERIAL_IMPORTS = [
  BrowserAnimationsModule,
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatStepperModule
];

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    ApiloaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    KeycloakAngularModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      includeTitleDuplicates:true,
      countDuplicates: true,
      preventDuplicates: true,
      maxOpened: 5,
      newestOnTop: true
    }),
    NgxPaginationModule,
    MdbCollapseModule,
    MdbDropdownModule,
    MdbAccordionModule,
    MdbCheckboxModule,
    MdbFormsModule,
    MdbModalModule,
    MdbPopoverModule,
    MdbRadioModule,
    MdbRangeModule,
    MdbAccordionModule,
    MdbScrollspyModule,
    MdbRippleModule,
    RestServicesModule,
    AppRoutingModule,
    ...MATERIAL_IMPORTS,
    ServiceWorkerModule.register('ngsw-worker.js', {
        registrationStrategy: 'registerWhenStable:20000',
        enabled: true
    }),
    FormsModule,
    LMSSharedUiModule,
    MaterialModule.forRoot(),
    BranchEmployersModule,
    RegionsAdminModule,
    // RegionalManagerModule,
    ClientSearchModule,
    RatesAndFeesModule,
    MenusModule,
    LMSDatePickerModule,
    PageCardheadComponent,
    ReactiveFormsModule
  ],
  providers: [
    { provide: DEFAULT_TIMEOUT, useValue: 120000 },
    { provide: APP_INITIALIZER, useFactory: initializeKeycloak, multi:true },
    ApiloaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true  },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true  },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true  },
    { provide: LOCALE_ID, useValue: 'en-ZA' }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
