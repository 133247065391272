<app-page-cardhead [PageName]="'Region Details'" [PageHeadWidth]=500></app-page-cardhead>
<div class="container-sm center-container">
    <form [formGroup]="regionForm">
        <div class="card m-2">
          <div class="card-body p-1">
          <table class="table table-striped table-hover table-sm caption-top">
            <tbody class="field-4column-label">
              <tr>
                <td class="align-items-center text-center">Region name: &nbsp;
                  <input formControlName="region"
                    [(ngModel)]="regionModel.name"
                    type="text" >
                  <!-- <div *ngIf="areaForm.controls.area.errors?.required && submitted" class="error-label">
                    Area is required
                  </div> -->
                </td>
              </tr>

              <tr >
                <td style="width: 300px;">
                  <div >
                    <table class="table table-striped table-hover table-sm caption-top">
                      <caption>Manage areas in this region</caption>
                      <thead color="primary">
                        <tr>
                          <th>Code</th>
                          <th>Area</th>
                          <th>Edit</th>
                        </tr>
                      </thead>
                      <tbody class="center-fields">
                        <ng-container *ngFor="let areas of regionSelected.areas" >
                        <tr  >
                          <td>{{areas.id}}</td>
                          <td>{{areas.name}}</td>
                          <td><button mat-icon-button
                            class="btn btn-primary btn-rounded"
                             mdbRipple color="primary"
                             (click)="selectArea(areas)">
                            <i class="fas fa-xs fa-pen-to-square"></i></button></td>
                        </tr>
                        </ng-container>
                        <tr>
                          <td colspan="3">
                            <button mat-raised-button
                              class="btn btn-primary btn-rounded mc-2" mdbRipple color="primary" type="button"
                              class="button-add-row"
                              (click)="findAllAreas()">Move an area to this region</button>

                              &nbsp;&nbsp;&nbsp;&nbsp;

                                <button mat-raised-button type="button"
                                color="primary"
                                (click)="createNewArea()">Create a new area</button>
                          </td>
                        </tr>

                        <tr *ngIf="lstAreas">
                          <td colspan="3">
                            <tr>
                              <td>
                                Select the area that must be moved to this region.
                              </td>
                              <td>
                                <select formControlName="selArea"
                                  [(ngModel)]="regionModel.areas"
                                  (click)="selectNewArea($event)">
                                  <option *ngFor="let area of lstAreas"
                                    [value]="area.id">
                                      {{ area.name }}
                                  </option>
                                </select>
                              </td>

                            </tr>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>

              </tr>



            </tbody>
          </table>
          </div>

        <div class="card-footer center-screen">
          <button mat-raised-button class="btn btn-primary btn-rounded me-2"
            mdbRipple color="primary" (click)="close()"> Close
          </button>
          <button mat-raised-button class="btn btn-primary btn-rounded"
            mdbRipple color="primary"
            (click)="saveRegion()">Save
          </button>
        </div>
        </div>
      </form>
</div>
