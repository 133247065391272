<app-navbar></app-navbar>

<section  class="ms-auto me-auto mt-1 pt-1 ">
  <div class="lms-banner mat-elevation-z3 " role="banner"  *ngIf="environmentName !== 'PROD'">
    <div  [ngClass]="{
        'env-local': environmentName === 'LOCAL',
        'env-dev': environmentName === 'DEV',
        'env-uat': environmentName === 'UAT',
        'env-preprod': environmentName === 'PREPROD'
      }" role="alertdialog" aria-live="assertive">
        <div class="text-muted text-sm-center" height="150px" style="font-size: medium; text-align: center;">
          This is {{ environmentName }} environment
        </div>
    </div>
  </div>

  <!-- <div class="app-container"> -->
  <div *ngIf="isLoggedIn | async" class="center-screen">
    <app-page-cardhead [PageName]="Page_Title" [PageHeadWidth]="650"></app-page-cardhead>
    <div class="col-12 my-3">
    </div>
  </div>
</section>
<div class="container container-lg">
  <div class="card shadow-1-soft rounded-1 pt-1 pb-2">
    <router-outlet (activate)="global_utilities.onRouteActivation()"></router-outlet>
  </div>
</div>

